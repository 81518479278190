import {
    todayVariationsHindi,
    todayVariationstamil,
    todayVariationsTelugu,
    tomorrowVariationsHindi,
    tomorrowVariationstamil,
    tomorrowVariationsTelugu,
    toss
} from './variationsConstant'

let todayVariations = []
let tomorrowVariations = []
const now = new Date()
const endOfToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    23,
    59,
    59
)
const endOfTomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    23,
    59,
    59
)
const remainingTimeToday = endOfToday - now
const remainingTimeTomorrow = endOfTomorrow - now
function convertMsToTime(ms) {
    const totalSeconds = Math.floor(ms / 1000)
    const hours = Math.floor(totalSeconds / 3600)
    return hours
}
const variations = {
    2: { today: todayVariationsTelugu, tomorrow: tomorrowVariationsTelugu },
    3: { today: todayVariationsHindi, tomorrow: tomorrowVariationsHindi },
    4: { today: todayVariationstamil, tomorrow: tomorrowVariationstamil },
}
export function checkContentForDay(content, language) {
    todayVariations = variations[language]?.today || []
    tomorrowVariations = variations[language]?.tomorrow || []
    const normalizedContent = content.trim()
    const isToday = todayVariations.some((word) =>
        normalizedContent.includes(word)
    )
    const isYesterday = tomorrowVariations.some((word) =>
        normalizedContent.includes(word)
    )
    const isToss = toss.some((word)=>
        normalizedContent.includes(word)
    )
    if(isToss){
        return { today: true, expiry: 1, tomorrow: false }
    }
    else if (isYesterday) {
        const tomorrowRemaining = convertMsToTime(remainingTimeTomorrow)
        return { today: false, expiry: tomorrowRemaining === 0 ? 1 : tomorrowRemaining, tomorrow: true }
    }
    else if (isToday) {
        const todayRemaining = convertMsToTime(remainingTimeToday)
        return { today: true, expiry: todayRemaining === 0 ? 1 : todayRemaining, tomorrow: false }
    }else {
        return { today: false, expiry: undefined, tomorrow: false }
    }
}
