import React, { useEffect, useState } from 'react'
import { Table, Checkbox, Select, Button, message } from 'antd'
import './Permissions.scss'
import {
    getPermissionWithRoleId,
    getRoleList,
    updatePermission,
} from '../../AadhanApi'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

const ManagePermissions = () => {
    const [selectedOption, setSelectedOption] = useState('')
    const [availableRole, setAvailableRoles] = useState([])
    const employeeId = useSelector((state) => state.app.employeeId)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const { Option } = Select

    useEffect(() => {
        if (availableRole.length > 0) {
            setSelectedOption(availableRole[0].role_id)
        }
    }, [availableRole])

    useEffect(() => {
        try {
            getPermissionTableData()
        } catch (err) {
            console.log('err')
        }
    }, [selectedOption])

    const getPermissionTableData = async () => {
        if (selectedOption && accessToken) {
            const response = await getPermissionWithRoleId(
                selectedOption,
                accessToken
            )
            let arr = []
            Object.keys(response.data.permission_menu).forEach(
                (menuSection) => {
                    Object.keys(
                        response?.data?.permission_menu?.[menuSection]
                    ).forEach((menu, index) => {
                        const menuSectionObj =
                            response?.data?.permission_menu?.[menuSection]
                        arr.push({
                            key: menu + index,
                            menu: menu,
                            ...getPermissionObject(menuSectionObj?.[menu]),
                        })
                    })
                }
            )
            setData(arr)
        }
    }

    const getPermissionObject = (permissionArr) => {
        return {
            canAdd: permissionArr.includes('Can Add'),
            canEdit: permissionArr.includes('Can Edit'),
            canView: permissionArr.includes('Can View'),
            canDelete: permissionArr.includes('Can Delete'),
            canApprove: permissionArr.includes('Can Approve'),
        }
    }
    useEffect(() => {
        getAvailableRoles()
    }, [])
    const getAvailableRoles = async () => {
        const roleListResponse = await getRoleList(accessToken)
        setAvailableRoles(roleListResponse?.data)
    }

    const [data, setData] = useState([])

    const getPermissionsForPayload = (menu) => {
        let arr = []

        data.forEach((row) => {
            if (row.menu === menu) {
                row.canAdd && arr.push('Can Add')
                row.canEdit && arr.push('Can Edit')
                row.canView && arr.push('Can View')
                row.canDelete && arr.push('Can Delete')
                row.canApprove && arr.push('Can Approve')
            }
        })

        return arr
    }

    const createobj = () => {
        return {
            permission_menu: {
                Dashboard: {
                    Dashboard: getPermissionsForPayload('Dashboard'),
                },
                'Editor Dashboard': {
                    'Editor Dashboard':
                        getPermissionsForPayload('Editor Dashboard'),
                },

                Content: {
                    'Short News': getPermissionsForPayload('Short News'),
                    'Short Video': getPermissionsForPayload('Short Video'),
                    'Devotional Video':
                        getPermissionsForPayload('Devotional Video'),
                    Card: getPermissionsForPayload('Card'),
                    Poll: getPermissionsForPayload('Poll'),
                    Quiz: getPermissionsForPayload('Quiz'),
                    Notifications: getPermissionsForPayload('Notifications'),
                    HTML: getPermissionsForPayload('HTML'),
                    Ads: getPermissionsForPayload('Ads'),
                    'UG Content': getPermissionsForPayload('UG Content'),
                },
                Library: {
                    Playlist: getPermissionsForPayload('Playlist'),
                    Category: getPermissionsForPayload('Category'),
                    Visuals: getPermissionsForPayload('Visuals'),
                    'Daily Horoscope':
                        getPermissionsForPayload('Daily Horoscope'),
                    Videos : getPermissionsForPayload('Videos')    
                },
                Reports: {
                    'Category Time Slot Report': getPermissionsForPayload(
                        'Category Time Slot Report'
                    ),
                    'Time Slot Report':
                        getPermissionsForPayload('Time Slot Report'),
                    'State Content Report': getPermissionsForPayload(
                        'State Content Report'
                    ),
                    'Content Edits Reports': getPermissionsForPayload(
                        'Content Edits Reports'
                    ),
                    'Content Report By Language': getPermissionsForPayload(
                        'Content Report By Language'
                    ),
                    'Daily Content Report': getPermissionsForPayload(
                        'Daily Content Report'
                    ),
                    'Employee Report':
                        getPermissionsForPayload('Employee Report'),
                    'Notification Report': getPermissionsForPayload(
                        'Notification Report'
                    ),
                    'Proof Readers Report': getPermissionsForPayload(
                        'Proof Readers Report'
                    ),
                    'Source Report': getPermissionsForPayload('Source Report'),
                    'UGC Report': getPermissionsForPayload('UGC Report'),
                    'Video Report': getPermissionsForPayload('Video Report'),
                    'Districts Report':
                        getPermissionsForPayload('Districts Report'),
                    'Reporter Payment Report': getPermissionsForPayload(
                        'Reporter Payment Report'
                    ),
                    'UG Content Report':
                        getPermissionsForPayload('UG Content Report'),
                    'Search By Keywords Report': getPermissionsForPayload(
                        'Search By Keywords Report'
                    ),
                    'UG Content Proof Readers Report': getPermissionsForPayload(
                        'UG Content Proof Readers Report'
                    ),
                },
                Master: {
                    Hashtags: getPermissionsForPayload('Hashtags'),
                    Sources: getPermissionsForPayload('Sources'),
                    Locations: getPermissionsForPayload('Locations'),
                    Categories: getPermissionsForPayload('Categories'),
                    'Sub Categories':
                        getPermissionsForPayload('Sub Categories'),
                    Languages: getPermissionsForPayload('Languages'),
                    Features: getPermissionsForPayload('Features'),
                },
                Astrology: {
                    Horoscope: getPermissionsForPayload('Horoscope'),
                    Panchangam: getPermissionsForPayload('Panchangam'),
                },
                Employees: {
                    'Create New Employee': getPermissionsForPayload(
                        'Create New Employee'
                    ),
                    'Employee Shifts':
                        getPermissionsForPayload('Employee Shifts'),
                    'List All Employee':
                        getPermissionsForPayload('List All Employee'),
                    'List All Stringers':
                        getPermissionsForPayload('List All Stringers'),
                    Billing: getPermissionsForPayload('Billing'),
                    'Manage App Stringers':
                        getPermissionsForPayload('Manage App Stringers'),
                    'Manage TV Stringers':getPermissionsForPayload('Manage TV Stringers')    
                },
                Feedback: {
                    Feedback: getPermissionsForPayload('Feedback'),
                },
                Elections: {
                    'Election Master':
                        getPermissionsForPayload('Election Master'),
                    'Constituency Master': getPermissionsForPayload(
                        'Constituency Master'
                    ),
                    'Election Results':
                        getPermissionsForPayload('Election Results'),
                    'Constituency Results': getPermissionsForPayload(
                        'Constituency Results'
                    ),
                    'Create Elections':
                        getPermissionsForPayload('Create Elections'),
                    'List Elections':
                        getPermissionsForPayload('List Elections'),
                    Parties: getPermissionsForPayload('Parties'),
                    'Media Source': getPermissionsForPayload('Media Source'),
                    Candidate: getPermissionsForPayload('Candidate'),
                    Pulse: getPermissionsForPayload('Pulse'),
                    'Pulse-Polls': getPermissionsForPayload('Pulse-Polls'),
                    'Exit Polls': getPermissionsForPayload('Exit Polls'),
                    Results: getPermissionsForPayload('Results'),
                    'Candidate Mapping':
                        getPermissionsForPayload('Candidate Mapping'),
                    'Constituency Result': getPermissionsForPayload(
                        'Constituency Result'
                    ),
                },
                'Roles and Permissions': {
                    'Assign Roles & Permissions': getPermissionsForPayload(
                        'Assign Roles & Permissions'
                    ),
                    'Manage Roles': getPermissionsForPayload('Manage Roles'),
                },
            },
            role_id: selectedOption,
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
    }

    const handleSavePermission = async () => {
        try {
            const response = await updatePermission(
                createobj(),
                accessToken,
                selectedOption
            )
            if (response.status === 200) {
                getPermissionTableData()
                messageApi.open({
                    type: 'success',
                    content: 'Permissions Saved Succesfully',
                    duration: 5,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to Save Permissions',
                duration: 5,
            })
        }
    }

    const handleCheckboxChange = (e, columnName, key) => {
        setData((prevData) =>
            prevData.map((record) => {
                if (record.key === key) {
                    return { ...record, [columnName]: e.target.checked }
                }
                return record
            })
        )
    }

    const columns = [
        {
            title: 'Menu',
            dataIndex: 'menu',
            key: 'menu',
            width: 200,
        },
        {
            title: 'Can Add',
            dataIndex: 'canAdd',
            key: 'canAdd',
            width: 50,
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(e) =>
                        handleCheckboxChange(e, 'canAdd', record.key)
                    }
                />
            ),
        },
        {
            title: 'Can View',
            dataIndex: 'canView',
            key: 'canView',
            width: 50,
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(e) =>
                        handleCheckboxChange(e, 'canView', record.key)
                    }
                />
            ),
        },
        {
            title: 'Can Edit',
            dataIndex: 'canEdit',
            key: 'canEdit',
            width: 50,
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(e) =>
                        handleCheckboxChange(e, 'canEdit', record.key)
                    }
                />
            ),
        },
        {
            title: 'Can Delete',
            dataIndex: 'canDelete',
            key: 'canDelete',
            width: 50,
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(e) =>
                        handleCheckboxChange(e, 'canDelete', record.key)
                    }
                />
            ),
        },
        {
            title: 'Can Approve',
            dataIndex: 'canApprove',
            key: 'canApprove',
            width: 50,
            render: (value, record) => (
                <Checkbox
                    checked={value}
                    onChange={(e) =>
                        handleCheckboxChange(e, 'canApprove', record.key)
                    }
                />
            ),
        },
    ]

    const handleSelectChange = (value) => {
        setSelectedOption(value)
    }

    return (
        <div className="manage-permissions-container">
            <h2 className="heading">Manage Permissions</h2>
            {contextHolder}
            <Select
                value={selectedOption}
                onChange={handleSelectChange}
                style={{ width: '10rem', marginBottom: '1rem' }}
            >
                {availableRole.map((role) => (
                    <Option key={role?.role_id} value={role.role_id}>
                        {role.role_name}
                    </Option>
                ))}
            </Select>

            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                scroll={{ y: 500 }}
            />
            <Button
                style={{ marginTop: '1rem' }}
                onClick={handleSavePermission}
                type="primary"
            >
                Save Permissions
            </Button>
        </div>
    )
}
export default ManagePermissions
