import OpenAI from 'openai'

const openai = new OpenAI({
    apiKey: 'sk-proj-vxH2FBo5vM79zKwK1RQ7T3BlbkFJUu8EaHRK5T7toUypZxsN',
    dangerouslyAllowBrowser: true,
})

export default async function summarizeText(originalText) {
    try {
        const response = await openai.chat.completions.create({
            model: 'gpt-4o-mini',
            messages: [
                {
                    role: 'user',
                    content: `Please summarize the following horoscope in 30-35 words. The summary should be clear, coherent, and strictly in the same language as the original text. Focus on key aspects such as health, finance, job/career, family/love, and any notable events. Ensure that the summary captures the main points accurately, with all sentences being complete and contextually relevant, similar to daily or monthly horoscope readings found in books or articles. Additionally, provide a conclusive statement that reflects the overall theme or mood of the day or month for the reader based on the horoscope sign.: "${originalText}"`,
                },
            ],
            max_tokens: 320,
            n: 1,
            stop: null,
            temperature: 0.1,
        })

        if (response.choices && response.choices.length > 0) {
            return response.choices[0].message.content.trim()
        } else {
            throw new Error('No response from  the OpenAI')
        }
    } catch (error) {
        console.error('Error:', error)
        throw error
    }
}
