import React, { useEffect, useState } from 'react'
import { Input, Button } from 'antd'
import { EditTwoTone, SyncOutlined, UndoOutlined } from '@ant-design/icons'

const { TextArea } = Input

const Spellcheck = ({
    generate,
    wordsToHighlight,
    setSpellDescription,
    correctedWords,
    textValue,
    setTextValue,
    type,
}) => {
    const [highlightedText, setHighlightedText] = useState('')
    const [selectedWord, setSelectedWord] = useState(null)
    const [suggestedWord, setSuggestedWord] = useState('')
    const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 })
    const [history, setHistory] = useState([textValue])
    const [originalText, setOriginalText] = useState(textValue)

    const highlightText = (text, wrongText) => {
        let highlighted = text
        wordsToHighlight.forEach((word) => {
            if (wrongText !== word) {
                const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // Escape special characters
                const regex = new RegExp(`(${escapedWord})`, 'g')
                highlighted = highlighted.replace(
                    regex,
                    `<span style="text-decoration:underline; text-decoration-color:red; text-decoration-style:dashed; font-weight:550; cursor:pointer;" data-word="${word}">${word}</span>`
                )
            }
        })
        return highlighted
    }

    useEffect(() => {
        if (generate) {
            setHighlightedText(
                `<span style="text-align:center;color:blue">${'Spell Checking...'}</span>`
            )
        } else {
            const highlighted = highlightText(textValue)
            setHighlightedText(highlighted)
        }
    }, [generate])

    const handleWordClick = (event) => {
        const word = event.target.getAttribute('data-word')
        if (word) {
            setSelectedWord(word)
            const index = wordsToHighlight.indexOf(word)
            if (index !== -1) {
                setSuggestedWord(correctedWords[index])
            }
            const rect = event.target.getBoundingClientRect()
            setPopupPosition({
                top:
                    rect.top -
                    event.target.offsetParent.getBoundingClientRect().top,
                left:
                    rect.left -
                    event.target.offsetParent.getBoundingClientRect().left,
            })
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                selectedWord &&
                !event.target.closest('[data-word]') &&
                !event.target.closest('.suggestion-popup')
            ) {
                setSelectedWord(null)
                setSuggestedWord('')
            }
        }
        if (selectedWord) {
            document.addEventListener('click', handleClickOutside)
        } else {
            document.removeEventListener('click', handleClickOutside)
        }
        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, [selectedWord])

    const handleReplace = () => {
        if (selectedWord) {
            const updatedText = textValue
                .split(' ')
                .map((word) => (word === selectedWord ? suggestedWord : word))
                .join(' ')
            setHistory([...history, updatedText])
            setTextValue(updatedText)
            setHighlightedText(highlightText(updatedText,selectedWord))
            setSelectedWord(null)
            setSuggestedWord('')
        }
    }

    const handleReplaceAll = () => {
        let updatedText = textValue
        wordsToHighlight.forEach((word, index) => {
            updatedText = updatedText
                .split(' ')
                .map((w) => (w === word ? correctedWords[index] : w))
                .join(' ')
        })
        setHistory([...history, updatedText])
        setTextValue(updatedText)
        setHighlightedText(updatedText)
        setSelectedWord(null)
        setSuggestedWord('')
    }

    const handleUndo = () => {
        if (history.length > 1) {
            const previousState = history[history.length - 2]
            setHistory(history.slice(0, -1))
            setTextValue(previousState)
            setHighlightedText(highlightText(previousState))
        }
    }

    return (
        <div>
            <div
                onClick={handleWordClick}
                onDoubleClick={() => setSpellDescription(false)}
                dangerouslySetInnerHTML={{ __html: highlightedText }}
                style={{
                    marginBottom: '.3rem',
                    padding: '4px 11px',
                    border: '1px solid #d9d9d9',
                    borderRadius: '4px',
                    whiteSpace: 'pre-wrap',
                    wordWrap: 'break-word',
                    minHeight: type === 'description' ? '5rem' : '60px',
                    position: 'relative',
                    width: '100%',
                    fontSize: '.88rem',
                    lineHeight: '24px',
                    backgroundColor: '#f1efee',
                }}
            />
            {selectedWord && (
                <div
                    style={{
                        position: 'absolute',
                        top: popupPosition.top,
                        left: popupPosition.left,
                        backgroundColor: 'orange',
                        zIndex: 1000,
                        fontSize: '.8rem',
                        padding: '1px 3px',
                        borderRadius: '2px',
                        cursor: 'pointer',
                        transform: 'translateY(-90%)',
                    }}
                    onClick={handleReplace}
                >
                    {suggestedWord}
                </div>
            )}
            <div style={{ marginBottom: '.3rem' }}>
                <Button
                    onClick={handleReplaceAll}
                    type="primary"
                    ghost
                    style={{ marginRight: '10px' }}
                >
                    Correct All
                </Button>

                <Button
                    onClick={handleUndo}
                    type="primary"
                    ghost
                    style={{ marginRight: '10px' }}
                >
                    Undo
                </Button>
                <Button
                    type="primary"
                    style={{ marginRight: '10px' }}
                    onClick={() => setSpellDescription(false)}
                >
                    Done
                </Button>
                <Button
                    onClick={() => {
                        setTextValue(originalText)
                        const highlighted = highlightText(originalText)
                        setHighlightedText(highlighted)
                    }}
                    style={{ color: 'red', float: 'right' }}
                >
                    Revert All <UndoOutlined style={{ color: 'red' }} />
                </Button>
            </div>
        </div>
    )
}

export default Spellcheck
