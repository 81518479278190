
import React, { useRef, useState } from 'react';
import CropperModal from './cropperModal';
import ImageCompressor from './ImageCompressor';

function ImageUploader({ getImagePath, onChange, value, inHouseAd }) {
    const [isCompress, setIsCompress] = useState('false')
    const [file, setFile] = React.useState(null)
    const [isValidFile, setisValidFile] = useState(false)
    const inputRef = useRef(null)

    const onFileInputChange = (e) => {
        if (e?.target?.files && e?.target?.files?.length > 0) {
            const fileInput = e?.target?.files[0]
            const maxSize = inHouseAd ? 1000 : 100;
            if (fileInput?.size / 1024 <= maxSize) {
                setFile(e?.target?.files[0]);
                setisValidFile(true);
                setIsCompress(false);
            } else {
                setisValidFile(false);
                setFile(e?.target?.files[0]);
                setIsCompress(true);
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
                onClick={() => {
                    inputRef.current.value = null
                }}
                ref={inputRef}
                accept="image/jpeg,image/png"
                type="file"
                onChange={(e) => {
                    onFileInputChange(e)
                }}

            />

            {(isCompress === true && !isValidFile) ? (


                <ImageCompressor
                    file={file}
                    onConfirm={(ImageCompressor) => {

                        getImagePath(ImageCompressor)
                    }}
                    onDiscard={() => {
                        inputRef.current.value = null
                    }}
                    onCompleted={() => setFile(null)}
                />

            ) :
                <CropperModal
                    file={file}
                    onConfirm={(croppedFile) => {

                        getImagePath(croppedFile)
                    }}
                    onDiscard={() => {
                        inputRef.current.value = null
                    }}
                    onCompleted={() => setFile(null)}
                />
            }

            {/* <a href="https://squoosh.app/" target="_blank">
                Open Squoosh to Format Image
            </a> */}
            <div style={{ color: '#199be9' }}>Image size 540*525</div>
        </div>
    )
}

export default ImageUploader
