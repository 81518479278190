import './SmartphonePreview.scss'
import { useLocation } from 'react-router'
import { Carousel} from 'antd'
import ReactPlayer from 'react-player'
import NumberedBulletList from '../NumberedBulletList/NumberedBulletList'
import MuxPlayer from '@mux/mux-player-react'
const SmartphonePreview = ({
    image,
    title,
    description,
    questions,
    cardImages,
    videoPath,
    weburl,
    selectedType,
}) => {
    const location = useLocation()

    const getImageOrCarouselContainer = () => {
        if (
            image &&
            ([
                '/home/shortNewsContent',
                '/home/ugContent',
                '/home/reviewContent',
                '/home/pollContent',
                '/home/quizContent',
                '/home/notifications',
            ].includes(location.pathname) ||
                selectedType === 'shortnews')
        ) {
            // image should be displayed only for shortnews,quiz,poll.
            return (
                <img
                    style={{ width: '100%', height: '108%'}}
                    alt="news"
                    src={image}
                />
            )
        } else if (
            cardImages &&
            cardImages?.length > 0 &&
            (['/home/cardContent', '/home/visuals'].includes(
                location.pathname
            ) ||location.pathname.includes('visuals') ||
                selectedType === 'card') 
        ) {
            return (
                <Carousel
                    style={{ height: '100%', width: '100%' }}
                    swipeToSlide
                    draggable
                >
                    {cardImages?.map((images, index) => {
                        if (location.pathname.includes('visuals')) {
                            return (
                                <img
                                    key={index}
                                    src={
                                        images.originFileObj
                                            ? URL.createObjectURL(
                                                  images?.originFileObj
                                              )
                                            : `${
                                                  images?.response
                                              }?${new Date().getTime()}`
                                    }
                                    alt={`${index}`}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            )
                        } else {
                            return (
                                <img
                                    key={index}
                                    src={images?.response}
                                    alt={`${index}`}
                                    style={{ width: '100%', height: '100%' }}
                                />
                            )
                        }
                    })}
                </Carousel>
            )
        }
    }

    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    let token
    let playbackId

    const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
    const temp = youtubeRegex.test(videoPath)
    if (videoPath && !temp && videoPath.includes('token')) {
        token = videoPath.match(tokenRegex)[1]
        playbackId = videoPath.match(playbackIdRegex)[1]
    }

    const isMP4 = videoPath && videoPath.endsWith('.mp4')
    return (
        <div className={(location.pathname.includes("ugContent") || location.pathname.includes("reviewContent"))?"":"addAd-preview"}>
           {(location.pathname.includes("ugContent") || location.pathname.includes("reviewContent")) ?"":
            <h2 className="preview-header">Preview</h2> 
           }
            <div className="smartphone">
                {location.pathname !== '/home/htmlContent' && (
                    <div className="content">
                        <div
                            className={
                                location?.pathname === '/home/cardContent' ||
                                location?.pathname ===
                                    '/home/shortVideoContent' ||
                                location?.pathname ===
                                    '/home/devotionalVideoContent' ||
                                location?.pathname === '/home/visuals' || location.pathname.includes('visuals') ||
                                (location?.pathname ===
                                    '/home/notifications' &&
                                    cardImages) ||
                                (location.pathname === '/home/adsContent' &&
                                    ['html', 'shortvideo', 'card'].includes(
                                        selectedType
                                    ))
                                    ? 'full-image'
                                    : 'image'
                            }
                        >
                            {!videoPath && getImageOrCarouselContainer()}
                            {videoPath &&
                                playbackId !== '' &&
                                playbackId !== undefined &&
                                token !== undefined && (
                                    <MuxPlayer
                                        style={{ height: '100%',width:"100%"}}
                                        playbackId={playbackId}
                                        tokens={{ playback: token }}
                                    />
                                )}
                            {videoPath && temp && (
                                <ReactPlayer
                                    url={videoPath}
                                    controls
                                    width="100%"
                                    height="100%"
                                />
                            )}
                            {videoPath && isMP4 && (
                                <ReactPlayer
                                    url={videoPath}
                                    controls
                                    width="100%"
                                    height="100%"
                                />
                            )}

                            {weburl &&
                                (location.pathname === '/home/htmlContent' ||
                                    selectedType === 'html') && (
                                    <iframe
                                        title=" "
                                        width={'100%'}
                                        height={'100%'}
                                        src={weburl}
                                    ></iframe>
                                )}
                        </div>
                        {location.pathname === '/home/cardContent' ||
                        (location.pathname === '/home/notifications' &&
                            cardImages) ||
                        location?.pathname === '/home/shortVideoContent' ? (
                            ''
                        ) : (
                            <div>
                                {title &&
                                    (location.pathname !== '/home/adsContent' ||
                                        selectedType === 'shortnews') && (
                                        <div className="title">
                                            {title?.replace(/\\n/g, '')}
                                        </div>
                                    )}
                                {description &&
                                    (location.pathname !== '/home/adsContent' ||
                                        selectedType === 'shortnews') && (
                                        <div className="desc">
                                            <NumberedBulletList
                                                text={description}
                                            />
                                        </div>
                                    )}
                                <div className="questions">
                                    {questions?.question && (
                                        <p>{questions?.question}</p>
                                    )}
                                    {questions?.options &&
                                        questions?.options.map(
                                            (option, index) => (
                                                <p
                                                    key={index}
                                                    className="options"
                                                >
                                                    {option}
                                                </p>
                                            )
                                        )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {location.pathname === '/home/htmlContent' && weburl && (
                    <iframe
                        title=" "
                        width={'100%'}
                        height={'100%'}
                        src={weburl}
                    ></iframe>
                )}
            </div>
        </div>
    )
}
export default SmartphonePreview
