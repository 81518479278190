import React, { useEffect, useState } from 'react'
import {
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    Tag,
    Row,
    Col,
    message,
    Popconfirm,
    Modal,
    Tooltip,
} from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { createRules, deleteRule, getRules, updateRule } from '../../AadhanApi'
import CommonTableWithBorder from '../CommonTable/CommonTableWithBorder'
const { Option } = Select
const RuleForDay = () => {
    const languages = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [keywords, setKeywords] = useState([])
    const [currentKeyword, setCurrentKeyword] = useState('')
    const [rules, setRules] = useState([])
    const [editingRule, setEditingRule] = useState(null)
    const [errorMessage, setErrorMessage] = useState('')
    const [createdDate, setCreatedDate] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [iserror, setIsError] = useState({
        language: false,
        keywords: false,
        ruleName: false,
        createdDate: false,
    })
    const [language_, setLanguage_] = useState(2)
    const [date, setDate] = useState(dayjs(new Date()))
    async function getData() {
        setLoading(true)
        const data = await getRules(language_, accessToken)
        setRules(data?.data?.data)
        setLoading(false)
    }
    useEffect(() => {
        setRules([])
        getData()
        setIsEditing(false)
        setRuleName('')
        setKeywords([])
    }, [language_, date])
    const [ruleName, setRuleName] = useState('')
    const handleAddKeyword = () => {
        if (currentKeyword) {
            if (keywords.includes(currentKeyword)) {
                setErrorMessage('This keyword already exists!')
            } else {
                setKeywords([...keywords, currentKeyword])
                setCurrentKeyword('')
                setErrorMessage('')
            }
        }
    }
    const column = [
        { title: 'Rule Nmae', dataIndex: 'rule_name' },
        {
            title: 'Date',
            dataIndex: 'created_date',
            render: (_, record) => (
                <div>{dayjs(record?.created_date).format('DD MMM YYYY')}</div>
            ),
            defaultSortOrder: 'ascend', 
            sorter: (a, b) => dayjs(a.created_date).unix() - dayjs(b.created_date).unix(), 
        },
        {
            title: 'Keywords',
            dataIndex: 'key_words',
            render: (_, record) => (
                <div>
                    {record?.key_words?.split(',')?.map((keyword) => (
                        <Tag
                            key={keyword}
                            color="blue"
                            style={{
                                marginBottom: '5px',
                            }}
                        >
                            {keyword}
                        </Tag>
                    ))}
                </div>
            ),
        },
        {
            title: 'Actions',
            render: (text, record, index) => (
                <div>
                    <Button
                        type="primary"
                        onClick={() => handleEditRule(index)}
                        style={{ marginRight: '1rem' }}
                        icon={<EditOutlined />}
                    ></Button>
                    <Popconfirm
                        title="Are you sure you want to delete this rule?"
                        onConfirm={() => handleDeleteRule(index)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Tooltip>
                            <Button icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </div>
            ),
        },
    ]
    const handleDeleteKeyword = (keyword) => {
        setKeywords(keywords.filter((k) => k !== keyword))
    }
    const handleSubmit = async () => {
        const newRule = {
            language_id: language_,
            key_words: keywords.join(','),
            rule_name: ruleName,
            created_date: dayjs(createdDate)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }
        let validation = {
            language: false,
            keywords: false,
            ruleName: false,
            createdDate: false,
        }
        let hasError = false
        if (keywords.length === 0) {
            validation.keywords = true
            hasError = true
        }
        if (!ruleName || ruleName.trim() === '') {
            validation.ruleName = true
            hasError = true
        }
        if (!createdDate) {
            validation.createdDate = true
            hasError = true
        }
        setIsError(validation)
        if (hasError) {
            return
        }

        if (editingRule !== null) {
            const rule = rules.filter((rule, index) => index === editingRule)
            const res = await updateRule(
                accessToken,
                rule?.[0]?.rule_id,
                newRule
            )
            if (res?.data?.success === true) {
                message.success('Rule updated successfully')
                setEditingRule(null)
                getData()
            } else {
                message.error('Error while updating rule')
            }
        } else {
            const res = await createRules(accessToken, newRule)
            if (res?.data?.success === true) {
                message.success('Rule created successfully')
                getData()
            } else {
                message.error('Error while creating rule')
            }
        }
        setIsEditing(false)
        resetForm()
        setIsModalOpen(false)
    }
    const handleEditRule = (index) => {
        setIsError({
            language: false,
            keywords: false,
            ruleName: false,
            createdDate: false,
        })
        setIsEditing(true)
        const rule = rules[index]
        setKeywords(rule.key_words.split(','))
        setRuleName(rule.rule_name)
        setCreatedDate(dayjs(rule?.created_date))
        setEditingRule(index)
        setIsModalOpen(true)
    }
    const handleDeleteRule = async (index) => {
        const rule = rules.filter((_, i) => i === index)
        const res = await deleteRule(accessToken, rule?.[0]?.rule_id)
        if (res?.data?.success === true) {
            message.success('Rule deleted succes    sfully')
            setRules(rules.filter((_, i) => i !== index))
        }
    }

    const resetForm = () => {
        setKeywords([])
    }
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => {
        setRuleName('')
        setKeywords([])
        setCreatedDate(null)
        setIsModalOpen(true)
    }
    const handleOk = () => {
        handleSubmit()
    }
    const handleCancel = () => {
        setIsEditing(false)
        resetForm()
        setIsModalOpen(false)
        setIsError({
            language: false,
            keywords: false,
            ruleName: false,
            createdDate: false,
        })
    }
    return (
        <div>
            <div style={{ display: 'flex', marginBottom: '1rem' }}>
                <Select
                    onChange={(value) => setLanguage_(value)}
                    value={language_}
                    placeholder="Select language"
                    className="language-select"
                >
                    {languages?.map((e) => (
                        <Option value={e.language_id} key={e.language_id}>
                            {e.language_name}
                        </Option>
                    ))}
                </Select>

                <Button
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                    icon={<PlusOutlined />}
                    onClick={showModal}
                >
                    Add Rule
                </Button>
            </div>
            <CommonTableWithBorder
            className={'no-padding-table'}
                data={rules}
                columns={column}
                disablePagination={true}
                dataLoading={loading}
            />

            <Modal
                title={isEditing ? 'Edit Rule' : 'Add Rule'}
                open={isModalOpen}
                okText={isEditing ? 'Update Rule' : 'Submit Rule'}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <div>
                    {
                        <Form layout="vertical" onFinish={handleSubmit}>
                            <Form.Item required label="Date">
                                <DatePicker
                                    style={{ width: '60%' }}
                                    value={createdDate}
                                    onChange={(e) => setCreatedDate(e)}
                                />
                            </Form.Item>
                            {iserror?.createdDate && (
                                <div
                                    style={{
                                        color: 'red',
                                        marginTop: '5px',
                                    }}
                                >
                                    {'Date is required'}
                                </div>
                            )}

                            <Form.Item required label="Rule Name">
                                <Input
                                    value={ruleName}
                                    placeholder="Enter Rule Name"
                                    onChange={(e) =>
                                        setRuleName(e.target.value)
                                    }
                                />
                            </Form.Item>
                            {iserror?.ruleName && (
                                <div
                                    style={{
                                        color: 'red',
                                        marginTop: '5px',
                                    }}
                                >
                                    {'Rule name required!'}
                                </div>
                            )}

                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item required label="Add Keywords">
                                        <Input
                                            placeholder="Enter a keyword and press enter"
                                            value={currentKeyword}
                                            onChange={(e) =>
                                                setCurrentKeyword(
                                                    e.target.value
                                                )
                                            }
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault() // Prevent form submission
                                                    handleAddKeyword() // Add keyword when Enter is pressed
                                                }
                                            }}
                                            suffix={
                                                <Button
                                                    type="primary"
                                                    icon={<PlusOutlined />}
                                                    onClick={handleAddKeyword}
                                                    disabled={!currentKeyword}
                                                    className="add-btn"
                                                >
                                                    Add
                                                </Button>
                                            }
                                            className="keyword-input"
                                        />

                                        {errorMessage && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {errorMessage}
                                            </div>
                                        )}
                                        {iserror?.keywords && (
                                            <div
                                                style={{
                                                    color: 'red',
                                                    marginTop: '5px',
                                                }}
                                            >
                                                {'keywords is required'}
                                            </div>
                                        )}
                                        <div className="keyword-tags">
                                            {keywords.map((keyword) => (
                                                <Tag
                                                    style={{
                                                        marginBottom: '5px',
                                                    }}
                                                    closable
                                                    onClose={() =>
                                                        handleDeleteKeyword(
                                                            keyword
                                                        )
                                                    }
                                                    key={keyword}
                                                    color="blue"
                                                    className="keyword-tag"
                                                >
                                                    {keyword}
                                                </Tag>
                                            ))}
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    }
                </div>
            </Modal>
        </div>
    )
}
export default RuleForDay
