import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const VideoPlayerWithThumbnail = ({ videoUrl, thumbnailUrl }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlay = () => {
        setIsPlaying(true); // Switch to playing mode
    };

    return (
        <div style={{ position: 'relative', width: '100%', paddingBottom: '56.25%'}}>
            {isPlaying ? (
                <ReactPlayer
                    url={videoUrl}
                    playing
                    controls
                    width="100%"
                    height="100%"
                    muted
                    style={{
                        borderRadius: '8px',
                        overflow: 'hidden',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                
                    }}
                />
            ) : (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: `url(${thumbnailUrl})`,
                        backgroundColor:"gray",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        borderRadius: '8px',
                        cursor: 'pointer',
                    }}
                    onClick={handlePlay}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            fontSize: '40px',
                        }}
                    >
                        ▶️
                    </div>
                </div>
            )}
        </div>
    );
};

export default VideoPlayerWithThumbnail;
