import { useEffect, useState } from 'react'
import {
    Button,
    Select,
    Input,
    Popconfirm,
} from 'antd'
import {
    EditOutlined,
    DeleteOutlined,
    YoutubeFilled,
    PlayCircleOutlined,
    RedoOutlined,
} from '@ant-design/icons'
import {
    AddTypeOptions,
    ApprovalOptions,
} from '../ManageContent/ManageContent.constant'
import CommonTable from '../CommonTable/CommonTable'
import { useLocation, useNavigate } from 'react-router'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
import { useSelector, useDispatch } from 'react-redux'
import {
    deleteInhouseAdsRow,
    searchDataByTitleAndType,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import Uploadedicon from '../../assets/uploaded_icon.svg'
import PreviewCorousel from '../PreviewCorousel/PreviewCorousel'
import './ManageAd.scss'
import { changePopup } from '../../store/slice/AppSlice'

const ManageAd = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const popup = useSelector((state) => state.app.popup)
    

    const { Option } = Select
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const location = useLocation()

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
        updateLocalStorageData('dates', updatedDates)
    }
    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
        updateLocalStorageData('dates', updatedDates)
    }
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })

    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [approvalOption, setSelectedApprovalOption] = useState('draft')
    const [addTypeOption, setAddTypeOption] = useState("all")
    const [categoryOption, setCategoryOption] = useState(99999)

    const [searchData, setSearchData] = useState('')
    const [dataLoading, setDataLoading] = useState(false)
    const [inHouseAdsData, setInHouseAdsData] = useState([])
    const [contentId, setContentId] = useState('')
    const [localStorageData, setLocalStorageData] = useState({})

    const currentTableType = 'inhousead'

    const viewPopup = (id) => {
        dispatch(changePopup(true))
        setContentId(id)
    }

    const updateLocalStorageData = (type, value) => {
        const storedJsonString = localStorage.getItem(currentTableType)
        if (storedJsonString !== null) {
            const storedObj = JSON.parse(storedJsonString)
            storedObj[type] = value
            const updatedJsonString = JSON.stringify(storedObj)
            localStorage.setItem(currentTableType, updatedJsonString)
        }
    }

    const getInHouseAdsTableData = async () => {
        setDataLoading(true);

        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')

        setDataLoading(true)
        const text = searchData == '' ? '' : searchData
        try {
            const inHouseAdsDataResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                700,
                accessToken,
                addTypeOption
            )
            const updatedSearchResponse = inHouseAdsDataResponse?.data.map(item => {
                return {
                    ...item,
                    uploaded_by_name: item.uploaded_by,
                };
            });
           

            setInHouseAdsData(updatedSearchResponse)

            setDataLoading(false)
        } catch (err) {
            console.log(err)
            setDataLoading(false)
        }
    };

    // useEffect(() => {
    //     getInHouseAdsTableData();
    // }, []);

    const getTableData = (searchResponse, searchInput) => {
        if (searchInput) {
            const updatedSearchResponse = searchResponse.map(item => {
                return {
                    ...item,
                    uploaded_by_name: item.uploaded_by,
                };
            });

            setInHouseAdsData(updatedSearchResponse)
        } else {
            getInHouseAdsTableData()
        }
    }

    const deleteInhouseAds = async (record) => {
        try {
            setDataLoading(true)
            const deleteResponse = await deleteInhouseAdsRow(record.content_id, accessToken)

            if (deleteResponse?.status === 200) {
                getInHouseAdsTableData()
            }
            setDataLoading(false)
        }
        catch (err) {
            console.log("failed to delete inhouse ads", err)
        }
    }

    const onEditClicked = (record) => {
        Navigate(`/home/adsContent?mode=edit&contentId=${record?.content_id}`)
    }

    const InHouseAdsColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p
                        style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                    <span style={{ fontSize: '1.5rem' }}>
                        {record?.stream_video_url ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            ),
            width: 100,
        },
        {
            title: 'Ad Type',
            dataIndex: 'ad_type',
            key: 'ad_type',
            width: 100,
        },
        {
            title: 'Advertiser',
            dataIndex: 'advertiser_name',
            key: 'advertiser',
            width: 100,
        },
        {
            title: 'CTA Name',
            dataIndex: 'cta_name',
            key: 'cta_name',
            width: 100,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: 100,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p style={{ margin: '0px' }}>
                        {record?.created_type == 'reporter' && (
                            <img
                                style={{ width: '1rem', marginRight: '9px' }}
                                alt="ViewIcon"
                                src={Uploadedicon}
                            />
                        )}
                        {record?.uploaded_by_name}
                    </p>
                </div>
            ),
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: 120,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {dayjs(record?.published_date).diff(dayjs(), 'min') > 0 && (
                        <div
                            style={{
                                backgroundColor: '#a4c9a4',
                                border: '0.5px solid black',
                                borderRadius: '5px',
                                paddingLeft: '4px',
                                width: '80%',
                            }}
                        >
                            Scheduled :{' '}
                            {dayjs(record?.published_date).format(
                                'DD MMM YYYY, hh:mm a'
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Status',
            dataIndex: 'content_status',
            key: 'content_status',
            width: 100,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: 50,
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    {/* <a
                        href={record.dynamic_url}
                        target="blank"
                        className="view-icon"
                    >
                        <img
                            style={{ width: '1rem' }}
                            alt="ViewIcon"
                            src={Viewicon}
                        />
                    </a> */}
                    <EditOutlined
                        onClick={() => onEditClicked(record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteInhouseAds(record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const onSearch = async (event) => {
        if (currentTableType === 'ads') {
            currentTableType = 'inhousead'
        }
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setSearchData(event)
        updateLocalStorageData('searchData', event)
        setDataLoading(true)
        try {
            const response = await searchDataByTitleAndType(
                event,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken,
                addTypeOption,
            )
            setDataLoading(false)
            getTableData(response?.data, event)

        } catch (err) {
            setDataLoading(false)
            console.log(err)
        }
    }

    const handleApprovalChange = (event) => {
        setSelectedApprovalOption(event)
        updateLocalStorageData('approvalOption', event)
    }

    const handleAddAdTypeChange = (event) => {
        setAddTypeOption(event)
        updateLocalStorageData('addTypeOption', event)
    }

    const handleLanguageChange = (event) => {
        // setCategoryOption(99999)
        setLanguageOption(event)
        updateLocalStorageData('languageOption', event)
    }

    const handleCategoryChange = (event) => {
        setCategoryOption(event)
        updateLocalStorageData('categoryOption', event)
    }

    const changeHandler = (event) => {
        setSearchData(event.target.value)
        // updateLocalStorageData('searchData', event.target.value)
    }

    const canMakeAPICall = () => {
        return (
            languageOption && dates && categoryOption && approvalOption
        )
    }

    useEffect(() => {
        if (canMakeAPICall()) {
            getTableData()
        }
    }, [
        approvalOption,
        languageOption,
        dates,
        // categoryOption,
        addTypeOption,
    ])

    useEffect(() => {
        const getContentData =
            localStorage.getItem(currentTableType) &&
            JSON.parse(localStorage.getItem(currentTableType))
        if (getContentData && Object.keys(getContentData)?.length) {
            const {
                approvalOption,
                languageOption,
                categoryOption,
                searchData,
                dates,
                addTypeOption,
            } = getContentData
            setSelectedApprovalOption(approvalOption)
            setLanguageOption(languageOption)
            setCategoryOption(categoryOption)
            setSearchData(searchData)
            addDates(dates?.startDate, dates?.endDate)
            setAddTypeOption(addTypeOption)
        } else {
            let obj = {
                approvalOption: 'draft',
                languageOption: 1,
                categoryOption: 99999,
                searchData: '',
                dates: {
                    startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                },
                addTypeOption: 'all',
            }
            localStorage.setItem(currentTableType, JSON.stringify(obj))
            setLocalStorageData(obj)
        }
    }, [location.pathname, localStorageData])

    return (
        <div className='manage-ad'>

            <div className='manage-content-header'>
                <h2>Manage Ad</h2>
                <div className="date-picker-container">
                    <span style={{ marginRight: '5px' }}>Date Range :</span>
                    <ManageContentDateRange
                        dates={dates}
                        addDates={addDates}
                        clearDates={clearDates}
                    />
                </div>
            </div>

            <div className='manage-content-inputs'>

                <Select
                    value={approvalOption}
                    onChange={handleApprovalChange}
                    style={{ width: 200 }}
                >
                    {ApprovalOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>

                <Select
                    value={addTypeOption}
                    onChange={handleAddAdTypeChange}
                    style={{ width: 200 }}
                >
                    {AddTypeOptions.map((option, index) => {
                        return (
                            <Option key={index} value={option?.value}>
                                {option?.label}
                            </Option>
                        )
                    })}
                </Select>

                <Select
                    value={languageOption}
                    onChange={handleLanguageChange}
                    style={{ width: 200 }}
                    placeholder="Select language"
                >
                    {languageData?.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>

                {/* <Select
                    value={categoryOption}
                    onChange={handleCategoryChange}
                    style={{ width: 200 }}
                    placeholder="Select category"
                >
                    <Option key={'all'} value={99999}>
                        All categories
                    </Option>
                    {categories
                        ?.filter(
                            (ct) =>
                                ct?.language_name ===
                                languageData?.filter(
                                    (l) =>
                                        l.language_id === languageOption
                                )?.[0]?.language_name &&
                                ct?.type_content?.includes(currentTableType)
                        )
                        .map((option) => {
                            return (
                                <Option
                                    key={option?.category_name}
                                    value={option?.category_id}
                                >
                                    {option?.category_name}
                                </Option>
                            )
                        })}
                </Select> */}

                <div style={{ display: 'flex' }}>
                    <Input
                        placeholder="Search by Title.."
                        allowClear
                        value={searchData}
                        onChange={changeHandler}
                        style={{ width: 160, border: 'none' }}
                        onPressEnter={(e) => {
                            onSearch(e.target.value)
                        }}
                    />
                    <Button
                        type="primary"
                        style={{ borderLeft: 'none' }}
                        onClick={() => onSearch(searchData)}
                    >
                        Search
                    </Button>
                    <Button
                        className="refresh-button"
                        style={{ marginLeft: '1rem'}}
                        onClick={getInHouseAdsTableData}
                    >
                        <RedoOutlined/>
                        Refresh
                    </Button>
                </div>

                <Button
                    type="primary"
                    className="manage-button"
                    onClick={() => Navigate(`/home/adsContent?mode=add`)}
                >
                    Add ad
                </Button>

            </div>

            <div className='table-data'>
                <CommonTable
                    data={inHouseAdsData}
                    columns={InHouseAdsColumns}
                    dataLoading={dataLoading}
                ></CommonTable>
                {popup && (
                    <PreviewCorousel
                        data={inHouseAdsData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )}
            </div>

        </div>
    )

}

export default ManageAd