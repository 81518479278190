import { createSlice } from '@reduxjs/toolkit'
import { type } from '@testing-library/user-event/dist/type'
import { act } from 'react-dom/test-utils'

const initialState = {
    states: {},
    constituencies: {},
    initialPhase: [],
    formData: {
        electionType: 'assembly_elections',
        startDate: '',
        endDate: '',
        noOfPhases: '',
        countingDate: '',
    },
    selectedState:'',
    selectedElectionData:[],
    candidateData:[],
    temp:[]
}
const typeArray ={assembly_elections:"assembly_constituency",loksabha_elections:"lok_sabha",assembly_bye_elections:"assembly_constituency",loksabha_bye_elections:"lok_sabha"}

export const ElectionsSlice = createSlice({
    name: 'elections',
    initialState,
    reducers: {
        addFormData: (state, action) => {
            state.formData[action.payload.key] = action.payload.data
        },
        onChangeState:(state,action)=>{
            state.selectedState=action.payload
        },
        resetFormData:(state,action)=>{
           state.formData['electionType']=action.payload
           state.formData['noOfPhases'] = ''
        },
        addInitialPhase: (state, action) => {
            state.initialPhase = action.payload
        },
        dateChange: (state, action) => {
            state.initialPhase = state.initialPhase.map((e) => {
                if (e.key === action.payload.id) {
                    return { ...e, date: action.payload.date }
                }
                return e
            })
        },
        phaseChange:(state,action)=>{
            state.initialPhase = state.initialPhase.map((e) => {
                if (e.key === action.payload.id) {
                    return { ...e, phase: action.payload.phase }
                }
                return e
            })
        },
        addState: (state, action) => {
            state.states[action.payload.phase] = action?.payload.data
        },

        resetState:(state,action)=>{
            state.states={}
        },
        resetconstituencies:(state,action)=>{
            state.constituencies={}
        },
        handleOpenClick: (state, action) => {
            const { phase, id, direction } = action.payload

            // Ensure that the phase property exists in the states object
            if (!state.states[phase]) {
                state.states[phase] = [] // Initialize the phase array if it doesn't exist
            }

            // Map over the array and update the clicked state based on id and direction
            state.states[phase] = state.states[phase].map((e) => {
                if (e.state_id === id && direction === 'left') {
                    return { ...e, isClickedLeft: !e.isClickedLeft }
                } else if (e.state_id === id && direction === 'right') {
                    return { ...e, isClickedRight: !e.isClickedRight }
                }
                return e
            })
           
        },

        handleisChecked: (state, action) => {
            const { phase, id, direction, checked } = action.payload;
          
            // Update states[action.payload.phase]
            state.states[phase] = state.states[phase].map((e) => {
              if (id === e.state_id && direction === 'left') {
                return { ...e, isCheckedLeft: checked };
              } else if (id === e.state_id && direction === 'right') {
                return { ...e, isCheckedRight: checked };
              }
              return e;
            });
          
            // Update constituencies[action.payload.phase][action.payload.id]
            const selectedState = state.constituencies[phase][id].map((e) => {
             if(e.direction===direction){
                return { ...e, isChecked: checked };
             }
             return e
            });
            state.constituencies[phase][id] = selectedState;
          },
          
        addConstituencies: (state, action) => {
            const { phase, id, data } = action.payload

            if (!state.constituencies[phase]) {
                state.constituencies[phase] = {}
            }

            state.constituencies[phase][id] = data

            

            
        },
        handleConstituenciesCheck: (state, action) => {
        
            const type =typeArray[ state.formData.electionType]+"_no";
            let selectedState =
                state.constituencies[action.payload.phase][action.payload.id]
            selectedState = selectedState.map((e) => {
                if (e[type] === action.payload.no) {
                    e = { ...e, isChecked: !e.isChecked }
                }
                return e
            })
            state.constituencies[action.payload.phase][action.payload.id] =
                selectedState
        },
        handleMove: (state, action) => {
            state.states[action.payload.phase] = state.states[
                action.payload.phase
            ].map((e) => {
                return { ...e, isCheckedRight: false, isCheckedLeft: false }
            })
            Object.values(state.constituencies[action.payload.phase]).forEach(
                (arr) => {
                    arr.forEach((item) => {
                        // If isChecked is true, change direction to "right"
                        if (item.isChecked) {
                            item.direction = action.payload.direction
                            item.isChecked = false
                        }
                    })
                }
            )
        },
        addSelectedElectionData:(state,action)=>{
           
            state.selectedElectionData=action.payload;
        },
        getCandidateData:(state,action)=>{
          const data =   state.selectedElectionData.filter((e)=>e.state_name===action.payload.state_name)
          
          const candi = data[0]['constituencies']
          ?.map((e)=>{
            if(e[typeArray[action.payload.type]+"_name"]===action.payload.cons_name){
                
                if(e.candidates){
                    return e.candidates;
                }
                else{
                    return [];
                }
            }
          })
          state.candidateData =candi[0] ? candi[0]:[];
          
        },
        addCandidate:(state,action)=>{
            const obj = action.payload;
            const data =   state.selectedElectionData.filter((e)=>e.state_name===obj.state_name)
            
            let candi = data[0]['constituencies']
            candi=candi
            ?.map((e)=>{
              if(e[typeArray[obj.type]+"_name"]===obj.cons_name){
                  
                  if(e.candidates){
                      return {...e,candidates:[...e.candidates,{candidate_id:obj.candidate_id,party:obj.party,status:obj.status}]};
                  }
                  else{
                    return {...e,candidates:[{candidate_id:obj.candidate_id,party:obj.party,status:obj.status}]};
                  }
              }
              return e
            })
            state.selectedElectionData = state.selectedElectionData.map((e)=>{
                if(e.state_name===obj.state_name){
                    return {...e,constituencies:candi}
                }
                else{
                    return e
                }
            })
            state.temp=state.selectedElectionData
        },
        removeCandidate:(state,action)=>{
            const data =   state.selectedElectionData.filter((e)=>e.state_name===action.payload.state_name)
            
            let candi = data[0]?.['constituencies']
            ?.map((e)=>{
              if(e[typeArray[action.payload.type]+"_name"]===action.payload.cons_name){
                  
                  if(e.candidates){
                      return e.candidates;
                  }
                  else{
                      return [];
                  }
              }
            })
            candi =  candi[0].filter((e)=>e.candidate_id!==action.payload.id);
            state.candidateData =candi ? candi:[];
             let temp =  data[0]['constituencies']?.map((e)=>{
                if(e[typeArray[action.payload.type]+"_name"]===action.payload.cons_name){
                    return {...e,candidates:candi}
                }
                else{
                    return e
                }
            })
            state.selectedElectionData=state.selectedElectionData.map((e)=>{
                if(e.state_name===action.payload.state_name){
                    return {...e,constituencies:temp}
                }
                else{
                    return e
                }
            })
            
        }

    },
    
})

export const {
    addState,
    handleOpenClick,
    handleisChecked,
    addConstituencies,
    handleConstituenciesCheck,
    handleMove,
    addInitialPhase,
    resetState,
    resetconstituencies,
    dateChange,
    addFormData,
    phaseChange,
    resetFormData,
    onChangeState,
    addSelectedElectionData,
    getCandidateData,
    addCandidate,
    removeCandidate
} = ElectionsSlice.actions

export default ElectionsSlice.reducer
