import React, { useEffect, useRef, useState } from 'react'

import { Avatar, Button, Menu, Modal, notification, Popover } from 'antd'
import {
    NavBarItems,
    RootNavbarMenuKeys,
    findParentKeyByChildKey,
} from './NavbarConstant'
import './SideNavbar.scss'
import AadhanIcon from '../../assets/Aadhan.svg'
import {
    BellOutlined,
    DownOutlined,
    LoginOutlined,
    SettingFilled,
    UpOutlined,
    UserOutlined,
} from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin, changePopup } from '../../store/slice/AppSlice'
import Profile from '../Profile/Profile'
import { getEmployeeDetails, isAvailableApi } from '../../AadhanApi'
const SideNavbar = ({closable,setClosable}) => {
    const navigate = useNavigate()
    const [openKeys, setOpenKeys] = useState([])
    const [showSettingsDropdown, setShowSettingsDropDown] = useState(false)
    const [showProfileModal, setShowProfileModal] = useState(false)
    let permissionData = useSelector((state) => state.app.userPermissions)
    // Create a shallow copy
    let userPermissions = {
        ...permissionData,
        // Stories: {
        //     'List of Stories': [
        //         'Can Add',
        //         'Can Edit',
        //         'Can View',
        //         'Can Delete',
        //         'Can Approve',
        //     ],
        //     'Central Team Ops': [
        //         'Can Add',
        //         'Can Edit',
        //         'Can View',
        //         'Can Delete',
        //         'Can Approve',
        //     ],
        //     'Media Team': [
        //         'Can Add',
        //         'Can Edit',
        //         'Can View',
        //         'Can Delete',
        //         'Can Approve',
        //     ],
        //     Reporter: [
        //         'Can Add',
        //         'Can Edit',
        //         'Can View',
        //         'Can Delete',
        //         'Can Approve',
        //     ],
        //     Stages: [
        //         'Can Add',
        //         'Can Edit',
        //         'Can View',
        //         'Can Delete',
        //         'Can Approve',
        //     ],
        // },
    }
    // console.log(userPermissions)

    if (Object.keys(userPermissions).length) {
        if (userPermissions.Employees) {
            let Employees = {
                ...userPermissions.Employees,
                // Billing : ['Can Add', 'Can Edit', 'Can View', 'Can Delete', 'Can Approve']
            }
            userPermissions.Employees = Employees
        }
    }
    const employeeName = useSelector((state) => state.app.employeeName)
    const employeeId = useSelector((state) => state.app.employeeId)
    const accessToken = useSelector((state) => state.app.accessToken)
    const dispatch = useDispatch()
    const [initialFormData, setInitialFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        roleId: '',
        employeeName: '',
        roleName: '',
    })

    const [initialPasswordFormData, setInitialPasswordFormData] = useState({
        oldpassword: '',
        newpassword: '',
        confirmpassword: '',
    })

    const getEmployeeData = async () => {
        try {
            const response = await getEmployeeDetails(employeeId, accessToken)
            setInitialFormData({
                ...initialFormData,
                firstName: response?.data?.[0]?.first_name,
                lastName: response?.data?.[0]?.last_name,
                email: response?.data?.[0]?.email_id,
                phone: response?.data?.[0]?.mobile_number,
                roleId: response?.data?.[0]?.role_id,
                employeeName: response?.data?.[0]?.employee_name,
                roleName: response?.data?.[0]?.role_name,
                reportsTo: response?.data?.[0]?.reports_to,
                languages: response?.data?.[0]?.languages,
            })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (showProfileModal) {
            getEmployeeData()
            setInitialPasswordFormData({
                oldpassword: '',
                newpassword: '',
                confirmpassword: '',
            })
        }
    }, [showProfileModal])

    const location = useLocation()
    const defaultChildKey =
        location?.pathname && location?.pathname?.split('/')?.[2]
    const defaultParentKey = findParentKeyByChildKey(
        defaultChildKey,
        NavBarItems
    )
    useEffect(() => {
        setOpenKeys([
            defaultChildKey === 'employees' ? 'employees' : defaultParentKey,
        ])
    }, [])

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1)
        if (RootNavbarMenuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys)
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
        }
    }

    const AstrologyPermissin = localStorage.getItem('USER_PERMISSIONS')

    function getItem(item) {
        if (item?.children) {
            item.children = item.children.map((childItem) => {
                if (
                    userPermissions &&
                    userPermissions?.[item?.label] &&
                    Object.keys(userPermissions?.[item?.label])?.includes(
                        childItem?.label
                    ) &&
                    userPermissions?.[item?.label]?.[childItem?.label]?.length >
                        0
                ) {
                    return getItem(childItem)
                } else if (item.label === 'Astrology') {
                    return getItem(childItem)
                } else return null
            })
            return {
                key: item?.key,
                icon: item?.icon,
                children: item?.children,
                label: item?.label,
                type: item?.type,
            }
        } else {
            if (
                userPermissions?.[item?.label] &&
                Object.values(userPermissions?.[item?.label])?.length === 0
            ) {
                return null
            } else
                return {
                    key: item?.key,
                    icon: item?.icon,
                    children: item?.children,
                    label: item?.label,
                    type: item?.type,
                }
        }
    }

    const items = NavBarItems.map((item) => {
        if (
            item.label === 'Astrology' &&
            AstrologyPermissin &&
            JSON.parse(AstrologyPermissin)?.Astrology?.Horoscope.includes(
                'Can View'
            ) |
                JSON.parse(AstrologyPermissin)?.Astrology?.Panchangam.includes(
                    'Can View'
                )
        ) {
            const data = getItem(item)
            if (
                !JSON.parse(AstrologyPermissin)?.Astrology?.Horoscope.includes(
                    'Can View'
                )
            ) {
                return { ...data, children: [data.children[1]] }
            } else if (
                !JSON.parse(AstrologyPermissin)?.Astrology?.Panchangam.includes(
                    'Can View'
                )
            ) {
                return { ...data, children: [data.children[0]] }
            }
            return getItem(item)
        } else if (
            item.label !== 'Astrology' &&
            userPermissions?.[item?.label] &&
            Object.keys(userPermissions?.[item?.label])?.length > 0 &&
            Object.values(userPermissions?.[item?.label])?.some(
                (data) => data?.length > 0
            )
        ) {
            return getItem(item)
        } else return null
    })

    const onClickNavItem = (event) => {
        const modalMask = document.querySelector('.ant-modal-wrap')
        if (modalMask) {
            modalMask.style.display = 'none'
        }
        dispatch(changePopup(false))
        const previousPathname =
            location?.pathname && location?.pathname?.split('/')?.[2]
        localStorage.removeItem(previousPathname?.toLowerCase())
        if (event.key === 'Dashboard') {
            navigate(`/home/${event?.key}`)
        }
        navigate(`/home/${event?.key}`)
    }

    const onLogout = async () => {
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('USER_PERMISSIONS')
        localStorage.removeItem('EMPLOYEE_ID')
        localStorage.removeItem('EMPLOYEE_NAME')
        dispatch(userLogin({}))
        navigate('/')
    }
    const handleConfirm = async () => {
        notification.open({
            message: 'Availability Update',
            description:
                'Your tasks for today have been reassigned. Please take a break."',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true)
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('USER_PERMISSIONS')
        localStorage.removeItem('EMPLOYEE_ID')
        localStorage.removeItem('EMPLOYEE_NAME')
        dispatch(userLogin({}))
        setShowPopover(false)
        navigate('/')
    }

    const handleCancel = async () => {
        notification.open({
            message: 'Availability Update',
            description: 'Short break time! Take a moment to recharge.',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false,true)
        setShowPopover(false)
        localStorage.removeItem('ACCESS_TOKEN')
        localStorage.removeItem('USER_PERMISSIONS')
        localStorage.removeItem('EMPLOYEE_ID')
        localStorage.removeItem('EMPLOYEE_NAME')
        dispatch(userLogin({}))
        navigate('/')
    }

    const profileModal = (
        <Modal
            centered
            open={showProfileModal}
            title={'Settings'}
            footer={null}
            width={'65vw'}
            onCancel={() => setShowProfileModal(false)}
        >
            <Profile
                initialFormData={initialFormData}
                initialPasswordFormData={initialPasswordFormData}
                employeeId={employeeId}
                token={accessToken}
            />
        </Modal>
    )

    const openProfileModal = () => {
        setShowProfileModal(true)
    }
    const dropdownRef = useRef(null)

    const handleClickOutside = (event) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target)
        ) {
            setShowSettingsDropDown(false)
        }
    }
    const [showPopover, setShowPopover] = useState(false)

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])
    return (
        <div className="nav-bar">
            <div className="navbar-side-container">
                <div className="user-info-container">
                    <div className="user-details-container">
                        <img
                            onClick={()=>setClosable(!closable)}
                            src={AadhanIcon}
                            className="aadhan-icon"
                            alt ="Aadhan Icon"
                        />
                    </div>
                   
                </div>
                <Menu
                    inlineCollapsed={closable}
                    onClick={onClickNavItem}
                    defaultOpenKeys={[defaultParentKey]}
                    defaultSelectedKeys={[defaultChildKey]}
                    theme="dark"
                    openKeys={openKeys}
                    onOpenChange={onOpenChange}
                    style={{
                        // width: 256,
                        height: '100%',
                        overflowY: 'auto',
                        
                    }}
                    mode="inline"
                    items={items}
                    
                />
                <Menu />
            </div>
            <div className="top-nav-bar">
                <div
                    className="logged-in-user"
                    onClick={() =>
                        setShowSettingsDropDown(!showSettingsDropdown)
                    }
                    ref={dropdownRef}
                >
                    <Avatar shape="circle" size={45} icon={<UserOutlined />} />
                    <div className="profile-arrow-container">
                        <h2 className="profile-info">{employeeName}</h2>
                        <div>
                            {showSettingsDropdown ? (
                                <UpOutlined />
                            ) : (
                                <DownOutlined />
                            )}
                        </div>
                    </div>
                    <div
                        className={`user-dropdown-content ${
                            showSettingsDropdown ? 'show' : ''
                        }`}
                    >
                        <Popover
                            content={
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginRight: '2rem',
                                    }}
                                >
                                    <Button
                                        className="dayOff"
                                        style={{
                                            width: 150,
                                            marginBottom: '.5rem',
                                            background: 'blue',
                                            color: 'white',
                                        }}
                                        onClick={handleCancel}
                                    >
                                        Short Break
                                    </Button>
                                    <Button
                                        className="dayOff"
                                        style={{
                                            width: 150,
                                            background: 'red',
                                            color: 'white',
                                        }}
                                        onClick={handleConfirm}
                                    >
                                        Day Off
                                    </Button>
                                </div>
                            }
                            title="You are about to set"
                            trigger="click"
                            visible={showPopover}
                            onVisibleChange={(visible) =>
                                !visible && setShowPopover(false)
                            }
                        ></Popover>
                        <div
                            className="dropdown-row"
                            onClick={openProfileModal}
                        >
                            <span className="user-dropdown-text">Profiles</span>
                            <SettingFilled />
                        </div>

                        <div
                            className="dropdown-row"
                            onClick={() => {
                                const roleId = parseInt(
                                    localStorage.getItem('ROLE_ID')
                                )
                                const isAvailable =
                                    localStorage.getItem('is_Available') ===
                                    'true'

                                if (
                                    (roleId === 3 || roleId === 21) &&
                                    isAvailable
                                ) {
                                    setShowPopover(true)
                                } else {
                                    onLogout()
                                }
                            }}
                        >
                            <span className="user-dropdown-text">Logout</span>
                            <LoginOutlined />
                        </div>
                    </div>
                </div>
            </div>
            {profileModal}
        </div>
    )
}
export default SideNavbar
