import { Button, Form, Input, Radio, Select, Upload, message, Switch } from 'antd'
import { UploadOutlined, UserAddOutlined } from '@ant-design/icons'
import { createCategory, getImageUrlR2, updateCategory } from '../../AadhanApi'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { categoryTypeOptions } from './AddCategory.constant'
import { useState } from 'react'

const AddCategory = ({
    setShowModal,
    selectedCategory,
    accessToken,
    getCategoryTable,
}) => {
    const [form] = Form.useForm()
    const languageData = useSelector((state) => state.app.languageData)
    const [messageApi, contextHolder] = message.useMessage()
    const employeeId = useSelector((state) => state.app.employeeId)
    const [displayApp, setDisplayApp] = useState(selectedCategory?.displayin_app)

    form.setFieldsValue({
        categoryName: selectedCategory?.category_name,
        language: selectedCategory?.language_id,
        image: selectedCategory?.image_url,
        categoryType: selectedCategory?.type_content,
        status: selectedCategory?.status,
        displayin_app: selectedCategory?.displayin_app,
        displayName: selectedCategory?.display_name,
        rank:selectedCategory?.rank,
        display_for_reporters:selectedCategory?.display_for_reporters
    })
    
    const onFinish = async (values) => {
        const obj = {
            category_name: values.categoryName,
            type_content: values.categoryType,
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            image_url:
                values.image?.fileList?.[0]?.response ??
                selectedCategory?.image_url,
            language_id: values.language,
            status: values?.status,
            displayin_app: values?.displayin_app,
            display_name: values?.displayName,
            rank:values?.rank,
            display_for_reporters:values?.display_for_reporters
        }
        try {
            if (selectedCategory) {
                await updateCategory(
                    obj,
                    accessToken,
                    selectedCategory.category_id
                )
            } else {
                await createCategory(obj, accessToken)
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }

        getCategoryTable()
        setShowModal(false)
        form.resetFields()
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const draggerProps = {
        name: 'file',
        customRequest: ({ file, onSuccess }) => {
            getImageUrlR2(file, accessToken, 'category').then((pathUriArr) => {
                if (pathUriArr?.data) {
                    const path = pathUriArr?.data
                    onSuccess(path)
                }
            })
        },
    }
    return (
        <div>
            {contextHolder}
            <Form
                id="categoryForm"
                form={form}
                name="categoryForm"
                scrollToFirstError
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select Language' },
                    ]}
                >
                    <Select placeholder="--Select Language--">
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Category Name"
                    name="categoryName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter category name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Display Name"
                    name="displayName"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter display name',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <div style={{display:"flex",justifyContent:"space-around"}}>

                <Form.Item  labelCol={{ span: 24 }} label="Show in app" name="displayin_app">
                  

                    
                        <Switch checkedChildren="Yes" unCheckedChildren="No" className='status' 
                            defaultunChecked
                            // onChange={() => setDisplayApp(!displayApp)}
                            />
                    
                   
                </Form.Item>
                <Form.Item
                                labelCol={{ span: 24 }}
                                label="Show for reporters"
                                name="display_for_reporters"
                            >
                                <Switch
                                    className='status'
                                    checkedChildren={'Yes'}
                                    unCheckedChildren={'No'}
                                    defaultunChecked
                                />
                </Form.Item>
                

                <Form.Item  labelCol={{ span: 24 }} style={{width:"4rem"}} label="Rank" name="rank">
                
                    <Input/>
        
                 
              </Form.Item>
                </div>

                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Content Type"
                    name="categoryType"
                    rules={[
                        {
                            required: true,
                            message: 'Please enter Content type',
                        },
                    ]}
                >
                    <Select mode="multiple">
                        {categoryTypeOptions.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item labelCol={{ span: 24 }} label="Status" name="status">
                    <Radio.Group>
                        <Radio value="active"> Active </Radio>
                        <Radio value="inactive"> Inactive </Radio>
                    </Radio.Group>
                </Form.Item>
                
                <Form.Item
                    labelCol={{ span: 24 }}
                    label="Image"
                    name="image"
                    rules={[{ required: true, message: 'Please upload image' }]}
                >
                    <Upload {...draggerProps}>
                        <Button icon={<UploadOutlined />}>Upload</Button>
                        <div style={{ color: '#199be9' }}>
                            image size as 144*144
                        </div>
                    </Upload>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddCategory
