import React from 'react'
import { Button, Select } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const { Option } = Select

function HoroscopeDropdowns({
    language,
    setLanguage,
    month,
    setMonth,
    day,
    setDay,
    hasData,
    onSummarizeAll,
    isSaveDirectlyDisabled,
    isSummarizeDisabled,
    isGridButtonDisabled,
    uploadedDates,
    onSaveDirectly,
}) {
    const languageData = useSelector((state) => state.app.languageData)

    const currentDate = new Date()
    const currentMonth = currentDate.getMonth()
    const currentYear = currentDate.getFullYear()
    const currentDay = currentDate.getDate()

    const allMonths = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    const avalaiableMonths = allMonths.slice(currentMonth)

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/home/daily-horoscopegrid', {
            state: {
                language,
                month: allMonths[parseInt(month)],
                day,
                uploadedDates,
            },
        })
    }

    const getDaysInMonth = (month) => {
        if (month === null) return 31
        const year = currentYear
        return new Date(year, month + 1, 0).getDate()
    }
    const getAvaliableDays = (selectedMonth) => {
        if (selectedMonth === null) return []
        const totalDays = getDaysInMonth(selectedMonth)
        let startDay = 1

        if (selectedMonth === currentMonth) {
            startDay = currentDay
        }

        return Array.from(
            { length: totalDays - startDay + 1 },
            (_, i) => i + startDay
        )
    }

    const handleMonthChange = (selectedMonth) => {
        setMonth(selectedMonth)
        if (
            parseInt(selectedMonth) !== currentMonth ||
            parseInt(day) < currentDay
        ) {
            setDay(
                currentMonth === parseInt(selectedMonth)
                    ? currentDay.toString()
                    : '1'
            )
        }
    }

    return (
        <div className="dropdowns">
            <div>
                <Select
                    placeholder="Select a Language"
                    value={language}
                    onChange={setLanguage}
                    className="dropdown-1"
                >
                    {languageData.map((arr) => (
                        <Option key={arr.language_id} value={arr.language_id}>
                            {arr.language_name}
                        </Option>
                    ))}
                </Select>
                <Select
                    value={
                        month !== null ? allMonths[parseInt(month)] : undefined
                    }
                    onChange={handleMonthChange}
                    placeholder="Select Month"
                    className="dropdown-2"
                >
                    {avalaiableMonths.map((m, i) => (
                        <Option key={m} value={(i + currentMonth).toString()}>
                            {m}
                        </Option>
                    ))}
                </Select>
                <Select
                    value={day !== null ? day.toString() : undefined}
                    onChange={setDay}
                    placeholder="Select Day"
                    disabled={month === null}
                    className="dropdown-3"
                >
                    {getAvaliableDays(parseInt(month)).map((d) => (
                        <Option key={d} value={d.toString()}>
                            {d}
                        </Option>
                    ))}
                </Select>
            </div>
            <div>
                <Button
                    type="primary"
                    className="saveDirectlyBtn"
                    onClick={onSaveDirectly}
                    disabled={isSaveDirectlyDisabled}
                >
                    Save Directly
                </Button>
                <Button
                    type="primary"
                    className="mainSummarizeBtn"
                    onClick={onSummarizeAll}
                    Button
                    disabled={isSummarizeDisabled}
                >
                    Summarize and Save
                </Button>
                <Button
                    type="primary"
                    className="viewGridBtn"
                    onClick={handleClick}
                    disabled={!hasData || isGridButtonDisabled}
                >
                    View Grid
                </Button>
            </div>
        </div>
    )
}

export default HoroscopeDropdowns
