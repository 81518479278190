import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './CommonTable.scss'

const CommonTableWithBorder = ({className,columns, data, dataLoading,rowClassName, disablePagination, onRow }) => {
    const [inputColumns, setInputColums] = useState([...columns])
    const [paginationOptions,setpaginationOptions] = useState({
       
            pageSizeOptions: ['2','7', '20', '25', '30',],
            showSizeChanger: true,           
            current:1
        
    })
    
    const handleOnchange = (pagination, filters, sorter) => {
        let options = JSON.parse(localStorage.getItem('options')) || {};
        
        if (typeof options !== 'object' || options === null) {
            options = {};
        }
        
        options.page = pagination.current;
        localStorage.setItem('options', JSON.stringify(options));
        
        setpaginationOptions(prevOptions => ({
            ...prevOptions,
            current: pagination.current,
            pageSize: pagination.pageSize
        }));
        
        if (sorter.field && sorter.order) {
            setInputColums(prevState => 
                prevState.map(col => {
                    if (col.dataIndex === sorter.field) {
                        return { ...col, sortOrder: sorter.order };
                    }
                    return col;
                })
            );
        } else {
            setInputColums(prevState => 
                prevState.map(col => ({
                    ...col,
                    sortOrder: col.dataIndex === sorter.field ? null : col.sortOrder
                }))
            );
        }
    };
    
    


    useEffect(() => {
        setInputColums([...columns])
    }, [columns])

    

    useEffect(()=>{
        setpaginationOptions({...paginationOptions,current:1})
        
    },[data])

    return (
        <div className="table-container">
            <Table
                // className={className}
                className={`custom-table ${className}`}
                pagination={disablePagination ? false : paginationOptions}
                scroll={{ x: '100%' }}
                columns={inputColumns}
                dataSource={data}
                loading={dataLoading}
                onChange={handleOnchange}
                rowClassName={rowClassName}
                onRow={onRow}
                bordered
                
            />
        </div>
    )
}
export default CommonTableWithBorder


