import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './CommonTable.scss'
import { useLocation } from 'react-router'

const CommonTable = ({ pageSize, columns, data, dataLoading,rowClassName, disablePagination, onRow }) => {
    const [inputColumns, setInputColums] = useState([...columns])
    const location = useLocation()
    const [paginationOptions,setpaginationOptions] = useState({
       
            pageSizeOptions: ['2','7', '20', '25', '30',],
            showSizeChanger: true,           
            current:1
        
    })
    
    // const handleOnchange = (pagination, filters, sorter) => {
    //     // Retrieve the 'options' object from localStorage or create a new object if it doesn't exist
    //     let options = JSON.parse(localStorage.getItem('options')) || {};
    
    //     // If 'options' is not an object, initialize it as an empty object
    //     if (typeof options !== 'object' || options === null) {
    //         options = {};
    //     }
    
    //     // Update the 'page' property
    //     options.page = pagination?.current;
    //     localStorage.setItem('options', JSON.stringify(options));
    //     setpaginationOptions({...pagination})
    //     if (sorter.field && sorter.order) {
    //         setInputColums((prevState) => {
            
    //             const sortedColumn = prevState.find(
    //                 (col) => col.dataIndex === sorter.field
    //             )
    //             sortedColumn.sortOrder = sorter.order
    //             return [...prevState]
    //         })
    //     }
    // }
    
    
    const handleOnchange = (pagination, filters, sorter) => {
        let options = JSON.parse(localStorage.getItem('options')) || {};
        
        if (typeof options !== 'object' || options === null) {
            options = {};
        }
        
        options.page = pagination.current;
        localStorage.setItem('options', JSON.stringify(options));
        
        setpaginationOptions(prevOptions => ({
            ...prevOptions,
            current: pagination.current,
            pageSize: pagination.pageSize
        }));
        
        if (sorter.field && sorter.order) {
            setInputColums(prevState => 
                prevState.map(col => {
                    if (col.dataIndex === sorter.field) {
                        return { ...col, sortOrder: sorter.order };
                    }
                    return col;
                })
            );
        } else {
            setInputColums(prevState => 
                prevState.map(col => ({
                    ...col,
                    sortOrder: col.dataIndex === sorter.field ? null : col.sortOrder
                }))
            );
        }
    };
    

    useEffect(() => {
        setInputColums([...columns])
    }, [columns])

    

    useEffect(()=>{
        if(location.pathname.includes('visuals')){

            setpaginationOptions({...paginationOptions,current:JSON?.parse(localStorage?.getItem('options'))?.page===undefined ? 1 : JSON?.parse(localStorage?.getItem('options'))?.page})
        }
        else{

            setpaginationOptions({...paginationOptions,current:1})
        }
        
    },[data])

    return (
        <div className="table-container">
            <Table
                
                pagination={disablePagination ? false : paginationOptions}
                scroll={{ x: '100%' }}
                columns={inputColumns}
                dataSource={data}
                loading={dataLoading}
                onChange={handleOnchange}
                rowClassName={rowClassName}
                onRow={onRow}                
                
            />
        </div>
    )
}
export default CommonTable


