export const languageOptions = [
    {
        label: 'English',
        value: 'english',
    },
    {
        label: 'French',
        value: 'french',
    },
]

export const expiryHoursOptions = [1, 4, 6, 12, 24, 48, 'Never']

export const publishRadioOptions = [
    {
        label: 'Immediately',
        value: 'immediately',
    },
    {
        label: 'Schedule',
        value: 'schedule',
    },
]

export const autoCompleteOptions = [
    { value: 'test1' },
    { value: 'Hindustan Times' },
    { value: 'TOI' },
]

export const categoryOptions = [
    { label: 'Crime', value: 'crime' },
    { label: 'Education', value: 'education' },
]

export const breakingNewsOptions = [
    { label: 'Less Important', value: 'lessImportant' },
    { label: 'Important', value: 'important' },
    { label: 'Very Important', value: 'veryImportant' },
]

export const locationOptions = [
    { label: 'Delhi', value: 'delhi' },
    { label: 'Mumbai', value: 'mumbai' },
]
export const subData = {
    137: [
        'TDP Supporters',
        'YSRCP Supporters',
        'Janasena Supporters',
        'BJP, Congress & Others',
    ],
    141: [
        'Mega & Kapu audience',
        'Nandamuri & Kamma audience',
        'Prabhas & Raju audience',
        'Reddy, Velama & other audience',
    ],
    135:[
        "Football",
        "Basketball",
        "Baseball",
        "Soccer",
        "Tennis",
        "Golf",
        "Cricket",
        "Hockey",
        "Boxing",
        "Cycling",
        "Swimming",
        "Volleyball",
      
      ]
      
}
