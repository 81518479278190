import React, { useState, useEffect } from 'react'
import {
    Button,
    Select,
    Modal,
    Form,
    Input,
    Switch,
    Popconfirm,
    message,
} from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import { useSelector } from 'react-redux'
import './ManagePlaylist.scss'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import {
    getVisualsPlaylists,
    createVisualsPlaylists,
    updateVisualsByPlaylist,
    deleteVisualsByPlaylist,
    getVisualsCategory,
} from '../../AadhanApi'
import DraggableList from './DraggableList'

const { Option } = Select
const Playlist = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const [selectedLanguage, setSelectedLanguage] = useState(1)
    const [selectedShowInApp, setSelectedShowInApp] = useState()
    const [filteredData, setFilteredData] = useState([])
    const [isDeleting, setIsDeleting] = useState(false)
    const [data, setData] = useState([])
    const [form] = Form.useForm()
    const [modalTitle, setModalTitle] = useState('Add Playlist')
    const [isEdit, setIsEdit] = useState(false)
    const [editData, setEditData] = useState({})
    const [categoryOptions, setCategoryOptions] = useState([])
    const [filteredCategoryOptions, setFilteredCategoryOptions] = useState([])
    const [existingPlaylistNames, setExistingPlaylistNames] = useState({})
    const accessToken = useSelector((state) => state.app.accessToken)
    const [orderedCategory, setOrderedCategory] = useState([])
    useEffect(() => {
        if (languageData && languageData.length > 0) {
            const defaultLanguage = languageData.find(
                (lang) => lang.language_name.toLowerCase() === 'english'
            )
            const defaultLanguageId = defaultLanguage
                ? defaultLanguage.language_id
                : languageData[0].language_id
            setSelectedLanguage(defaultLanguageId)
            form.setFieldsValue({ language_id: defaultLanguageId })
        }
    }, [languageData, form])

    useEffect(() => {
        filterData()
    }, [data, selectedLanguage, selectedShowInApp])

    useEffect(() => {
        if (accessToken) {
            getPlaylistData(accessToken)
        }
    }, [accessToken, selectedLanguage])

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await getVisualsCategory(
                    accessToken,
                    selectedLanguage
                )
                const categories = response.data.data.map((category) => ({
                    value: category.category_id,
                    label: category.category_name,
                    language_id: category.language_id,
                }))
                setCategoryOptions(categories)
            } catch (error) {
                console.error('Failed to fetch categories:', error)
                message.error('Failed to fetch categories')
            }
        }

        if (accessToken && selectedLanguage) {
            fetchCategories()
        }
    }, [accessToken, selectedLanguage])

    useEffect(() => {
        const formLanguage = form.getFieldValue('language_id')
        const languageToUse = formLanguage || selectedLanguage
        const filtered = categoryOptions.filter(
            (category) => category.language_id === languageToUse
        )
        setFilteredCategoryOptions(filtered)
    }, [selectedLanguage, categoryOptions, form])

    const getPlaylistData = async (token) => {
        try {
            const response = await getVisualsPlaylists(token, selectedLanguage)
            const newData = response.data.data.map((item) => ({
                ...item,
                categories: item.categories || [],
                local_field: item.local_field || '',
            }))
            setData(newData)
            filterData(newData)
            const playlistNames = {}
            newData.forEach((item) => {
                if (!playlistNames[item.language_id]) {
                    playlistNames[item.language_id] = new Set()
                }
                playlistNames[item.language_id].add(
                    item.playlist_name.toLowerCase()
                )
            })
            setExistingPlaylistNames(playlistNames)
        } catch (error) {
            message.error('Failed to fetch playlist data')
            console.error(error)
        }
    }

    const filterData = (
        dataToFilter = data,
        lang = selectedLanguage,
        showInApp = selectedShowInApp
    ) => {
        let filtered = [...dataToFilter]

        if (lang) {
            filtered = filtered.filter((item) => item.language_id === lang)
        }

        if (showInApp !== undefined) {
            filtered = filtered.filter((item) => item.show_in_app === showInApp)
        }

        setFilteredData(filtered)
    }

    const handleAddPlaylist = async () => {
        setModalTitle('Add Playlist')
        setOrderedCategory([])
        setIsEdit(false)
        setEditData({})
        const response = await getVisualsCategory(accessToken, selectedLanguage)
        const categories = response.data.data.map((category) => ({
            value: category.category_id,
            label: category.category_name,
            language_id: category.language_id,
        }))
        setCategoryOptions(categories)
        setFilteredCategoryOptions(categories)
        form.resetFields()
        form.setFieldsValue({ language_id: selectedLanguage })
        setIsModalOpen(true)
        filterData(data, selectedLanguage, selectedShowInApp)
    }

    const handleEditPlaylist = (record) => {
        setModalTitle('Edit Playlist')
        setIsEdit(true)
        setEditData(record)
        getVisualsCategory(accessToken, record.language_id)
            .then((response) => {
                let list = []
                const categories = response.data.data.reduce(
                    (acc, category) => {
                        if (!record.categories.includes(category.category_id)) {
                            acc.push({
                                value: category.category_id,
                                label: category.category_name,
                                language_id: category.language_id,
                            })
                        } else {
                            list.push({
                                value: category.category_id,
                                label: category.category_name,
                                // language_id: category.language_id,
                            })
                        }
                        return acc
                    },
                    []
                )

                // Do something with the categories array
                setOrderedCategory(
                    list.sort(
                        (a, b) =>
                            record?.categories?.indexOf(a.value) -
                            record?.categories?.indexOf(b.value)
                    )
                )

                setCategoryOptions(categories)
                setFilteredCategoryOptions(categories)
                // const selectedCategoryIds = record.categories.map(
                //     (cat) => cat.category_id
                // )

                form.setFieldsValue({
                    language_id: record.language_id,
                    playlist_name: record.playlist_name,
                    // category_name: record.categories,
                    show_in_app: record.show_in_app,
                    local_field: record.local_name,
                })

                setIsModalOpen(true)
                setSelectedLanguage(record.language_id)
                filterData(data, record.language_id, selectedShowInApp)
            })
            .catch((error) => {
                console.error('Failed to fetch categories:', error)
                message.error('Failed to fetch categories')
            })
    }

    const handleSubmit = async (values) => {
        try {
            const languageId = values.language_id
            const playlistName = values.playlist_name.trim().toLowerCase()
            if (
                existingPlaylistNames[languageId] &&
                existingPlaylistNames[languageId].has(playlistName)
            ) {
                if (
                    !isEdit ||
                    (isEdit &&
                        playlistName !== editData.playlist_name.toLowerCase())
                ) {
                    message.error(
                        'A playlist with this name already exists for the selected language.'
                    )
                    return
                }
            }
            let cat_id = []
            let cat_name = []
            orderedCategory.map((e) => {
                cat_id.push(e.value)
                cat_name.push(e.label)
            })
            const payload = {
                language_id: values.language_id,
                playlist_name: values.playlist_name,
                categories: cat_id,
                category_name: cat_name,
                show_in_app: values.show_in_app,
                local_name: values.local_field || '',
            }
            if (isEdit) {
                const response = await updateVisualsByPlaylist(
                    editData.playlist_id,
                    payload,
                    accessToken
                )
                if (response.status === 200) {
                    message.success('Playlist updated successfully')
                    await getPlaylistData(accessToken)
                }
            } else {
                const response = await createVisualsPlaylists(
                    payload,
                    accessToken
                )
                if (response.status === 200 || response.status === 201) {
                    message.success('Playlist added successfully')
                    await getPlaylistData(accessToken)
                }
            }
            setIsModalOpen(false)
            form.resetFields()
            setIsEdit(false)
            setEditData({})
        } catch (error) {
            console.error('Error updating playlist:', error)
            message.error(
                `Failed to ${isEdit ? 'update' : 'add'} playlist: ${
                    error.message
                }`
            )
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = async (record = null) => {
        if (record) {
            setModalTitle('Edit Playlist')
            setIsEdit(true)
            setEditData(record)

            const filteredCategories = categoryOptions.filter(
                (category) => category.language_id === record.language_id
            )

            const selectedCategoryIds = record.categories.map(
                (cat) => cat.category_id
            )

            form.setFieldsValue({
                language_id: record.language_id,
                playlist_name: record.playlist_name,
                category_name: selectedCategoryIds,
                show_in_app: record.show_in_app,
                local_field: record.local_field,
            })

            setSelectedLanguage(record.language_id)
            filterData(data, record.language_id, selectedShowInApp)

            // setFilteredCategoryOptions(filteredCategories)
            setFilteredCategoryOptions(
                filteredCategories.map((category) => ({
                    ...category,
                    disabled: selectedCategoryIds.includes(category.value),
                }))
            )
        } else {
            setModalTitle('Add Playlist')
            setIsEdit(false)
            setEditData({})
            form.resetFields()
            try {
                const response = await getVisualsCategory(
                    accessToken,
                    selectedLanguage
                )
                const categories = response.data.data.map((category) => ({
                    value: category.category_id,
                    label: category.category_name,
                    language_id: category.language_id,
                }))
                setCategoryOptions(categories)
                setFilteredCategoryOptions(categories)
            } catch (error) {
                console.error('Failed to fetch categories:', error)
                message.error('Failed to fetch categories')
            }

            form.setFieldsValue({ language_id: selectedLanguage })
        }
        setIsModalOpen(true)
    }
    const handleOk = () => {
        form.validateFields().then((values) => {
            const newData = {
                playlist_name: values.playlist,
                categorytype: values.categoryname,
                show_in_app: values.showInApp,
                language: values.language,
            }
            setIsModalOpen(false)
            form.resetFields()
        })
    }
    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields()
    }

    const handleLanguageChange = (value) => {
        setOrderedCategory([])
        setSelectedLanguage(value)
        form.setFieldsValue({ language_id: value, category_name: [] })
        filterData(data, value, selectedShowInApp)
        getVisualsCategory(accessToken, value)
            .then((response) => {
                const categories = response.data.data.map((category) => ({
                    value: category.category_id,
                    label: category.category_name,
                    language_id: category.language_id,
                }))
                setCategoryOptions(categories)
                setFilteredCategoryOptions(categories)
            })
            .catch((error) => {
                console.error('Failed to fetch categories:', error)
                message.error('Failed to fetch categories')
            })
    }
    const handleShowInAppChange = (value) => {
        setSelectedShowInApp(
            value === 'Yes' ? true : value === 'No' ? false : undefined
        )
    }
    const deleteRow = async (record) => {
        setIsDeleting(true)
        try {
            const response = await deleteVisualsByPlaylist(
                record.playlist_id,
                accessToken
            )

            if (response.status === 200) {
                message.success('Playlist deleted successfully')
                setData((prevData) =>
                    prevData.filter((item) => item.id !== record.playlist_id)
                )
                setFilteredData((prevData) =>
                    prevData.filter((item) => item.id !== record.playlist_id)
                )
                await getPlaylistData(accessToken)
            }
        } catch (error) {
            message.error('Failed to delete Playlist')
            console.error(error)
        } finally {
            setIsDeleting(false)
        }
    }
    const columns = [
        {
            title: 'Playlist Name',
            dataIndex: 'playlist_name',
            key: 'playlist_name',
            width: '26%',
            render: (text, record) => (
                <div>
                    <div>{text}</div>
                    {record.local_name && (
                        <div
                            style={{
                                fontSize: '0.8em',
                                color: '#666',
                                marginTop: '4px',
                            }}
                        >
                            {record.local_name}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Number of Categories',
            dataIndex: 'categories',
            key: 'categories',
            width: '26%',
            render: (categories) => categories.length,
        },
        // {
        //     title: 'Local Name',
        //     dataIndex: 'local_field',
        //     key: 'local_field',
        //     width: '22%',
        // },
        {
            title: 'Show in App',
            dataIndex: 'show_in_app',
            key: 'show_in_app',
            width: '23%',
            render: (text) => (text ? 'Yes' : 'No'),
        },

        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: '25%',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px',
                        fontSize: '1.2rem',
                    }}
                >
                    <EditOutlined onClick={() => handleEditPlaylist(record)} />
                    <Popconfirm
                        title="Delete Playlist"
                        description="Are you sure to delete this Playlist?"
                        onConfirm={() => deleteRow(record)}
                        okText="Yes"
                        cancelText="No"
                        disabled={isDeleting}
                    >
                        <DeleteOutlined
                            style={{
                                paddingLeft: '5px',
                                color: isDeleting ? 'grey' : 'inherit',
                                cursor: isDeleting ? 'not-allowed' : 'pointer',
                            }}
                        />
                    </Popconfirm>
                </div>
            ),
        },
    ]
    function removeCategory(id) {
        setCategoryOptions((pre) => [
            ...pre,
            ...orderedCategory?.filter((e) => e?.value === id),
        ])

        setOrderedCategory(orderedCategory?.filter((e) => e?.value !== id))
    }

    const modal = (
        <>
            <Button
                type="primary"
                className="custom-button"
                onClick={handleAddPlaylist}
            >
                Add Playlist
            </Button>
            <Modal
                visible={isModalOpen}
                onCancel={handleCancel}
                width={550}
                footer={null}
            >
                <h2>{modalTitle}</h2>
                <hr />
                <Form form={form} onFinish={handleSubmit}>
                    <Form.Item
                        label={
                            <span style={{ fontWeight: 550 }}>Language</span>
                        }
                        name="language_id"
                        wrapperCol={{ span: 15 }}
                        rules={[
                            {
                                required: true,
                                message: 'Please select language',
                            },
                        ]}
                        className="form-item"
                        labelCol={{ span: 24 }}
                    >
                        <Select
                            onChange={handleLanguageChange}
                            className="form-item"
                        >
                            {languageData?.map((option) => (
                                <Select.Option
                                    key={option?.language_id}
                                    value={option?.language_id}
                                >
                                    {option?.language_name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={
                            <span style={{ fontWeight: 550 }}>
                                Playlist Name
                            </span>
                        }
                        name="playlist_name"
                        wrapperCol={{ span: 15 }}
                        rules={[
                            {
                                required: true,
                                message: ' Please enter the Playlist Name',
                            },
                        ]}
                        className="form-item"
                        labelCol={{ span: 24 }}
                    >
                        <Input
                            placeholder="Enter Playlist Name"
                            className="form-item"
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <span style={{ fontWeight: 550 }}>Local Name</span>
                        }
                        name="local_field"
                        wrapperCol={{ span: 15 }}
                        rules={[
                            {
                                required: false,
                                message: ' Please enter the Local Name',
                            },
                        ]}
                        className="form-item"
                        labelCol={{ span: 24 }}
                    >
                        <Input
                            placeholder="Enter Local Name"
                            className="form-item"
                        />
                    </Form.Item>
                    <Form.Item
                        label={
                            <span style={{ fontWeight: 550 }}>Show in App</span>
                        }
                        name="show_in_app"
                        // labelCol={{ span: 6 }}
                        // wrapperCol={{ span: 10 }}
                        className="switch-wrapper"
                        valuePropName="checked"
                    >
                        <Switch
                            className={`custom-switch ${
                                form.getFieldValue('show_in_app')
                                    ? 'checked'
                                    : 'unchecked'
                            }`}
                            checkedChildren="YES"
                            unCheckedChildren="NO"
                            style={{
                                marginTop: '2px',
                                backgroundColor: form.getFieldValue('showInApp')
                                    ? 'green'
                                    : 'grey',
                                border: ` ${
                                    form.getFieldValue('showInApp')
                                        ? 'darkgreen'
                                        : 'darkgrey'
                                }`,
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="category_name"
                        wrapperCol={{ span: 15 }}
                        className="form-item"
                        labelCol={{ span: 24 }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Form.Item
                                label={
                                    <span style={{ fontWeight: 550 }}>
                                        Select Category
                                    </span>
                                }
                                name="category_name"
                                wrapperCol={{ span: 15 }}
                                rules={[
                                    {
                                        required:
                                            orderedCategory.length === 0
                                                ? true
                                                : false,
                                        message:
                                            'Please select at least one Category',
                                    },
                                ]}
                                className="form-item"
                                labelCol={{ span: 24 }}
                            >
                                <Select
                                    mode="multiple"
                                    style={{ width: 160 }}
                                    optionFilterProp="label"
                                    placeholder="Select categories"
                                    className="form-item"
                                    onChange={(selectedValues) => {
                                        const newOrderedCategories =
                                            selectedValues.map((value) => {
                                                const category =
                                                    categoryOptions.find(
                                                        (category) =>
                                                            category?.value ===
                                                            value
                                                    )
                                                return {
                                                    label: category?.label,
                                                    value: category?.value,
                                                }
                                            })
                                        form.setFieldValue('category_name', [])
                                        setCategoryOptions((pre) =>
                                            pre?.filter(
                                                (e) =>
                                                    e?.value !==
                                                    newOrderedCategories[0]
                                                        ?.value
                                            )
                                        )
                                        setOrderedCategory((prev) => [
                                            ...prev,
                                            ...newOrderedCategories,
                                        ])
                                    }}
                                    options={categoryOptions}
                                />
                            </Form.Item>
                            {orderedCategory?.length !== 0 && (
                                <Form.Item
                                    label={
                                        <span
                                            style={{
                                                fontWeight: 550,
                                                color: 'gray',
                                            }}
                                        >
                                            Drag to change the order
                                        </span>
                                    }
                                    name="categorylist"
                                    wrapperCol={{ span: 20 }}
                                    style={{ margin: '0 0 0 10%' }}
                                    className="form-item"
                                    labelCol={{ span: 24 }}
                                >
                                    <DraggableList
                                        orderedCategory={orderedCategory}
                                        setOrderedCategory={setOrderedCategory}
                                        removeCategory={removeCategory}
                                    />
                                </Form.Item>
                            )}
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                className="save-button"
                                htmlType="submit"
                            >
                                {isEdit ? 'Update' : 'Save'}
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
    return (
        <div className="playlist-container">
            <h2>Manage Playlist</h2>
            <div className="playlist-dropdowns">
                <div className="playlist-controls">
                    <Select
                        value={selectedLanguage}
                        onChange={handleLanguageChange}
                        placeholder="Select Language"
                        style={{ width: 200 }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        value={
                            selectedShowInApp === true
                                ? 'Yes'
                                : selectedShowInApp === false
                                ? 'No'
                                : selectedShowInApp === undefined
                                ? 'All'
                                : undefined
                        }
                        onChange={handleShowInAppChange}
                        className="playlist-dropdown"
                        placeholder="Visibility in App"
                        style={{ width: 200 }}
                    >
                        <Option value="All">All</Option>
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                    </Select>
                </div>
                <div>{modal}</div>
            </div>
            <div>
                <CommonTable columns={columns} data={filteredData} />
            </div>
        </div>
    )
}
export default Playlist
