import { useEffect, useState } from 'react'
import { Button, Select, Spin, message } from 'antd'
import { UseSelector, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import keyword from './KeyWordSearchReport.Constants'
import './keyWordSearch.scss'
import ManageContentDateRange from '../ManageContent/ManageContentDateRange'
import CommonTable from '../CommonTable/CommonTable'
import { getKeyWordSearch } from '../../AadhanApi'

const KeyWordSearchReport = () => {
    const languageData = useSelector((state) => state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [dataLoading, setDataLoading] = useState(false)
    const [messageApi, contextHolder] = message.useMessage()
    const [keywordSearchData, setKeyWordSearchData] = useState([])

    const [keywordSearchDataNull, setKeyWordSearchDataNull] = useState([])
    const [languageOption, setLanguageOption] = useState(
        languageData?.[1]?.['language_id']
    )

    // const [dates, setDates] = useState({
    //     startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //     endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    // })
    const KeyWordSearchReportColumns = [
        {
            title: 'Keyword',
            dataIndex: 'text',
            key: 'Text',
        },
        {
            title: 'Searches',
            dataIndex: 'searches',
            key: 'searches',
        },
        {
            title: 'Results',
            dataIndex: 'total_length',
            key: 'Total_length',
        },
    ]
    const KeyWordSearchReportColumnsWithNull = [
        {
            title: 'Keyword',
            dataIndex: 'text',
            key: 'Text',
        },
        {
            title: 'Searches',
            dataIndex: 'searches',
            key: 'searches',
        }
    ]
    
    // const addDates = (startDate, endDate) => {
    //     let updatedDates = { startDate: startDate, endDate: endDate }
    //     setDates(updatedDates)
    // }

    // const clearDates = () => {
    //     const updatedDates = {
    //         startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //         endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    //     }
    //     setDates(updatedDates)
    // }
    const getSearchData = async (languageOption) => {
        try {
            setDataLoading(true)
            const response = await getKeyWordSearch(accessToken, languageOption)
            const keywordData = response.data[0]
            const keywordsData = response.data[1]
            setKeyWordSearchData(keywordData)
            setKeyWordSearchDataNull(keywordsData)
            setDataLoading(false)
        } catch (err) {
            setKeyWordSearchData([])
            setKeyWordSearchDataNull([])
            setDataLoading(false)
            messageApi.open({
                type: 'error',
                content: 'Failed to get reports',
                duration: 5,
            })
        }
    }
    const { Option } = Select
    useEffect(() => {
        if (languageOption) {
            getSearchData(languageOption)
        }
    }, [languageOption])

    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Search Keywords Report - Analyzing 30 Days of Data</h2>
                <div className="date-picker-container"></div>
                {/* <span style={{ marginRight: '5px' }}>Date Range :</span> */}
                {/* <ManageContentDateRange
                    dates={dates}
                    addDates={addDates}
                    clearDates={clearDates}
                /> */}
            </div>
            <div className="manage-content-inputs">
                <Select
                    value={languageOption}
                    style={{ width: 200 }}
                    onChange={(val) => setLanguageOption(val)}
                >
                    {languageData.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}
                </Select>
            </div>
            <Spin spinning={dataLoading}>
                <div className="keyword-table">
                    <div style={{ width: '47%' }}>
                        <h4>Top Searches</h4>
                        <CommonTable
                            columns={KeyWordSearchReportColumns}
                            data={keywordSearchData}
                            dataLoading={false}
                            disablePagination={false}
                        />
                    </div>
                    <div style={{ width: '47%' }}>
                        <h4>Searches with zero result</h4>
                        <CommonTable
                            columns={KeyWordSearchReportColumnsWithNull}
                            data={keywordSearchDataNull}
                            dataLoading={false}
                            disablePagination={false}
                        />
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default KeyWordSearchReport
