import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    message,
    Radio,
    Row,
    Select,
    Tabs,
} from 'antd'
import { useSelector } from 'react-redux'
import {
    getReporterDetails,
    updateReporter,
    getDistricts,
    getMandals,
    getAssemblyConstituenciesReporter,
    getElectionConstituencies,
} from '../../AadhanApi'
import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import './Reporter.scss'
import { Option } from 'antd/es/mentions'
const EditEmployee = () => {
    const paymentTermOptions = [
        {
            value: 'monthly',
            label: 'Monthly',
        },
        {
            value: 'weekly',
            label: 'Weekly',
        },
        {
            value: 'yearly',
            label: 'Yearly',
        },
    ]
    const [form] = Form.useForm()
    const [selectedEmployee, setSelectedEmployee] = useState()
    const navigate = useNavigate()
    const [obj, setObj] = useState({
        state_id: '',
        state_name: '',
        district_id: '',
        district_name: '',
        mandal_id: [],
        mandal_name: [],
        constituency_id: [],
        constituency_name: [],
        mobile: '',
    })
    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('reporterId')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const locationsData = useSelector((state)=>state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allMandals = useSelector((state) => state.app.mandals)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [
        selectedPrimaryConstituencyName,
        setSelectedPrimaryConstituencyName,
    ] = useState(null)
    const [districtsData, setDistrictsData] = useState([])
    const [electionAssemblyData, setElectionAssemblyData] = useState([])
    const [mandalsData, setMandalsData] = useState([])
    const [assemblyData, setAssemblyData] = useState([])
    const [data,setData] = useState({})
    const [comp,setComp] = useState(false)
    const onFinish = async (values) => {
        let districIDs = []
        if (values?.district) {
            for (let i = 0; i < values?.district.length; i++) {
                for (let j = 0; j < districtsData.length; j++) {
                    if (districtsData[j].name === values?.district[i]) {
                        districIDs.push(districtsData[j].district_id)
                    }
                }
            }
        }
        let mandalIDs = []
        if (values?.mandal) {
            for (let i = 0; i < values?.mandal.length; i++) {
                for (let j = 0; j < mandalsData.length; j++) {
                    if (mandalsData[j].name === values?.mandal[i]) {
                        mandalIDs.push(mandalsData[j].mandal_id)
                    }
                }
            }
        }

        let req = {
            full_name: values?.fullName,
            languages: values.languages ? values.languages : [],
            stringer_type:values?.type,
            reporter_information: {
                compensation:location.pathname.includes('tv-stringers') ? true : values.compensation === 'active' ? true : false,
                per_image_article_price:location.pathname.includes('tv-stringers') ? 0: values.costPerImageArticle,
                per_video_article_price:location.pathname.includes('tv-stringers') ? 0 : values.costPerVideoArticle,
                payment_term: values.paymentTerm,
                pen_name: values.penName,
                state_id: obj?.state_id || '',
                state: obj?.state_name || '',
                district_id:obj?.district_id|| '',
                district:obj?.district_name || '',
                mandal_id:obj?.mandal_id?.join(','),
                mandal:obj?.mandal_name?.join(','),
                constituencies: obj?.constituency_name || [''],
                constituency_ids: obj?.constituency_id || [''],
                primary_constituency_info:data?.primary_constituency_info,
                payment_info: {
                    account_number: values.accountNumber,
                    ifsc_code: values.ifscCode,
                    account_holder_name: values.accountHolderName,
                    gst_number: values.gstNumber,
                    pan_number: values.panNumber,
                },
                pincode:values?.pincode
            },
        }
        if(location.pathname.includes('tv-stringers')){
            req.reporter_information['ad_revenue_per_year'] = values?.adv_revenue_per_year;
            req.reporter_information['adv_percentage'] = values?.adv_percentage;
            
        }
        // if (selectedPrimaryConstituencyName) {
        //     obj.reporter_information.primary_constituency_info = {
        //         constituency_id: values.primaryAssemblyConstituency ,
        //         constituency_name: selectedPrimaryConstituencyName ,
        //         mapping_id: values.electionAssemblyConstituency,
        //         mapping_state_id: selectedMappingStateId===null ? 0 :selectedMappingStateId ,
        //     }
        // }
        const stringerResponse = await updateReporter(
            req,
            contentId,
            accessToken
        )
        if (stringerResponse.status === 200) {
            if(location.pathname.includes('tv-stringers')){
                navigate('/home/manage-tv-stringers')
                message.success('Stringers Data Updated Successfully')
            }
            else if(location.pathname.includes('app-stringers')){
                navigate('/home/manage-app-stringers')
                message.success('Stringers Data Updated Successfully')
            }
            else{
                navigate('/home/stringers')
            }
            form.resetFields()
        }
    }
    const updateFormData = async (contentId, accessToken) => {
        const response = await getReporterDetails(contentId, accessToken)
        setSelectedEmployee(response?.data)
        if (response?.data) {
            setData(response?.data)
            if (response?.data?.district_id !== '') {
                // setSelectDistric_Id([response?.data?.district_id])
            }
            const record = response?.data
            setObj({
                state_id: record?.reporter_information?.state_id,
                state_name: record?.reporter_information?.state,
                district_id:
                    record?.reporter_information?.district_id?.split(',')[0],
                district_name:
                    record?.reporter_information?.district?.split(',')[0],
                constituency_id:
                    record?.reporter_information?.constituency_ids?.[0] === ''
                        ? []
                        : record?.reporter_information?.constituency_ids,
                constituency_name:
                    record?.reporter_information?.constituencies?.[0] === ''
                        ? []
                        : record?.reporter_information?.constituencies,
                mandal_id :record?.reporter_information?.mandal_id!=='' ? record?.reporter_information?.mandal_id?.split(',') : [],
                mandal_name :record?.reporter_information?.mandal!=='' ? record?.reporter_information?.mandal?.split(',') : [],
            })
            setComp(response.data?.reporter_information?.compensation
                ? true
                : false)
            form.setFieldsValue({
                fullName: response.data?.full_name,
                email: response.data?.email_id,
                type:location.pathname.includes("manage-tv-stringers") ? 2 :location.pathname.includes("manage-app-stringers") ? 1 : response.data?.stringer_type,
                phoneNumber: response.data?.mobile_number,
                penName: response.data?.reporter_information?.pen_name,
                languages: response.data?.languages,
                adv_revenue_per_year:response.data?.reporter_information?.ad_revenue_per_year,
                adv_percentage:response.data?.reporter_information?.adv_percentage,
                pincode:response.data?.reporter_information?.pincode,
                assemblyConstituency:
                    response.data?.reporter_information?.constituency_ids,
                compensation: response.data?.reporter_information?.compensation
                    ? 'active'
                    : 'inactive',
                paymentTerm: response.data?.reporter_information?.payment_term,
                accountNumber:
                    response.data?.reporter_information?.payment_info
                        ?.account_number,
                ifscCode:
                    response.data?.reporter_information?.payment_info
                        ?.ifsc_code,
                accountHolderName:
                    response.data?.reporter_information?.payment_info
                        ?.account_holder_name,
                panNumber:
                    response.data?.reporter_information?.payment_info
                        ?.pan_number,
                gstNumber:
                    response.data?.reporter_information?.payment_info
                        ?.gst_number,
                state: response.data?.reporter_information?.state_id,
                primaryAssemblyConstituency:
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.constituency_id,
                electionAssemblyConstituency:
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.mapping_id,
                district:
                    response.data?.reporter_information?.district?.split(',') || [],
                mandal: response.data?.reporter_information?.mandal_id?.split(',') || [],
                costPerImageArticle:
                    response.data?.reporter_information
                        ?.per_image_article_price,
                costPerVideoArticle:
                    response.data?.reporter_information
                        ?.per_video_article_price,
            })
            if (response.data?.reporter_information?.state) {
                // getDistrictsList(response.data?.reporter_information?.state_id)
                // getAssembly(response.data?.reporter_information?.state_id)
               
            }
            if (response.data?.reporter_information?.district) {
                // getMandalsList(response.data?.reporter_information?.district_id)

              
            }
            if (response.data?.reporter_information?.mandal) {
                // setSelectedMandalNames(
                //     response.data?.reporter_information?.mandal
                // )
            }
            if (response.data?.reporter_information?.constituencies) {
               
            }
            if (
                response?.data?.reporter_information?.primary_constituency_info
                    ?.constituency_name
            ) {
                setSelectedPrimaryConstituencyName(
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.constituency_name
                )
            }
            if (
                response?.data?.reporter_information?.primary_constituency_info
                    ?.mapping_id
            ) {
               
            }
            if (
                response?.data?.reporter_information?.primary_constituency_info
                    ?.mapping_state_id
            ) {
               
            }
        }
    }
    useEffect(() => {
        if (contentId) {
           
            getElectionAssemblyConstituencies()
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

   
    const getElectionAssemblyConstituencies = async () => {
        const response = await getElectionConstituencies(
            'assembly_elections',
            1,
            accessToken
        )
        setElectionAssemblyData(response?.data?.data)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const onStateChange = (value,option) => {
        // getDistrictsList(id)
        // getAssembly(id)
        // const selectedNames = locationsData
        //     .filter((option) => id.includes(option.state_id))
        //     .map((selectedOption) => selectedOption.name)
        // setSelectedStateNames(selectedNames?.[0])
        setObj((prev) => ({
            ...prev,
            state_id: value,
            state_name: option?.children,
            district_id: undefined,
            district_name: undefined,
            constituency_id: [],
            constituency_name: [],
            mandal_id:[],
            mandal_name:[]
        }))
        form.setFieldValue('assemblyConstituency',undefined)
        
    }

    const onDistrictChange = (selectedDistrictIds) => {
        // getMandalsList(selectedDistrictIds)
        let temp = []
        districtsData.map((e) => {
            if (selectedDistrictIds.includes(e.name)) {
                temp.push(e.district_id)
            }
        })
      
        const selectedNames = districtsData
            .filter((option) => temp.includes(option.district_id))
            .map((option) => option.name)
            .join(',')
        const selectedIds = districtsData
            .filter((option) => temp.includes(option.district_id))
            .map((option) => option.district_id)
            .join(',')

        getMandalsList(selectedIds)
    }

    const getMandalsList = async (id) => {
        const mandalPromises = Array.isArray(id)
            ? id
            : id?.split(',')?.map(async (i) => {
                  const reponse = await getMandals(i, accessToken)
                  return await reponse?.data
              })
        const mandalDataArray = await Promise.all(mandalPromises)
        const totalMandalData = mandalDataArray.reduce((acc, curr) => {
            return [...acc, ...curr]
        })
        setMandalsData(totalMandalData)
    }
    const onMandalChange = (option,value) => {
        setObj((prev) => ({
            ...prev,
            mandal_id:[option?.value],
            mandal_name:[option?.children],
        }))
    }

    const onConstituencyChange = (value,option) => {
        // const selectedNames = assemblyData
        //     .filter((option) => id.includes(option.assembly_constituency_id))
        //     .map((selectedOption) => selectedOption.name)
        // setSelectedConstituencyNames(selectedNames)
        setObj((prev) => ({
            ...prev,
            constituency_id: [value],
            district_id:option['data-additional-prop'],
            district_name:option ? allDistricts[obj.state_id].filter(
                          (e) =>
                              e.district_id ===
                              option['data-additional-prop']
                              
                      )?.[0]?.name
                    : '',

            constituency_name:[option?.children],
            mandal_id:[],
            mandal_name:[],
        }))
        form.setFieldValue('district',option['data-additional-prop'])
    }
    const onPrimaryConstituencyChange = (id) => {
        if (!Array.isArray(id)) {
            const selectedPrimaryNames = assemblyData
                .filter((option) =>
                    id.includes(option.assembly_constituency_id)
                )
                .map((selectedOption) => selectedOption.name)
            setSelectedPrimaryConstituencyName(selectedPrimaryNames?.[0])
        } else {
            setSelectedPrimaryConstituencyName(null)
        }
    }
   
    return (
        <div className="edit-reporter">
            <h3 className="heading">{location.pathname.includes('tv-stringers') ? 'Edit TV Stringer' :location.pathname.includes('app-stringers') ?"Edit App Stringer" : 'Edit Stringer'}</h3>
            <div style={{padding:"0 1rem"}}>
                <Form
                    id="form"
                    form={form}
                    name="employeeForm"
                    scrollToFirstError
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{
                        compensation: 'active',
                        fullName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        role: '',
                        languages: '',
                        password: '',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row
                        style={{
                            marginBottom: '17px',
                            marginTop: '10px',
                        }}
                    >
                        <Col span={12}>
                            <Form.Item
                                label="Full Name"
                                name="fullName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                validateTrigger="onBlur"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item
                                label="Nick Name"
                                name="penName"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Language(s)" name="languages">
                                <Select mode="multiple">
                                    {languageData.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.language_id}
                                        >
                                            {option.language_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item label="Phone Number" name="phoneNumber">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name='type' label="Stringer Type" rules={[{required:comp ? true : false,message:"please select type"}]}>
                            <Select     disabled={location.pathname.includes('manage-tv-stringers') || location.pathname.includes('manage-app-stringers')  ? true : false}
                                        placeholder="Select Type"
                                    >
                                        {[{value:1,label:"App Stringer"},{value:2,label:"TV Stringer"}]?.map((option) => (
                                            <Select.Option
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                    <Col span={12}>
                            <Form.Item required name="state" label="State">
                                {locationsData && (
                                    <Select
                                        placeholder="Select State"
                                        onChange={(value,option)=>onStateChange(value,option)}
                                    >
                                        {locationsData?.map((option) => (
                                            <Select.Option
                                                key={option?.state_id}
                                                value={option?.state_id}
                                            >
                                                {option?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                            value={obj?.constituency_id?.[0]}
                            rules={[{required:comp ? true : false ,message:"Please Select Constituency"}]}
                                label={
                                    <div>
                                        Assembly
                                        <br />
                                        Constituency
                                    </div>
                                }
                                name="assemblyConstituency"
                            >
                                {assemblyData && (
                                    <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                        placeholder="Select Assembly"
                                        onChange={(value,option)=>onConstituencyChange(value,option)}
                                    >
                                        {allConstituencies?.[obj?.state_id]?.map((option) => (
                                            <Option
                                                key={
                                                    option?.assembly_constituency_id
                                                }
                                                value={
                                                    option?.assembly_constituency_id
                                                }
                                                data-additional-prop={option?.district_id}
                                                
                                            >
                                                {option?.name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                    <Col span={12}>
                            <Form.Item label="District" name="district">
                                <Select
                                    disabled
                                        onChange={onDistrictChange}
                                    >
                                        {allDistricts?.[obj?.state_id]?.map((option) => (
                                            <Select.Option
                                                key={option?.district_id}
                                                value={option?.district_id}
                                            >
                                                {option?.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
    
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item label="Mandal" name="mandal">
                                    <Select
                                        placeholder="Select Mandal"
                                        showSearch
                                        optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option?.children
                                            ?.toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                        onChange={(value,option)=>onMandalChange(option,value)}
                                    >
                                        {allMandals?.[obj?.district_id]?.map((option) => (
                                            <Select.Option
                                                key={option.mandal_id}
                                                value={option.mandal_id}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                        <Form.Item label="Pin Code" name="pincode">
                            <Input></Input>
                        </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                    <Col span={12}>
                           {!location.pathname.includes("tv-stringers") && 
                            <Form.Item
                            label={<div>Compensation</div>}
                            name="compensation"
                        >
                            <Radio.Group onChange={(e)=>setComp(e.target.value==='active' ? true : false)}>
                                <Radio value="active"> Yes </Radio>
                                <Radio value="inactive"> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                           }
                        </Col>
                    </Row>
                    {comp && !location.pathname.includes('tv-stringer') && 
                    <Row
                        style={{
                            marginBottom: '17px',
                            marginTop: '10px',
                        }}
                    >
                        <Col span={12}>
                           
                            <Form.Item
                            label={
                                <div>
                                    Cost per image
                                    <br />
                                    article
                                </div>
                            }
                            name="costPerImageArticle"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter value',
                                },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                           
                        </Col>
                        <Col span={12}>
                             
                            <Form.Item
                            label={
                                <div>
                                    Cost per video
                                    <br />
                                    article
                                </div>
                            }
                            name="costPerVideoArticle"
                            validateTrigger="onBlur"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please enter value',
                                },
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                            
                        </Col>
                    </Row>}
                    {location.pathname.includes('tv-stringers') && 
                    <Row>
                        <Col span={12}>
                        <Form.Item label={<div>Adv Revenue <br></br> Per Year</div>} name="adv_revenue_per_year">
                           <Input type='number' defaultValue={0}></Input>
                        </Form.Item>
                        </Col>
                        <Col span={12}>
                        <Form.Item label="Adv Percentage" name="adv_percentage">
                           <Input type='number' defaultValue={0}></Input>
                        </Form.Item>
                        </Col>
                    </Row>
                    }
                    {comp && <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Account
                                        <br />
                                        Number
                                    </div>
                                }
                                name="accountNumber"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="IFSC Code" name="ifscCode">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>}
                    {comp && 
                    <Row style={{ marginBottom: '17px' }}>
                    <Col span={12}>
                        <Form.Item
                            label={
                                <div>
                                    Account Holder
                                    <br />
                                    Name
                                </div>
                            }
                            name="accountHolderName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="GST Number" name="gstNumber">
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                    }
                    {comp && <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item label="Payment Term" name="paymentTerm">
                                <Select>
                                    {paymentTermOptions.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Pan Number" name="panNumber">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>}
                    <Form.Item
                        wrapperCol={{
                            span: 24,
                            offset: 0,
                            style: { textAlign: 'center', marginTop: '16px' },
                        }}
                    >
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default EditEmployee
