import React from 'react'
import { Route, Routes } from 'react-router'
import AddAd from '../AddAd/AddAd'
import ManageAd from '../AddAd/ManageAd'
import AddCard from '../AddCard/AddCard'
import AddPoll from '../AddPoll/AddPoll'
import AddQuiz from '../AddQuiz/AddQuiz'
import AddShortNews from '../AddShortNews/AddShortNews'
import AddShortVideo from '../AddShortVideo/AddShortVideo'
import AddDevotionalVideo from '../AddDevotionalVideo/AddDevotionalVideo'
import CategoryTimeSlotReport from '../CategoryTimeSlotReport/CategoryTimeSlotReport'
import DailyContentReport from '../DailyContentReport/DailyContentReport'
import ContentEditsReport from '../ContentEditsReport/ContentEditsReport'
import Dashboard from '../Dashboard/Dashboard'
import AddEmployee from '../Employee/AddEmployee/AddEmployee'
import Home from '../Home/Home'
import Login from '../Login/Login'
import Notifications from '../Notification/Notifications'
import NotificationsNew from '../NotificationNew/NotificationsNew'
import NotificationReport from '../NotificationReport/NotificationReport'
import ManagePermissions from '../Permissions/ManagePermissions'
import ManageRoles from '../Permissions/ManageRoles'
import SourceReport from '../SourceReport/SourceReport'
import TimeSlotReport from '../TimeSlotReport/TimeSlotReport'
import TopicsReport from '../TopicsReport/TopicsReport'
import Forgotpassword from '../Login/ForgotPassword'
import AddHtmlContent from '../AddHtmlContent/AddHtmlContent'
import EditEmployee from '../Employee/EditEmployee'
import EditReporter from '../Reporter/EditReporter'
import EmployeeReport from '../EmployeeReport/EmployeeReport'
import ContentReportByLanguage from '../ContentReportByLanguage/ContentReportByLanguage'
import ProofReadersReport from '../ProofReadersReport/ProofReaderReport'
import UgContentProofReadersReport from '../UgContentProofReadersReport/UgContentProofReadersReport'
import StateContentReport from '../StateContentReport/StateContentReport'
import Locations from '../Locations/Locations'
import ReviewContent from '../ReviewContent/ReviewContent'
import UgcReport from '../UgReport/UgcReport'
import DistrictsReport from '../DistrictsReport/DistrictsReport'
import EmployeeShifts from '../EmployeeShifts/EmployeeShifts'
import VideoReport from '../VideoReport/VideoReport'
import Master from '../Master/Master'
import DataEntry from '../DataEntry/DataEntry'
import AssemblyConstituency from '../AssemblyConstituency/AssemblyConstituency'
import AssemblyConstituencyDataEntry from '../AssemblyConstituencyDataEntry/AssemblyConstituencyDataEntry'
import Billing from '../Billing/Billing'
import ReporterPaymentReport from '../reporterPaymentReport/ReporterPaymentReport'
import KeyWordSearchReport from '../KeyWordSearchReport/KeyWordSearchReport'
import UgContentRepor from '../UGContentReport/UgContentRepor'
import { useDispatch, useSelector } from 'react-redux'
import { getEmployeeDetails } from '../../AadhanApi'
import { addUserLocation } from '../../store/slice/AppSlice'
import Test from '../Test'
import DashBoardReviewContent from '../DashboardReviewContent/DashboardReviewContent'

import NotificationsNewDashboard from '../NotificationNew/NotificationsNewDashboard/NotificationsNewDashboard'
import AssignedStories from '../AssignedStories/AssignedStories'
import ListOfStories from '../Stories/ListOfStories/ListOfStories'
import CentralTeamOps from '../Stories/CentarlTeamOps/CentralTeamOps'
import MediaTeam from '../Stories/MediaTeam/MediaTeam'
import Repoter from '../Stories/Repoter/Repoter'
import Stages from '../Stories/Stages/Stages'
import ManagePlaylist from '../Library/ManagePlaylist'
import ManageCategory from '../Library/ManageCategory'
import ManageVisuals from '../Library/ManageVisuals'
import GridHoroPage from '../Daily Horoscope/GridHoroPage'
import EditorDashboard from '../DashboardReviewContent/EditorDashboard'
import EditStringers from '../Reporter/EditStringers'
import VideoLibrary from '../VideoLibrary/VideoLibrary'
import HoroscopeMain from '../Daily Horoscope/HoroscopeMain'
import EditTVStringers from '../Reporter/EditTVStringers'

const AppRoute = () => {
    const dispatch = useDispatch()
    const token = localStorage.getItem('ACCESS_TOKEN')
    const employeeId = localStorage.getItem('EMPLOYEE_ID')
    let options = {
        language: useSelector((state) => state.app.languageData)?.[0]
            ?.language_id,
        state: 'All',
        district: '',
        ugType: true,
    }

    async function getlocations() {
        const data = await getEmployeeDetails(employeeId, token)

        const res = data?.data?.[0]
        // Convert the object to a JSON string
        options.state =
            res?.state_id?.split(',')?.length !== 0 &&
            res?.state_id !== '' &&
            res?.state_id?.split(',') !== undefined &&
            res.state_id !== null
                ? res?.state_id?.split(',')?.[0]
                : 'All'
        options.district =
            res?.district_id?.split(',')?.length !== 0 &&
            res?.district_id !== '' &&
            res?.district_id?.split(',') !== undefined &&
            res?.district_id !== null
                ? res?.district_id?.split(',')?.[0]
                : ''
        const optionsString = JSON.stringify(options)

        // Store the JSON string in localStorage with the key 'options'
        localStorage.setItem('options', optionsString)
        const location = {
            state: res?.state_id?.split(','),
            district: res?.district_id?.split(','),
        }
        dispatch(addUserLocation(location))
        const resp = await getEmployeeDetails(employeeId, token)
        localStorage.setItem(
            'is_Available',
            resp?.data?.[0]?.is_availdable === true ? true : ''
        )
    }
    getlocations()
    
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<Forgotpassword />} />
            <Route path="/home" element={<Home />}>
                <Route
                    path="/home/assignRolesAndPermissions"
                    element={<ManagePermissions />}
                />
                <Route path="/home/" element={<Dashboard />} />
                {/* <Route path="/home/ads" element={<Dashboard />} /> */}
                <Route path="/home/ads" element={<ManageAd />} />
                <Route path="/home/adsContent" element={<AddAd />} />
                <Route path="/home/manageRoles" element={<ManageRoles />} />
                <Route path="/home/shortNews" element={<Dashboard />} />
                <Route
                    path="/home/shortNewsContent"
                    element={<AddShortNews />}
                />
                <Route path="/home/shortVideo" element={<Dashboard />} />
                <Route path="/home/horoscope" element={<Dashboard />} />
                <Route path="/home/panchangam" element={<Dashboard />} />

                <Route
                    path="/home/shortVideoContent"
                    element={<AddShortVideo />}
                />
                <Route path="/home/devotionalVideo" element={<Dashboard />} />
                <Route
                    path="/home/devotionalVideoContent"
                    element={<AddDevotionalVideo />}
                />
                <Route path="/home/card" element={<Dashboard />} />
                <Route path="/home/locations" element={<Locations />} />
                <Route path="/home/cardContent" element={<AddCard />} />
                <Route path="/home/poll" element={<Dashboard />} />
                <Route path="/home/pollContent" element={<AddPoll />} />
                <Route path="/home/quiz" element={<Dashboard />} />
                <Route path="/home/quizContent" element={<AddQuiz />} />
                <Route path="/home/videos" element={<VideoLibrary />} />
                <Route
                    path="/home/notifications"
                    element={<NotificationsNew />}
                />
                <Route
                    path="/home/notificationsNew"
                    element={<NotificationsNew />}
                />
                <Route
                    path="/home/ug"
                    element={<Dashboard showAddButton={false} />}
                />
                <Route path="/home/ugContent" element={<ReviewContent />} />
                <Route path="/home/reviewContent" element={<ReviewContent />} />
                <Route path="/home/languages" element={<Dashboard />} />
                <Route path="/home/hashtags" element={<Dashboard />} />
                <Route path="/home/sources" element={<Dashboard />} />
                <Route path="/home/categories" element={<Dashboard />} />
                <Route path="/home/subcategories" element={<Dashboard />} />
                <Route path="/home/employees" element={<Dashboard />} />
                <Route
                    path="/home/stringers"
                    element={<Dashboard showAddButton={false} />}
                />
                <Route path='/home/manage-tv-stringers' element={<EditTVStringers/>} />
                <Route
                    path="/home/manage-app-stringers"
                    element={<EditStringers />}
                />

                <Route path="/home/createEmployee" element={<AddEmployee />} />
                <Route
                    path="/home/employeesContent"
                    element={<EditEmployee />}
                />
                <Route
                    path="/home/stringersContent"
                    element={<EditReporter />}
                />
                <Route path='/home/manage-tv-stringers/edit' element={<EditReporter/>}/>
                <Route path='/home/manage-app-stringers/edit' element={<EditReporter/>}/>
                <Route
                    path="/home/feedback"
                    element={<Dashboard showAddButton={false} />}
                />
                <Route
                    path="/home/categoryTimeSlotReport"
                    element={<CategoryTimeSlotReport />}
                />
                <Route
                    path="/home/reporterPaymentReport"
                    element={<ReporterPaymentReport />}
                />
                <Route path="ugContentReport" element={<UgContentRepor />} />
                <Route
                    path="/home/timeSlotReport"
                    element={<TimeSlotReport />}
                />
                <Route path="/home/topicsReport" element={<TopicsReport />} />
                <Route
                    path="/home/dailyContentReport"
                    element={<DailyContentReport />}
                />
                <Route
                    path="/home/notificationReport"
                    element={<NotificationReport />}
                />
                <Route path="/home/sourceReport" element={<SourceReport />} />
                <Route path="/home/features" element={<Dashboard />} />
                <Route path="/home/html" element={<Dashboard />} />
                <Route path="/home/htmlContent" element={<AddHtmlContent />} />
                <Route
                    path="/home/employeeReport"
                    element={<EmployeeReport />}
                />
                <Route path="/home/ugcReport" element={<UgcReport />} />
                <Route
                    path="/home/proofReadersReport"
                    element={<ProofReadersReport />}
                />
                <Route
                    path="/home/ugContentProofReadersReporter"
                    element={<UgContentProofReadersReport />}
                />
                <Route
                    path="/home/statesContentReport"
                    element={<StateContentReport />}
                />
                <Route
                    path="/home/searchByKeywordsReport"
                    element={<KeyWordSearchReport />}
                ></Route>
                <Route
                    path="/home/contentReportByLanguage"
                    element={<ContentReportByLanguage />}
                />
                <Route
                    path="/home/contentEditsReport"
                    element={<ContentEditsReport />}
                />
                <Route path="/home/videoReport" element={<VideoReport />} />
                <Route
                    path="/home/districtsReport"
                    element={<DistrictsReport />}
                />
                <Route
                    path="/home/employeeShifts"
                    element={<EmployeeShifts />}
                />
                <Route path="/home/electionMaster" element={<Master />} />
                <Route path="/home/createElection" element={<Master />} />
                <Route path="/home/electionResults" element={<DataEntry />} />
                <Route
                    path="/home/constituencyMaster"
                    element={<AssemblyConstituency />}
                />
                <Route
                    path="/home/constituencyResults"
                    element={<AssemblyConstituencyDataEntry />}
                />
                <Route path="/home/billing" element={<Billing />} />
                <Route path="/home/test" element={<Test />}></Route>

                <Route
                    path="/home/review"
                    element={<DashBoardReviewContent />}
                ></Route>

                <Route
                    path="/home/notificationsNewDashboard"
                    element={<NotificationsNewDashboard />}
                />
                <Route
                    path="/home/AssignedStories"
                    element={<AssignedStories />}
                />
                {/* stories */}
                <Route
                    path="/home/list-of-stories"
                    element={<ListOfStories />}
                />
                <Route
                    path="/home/central-team-ops"
                    element={<CentralTeamOps />}
                />
                <Route path="/home/media-team" element={<MediaTeam />} />
                <Route path="/home/reporter" element={<Repoter />} />
                <Route path="/home/stages" element={<Stages />} />
                <Route path="/home/playlist" element={<ManagePlaylist />} />
                <Route path="/home/category" element={<ManageCategory />} />
                <Route path="/home/visuals" element={<ManageVisuals />} />
                <Route
                    path="/home/visuals/:id/:langId"
                    element={<ManageVisuals />}
                />
                <Route
                    path="/home/daily-horoscope"
                    element={<HoroscopeMain />}
                />

                <Route
                    path="/home/daily-horoscopegrid"
                    element={<GridHoroPage />}
                />
                <Route
                    path="/home/editorDashboard"
                    element={<EditorDashboard />}
                />
            </Route>
        </Routes>
    )
}
export default AppRoute
