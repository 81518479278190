import React from 'react';
import { Tabs } from 'antd';
import EmployeeTable from './EmployeeTable';
import { useSelector } from 'react-redux';
import RuleForDay from './RuleForDay';
const { TabPane } = Tabs;
const EditorDashboard = () => {
    const location = useSelector((state) => state.app.locations)
    const language = useSelector((state)=>state.app.languageData)
    const accessToken = useSelector((state) => state.app.accessToken)
  return (
    <Tabs defaultActiveKey="1" style={{height:"3rem",padding:"0 1px",margin:"0 1rem 0 1rem"}}>
      <TabPane tab={<span style={{color:"gray",fontWeight:"bold"}}>Employee</span>} key="1" >
        <EmployeeTable token={accessToken} language={language} locations={location}  />
      </TabPane>
      <TabPane tab={<span style={{fontWeight:"bold",color:"gray"}}>Day Rules</span>} key="3">
       <RuleForDay/>
      </TabPane>
    </Tabs>  
  );
};

export default EditorDashboard;
