import React, { useEffect, useState } from 'react'
import './dashboard.scss'
import { Button, message, notification, Popover, Switch, Table } from 'antd'
import {
    BellOutlined,
    ExclamationCircleOutlined,
    RedoOutlined,
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import {
    getEmployeeDetails,
    getUGContent,
    isAvailableApi,
} from '../../AadhanApi'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import breaking from '../../assets/hot-news.png'
import Uploadedicon from '../../assets/uploaded_icon.svg'
const NewDashBoard = () => {
    const navigate = useNavigate()
    const [todayData, setTodayData] = useState([])
    const [yesterData, setYesterData] = useState([])
    const [oldData, setOldData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    let optionsString = localStorage.getItem('options')
    let options = JSON.parse(optionsString)
    const accessToken = useSelector((state) => state.app.accessToken)
    const location = useLocation()
    const [messageApi, contextHolder] = message.useMessage()
    const [isAvailable, setIsAvailabel] = useState(
        localStorage?.getItem('is_Available') === undefined &&
            localStorage?.getItem('is_Available') === null
            ? false
            : localStorage?.getItem('is_Available')
    )
    const [unassignedCount, setUnassignedCount] = useState([
        { title: 'Unassigned Content Count', count: 0 },
    ])
    const [showPopover, setShowPopover] = useState(false)
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    const displayAuthenticationError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: (
                <div style={{ display: 'flex', gap: '5px' }}>
                    {errMessage}
                    <Link to="/">
                        Click here to login or will be redirected to Login page
                        in 5 seconds
                    </Link>
                </div>
            ),
            duration: 10,
        })
        setTimeout(() => {
            navigate('/')
        }, 5000)
    }
    const onReviewClicked = async (record) => {
        navigate(
            `${location?.pathname}Content?mode=edit&contentId=${record.content_id}`
        )
    }
    useEffect(() => {
        if (
            localStorage?.getItem('is_Available') === '' ||
            localStorage?.getItem('is_Available') === undefined ||
            localStorage?.getItem('is_Available') === null
        ) {
            message.info(
                'Please set the availability status to "Active" before starting to review the content.'
            )
        }
    }, [])
    const getUgContentFunc = async (todaydate, yesterdaydate) => {
        try {
            const ugcDataResponse = await getUGContent(
                accessToken,
                options?.language,
                undefined,
                undefined,
                undefined
            )
            let today = []
            let yesterday = []
            let old = []
            ugcDataResponse?.data?.data?.forEach((e) => {
                if (e.assigned_at?.slice(0, 10) === todaydate) {
                    today.push(e)
                } else if (e.assigned_at?.slice(0, 10) === yesterdaydate) {
                    yesterday.push(e)
                } else {
                    old.push(e)
                }
            })
            setUnassignedCount([
                {
                    title: 'Unassigned Content Count',
                    count:
                        ugcDataResponse?.data?.unassigned_count !== undefined
                            ? ugcDataResponse?.data?.unassigned_count
                            : 0,
                },
            ])
            return { today, yesterday, old }
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get UG Content')
            }
        }
    }
    const refetchData = async () => {
        const newData = await getUgContentFunc(
            dayjs().startOf('day').format('YYYY-MM-DD'),
            dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD')
        )

        setTimeout(() => {
            setTodayData(newData?.today)
            setYesterData(newData?.yesterday)
            setOldData(newData?.old)
        }, 1000 * 60) // Delay of 1 minute before updating the displayed data
    }
    useEffect(() => {
        async function initialfetch() {
            const today = dayjs().startOf('day').format('YYYY-MM-DD')
            const yesterday = dayjs()
                .subtract(1, 'day')
                .startOf('day')
                .format('YYYY-MM-DD')
            const newData = await getUgContentFunc(today, yesterday)
            setTodayData(newData?.today)
            setYesterData(newData?.yesterday)
            setOldData(newData?.old)
        }
        if (!isAvailable) {
            initialfetch()
        }
    }, [])
    useEffect(() => {
        if (!isAvailable) return // Skip if status is false
        setDataLoading(true)
        const today = dayjs().startOf('day').format('YYYY-MM-DD')
        const yesterday = dayjs()
            .subtract(1, 'day')
            .startOf('day')
            .format('YYYY-MM-DD')
        let isMounted = true
        let interval
        const startInterval = () => {
            interval = setInterval(refetchData, 1000 * 60 * 15) // Refetch every 15 minutes
        }
        const clearExistingInterval = () => {
            if (interval) {
                clearInterval(interval)
            }
        }
        const fetchLatestData = async () => {
            const newData = await getUgContentFunc(today, yesterday)
            if (isMounted) {
                setTodayData(newData?.today)
                setYesterData(newData?.yesterday)
                setOldData(newData?.old)
            }
            const res = await getEmployeeDetails(
                localStorage.getItem('EMPLOYEE_ID'),
                accessToken
            )
            if (isMounted) {
                localStorage.setItem(
                    'is_Available',
                    res?.data?.[0]?.is_availdable === true ? true : ''
                )
                setIsAvailabel(
                    res?.data?.[0]?.is_availdable === true ? true : ''
                )
            }
        }
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                fetchLatestData() // Fetch the latest data immediately when the tab is visible
                startInterval() // Resume regular interval fetching
            } else {
                clearExistingInterval() // Pause fetching if the tab is inactive
            }
        }
        // Initial data fetch (without refetch)
        getUgContentFunc(today, yesterday).then((initialData) => {
            if (isMounted) {
                setTodayData(initialData?.today)
                setYesterData(initialData?.yesterday)
                setOldData(initialData?.old)
                setDataLoading(false)
            }
        })
        // Only start the interval after the initial fetch
        document.addEventListener('visibilitychange', handleVisibilityChange)

        return () => {
            isMounted = false
            clearExistingInterval()
            document.removeEventListener(
                'visibilitychange',
                handleVisibilityChange
            ) // Cleanup event listener on unmount
        }
    }, [isAvailable, options?.language]) // Dependency array includes 'status'

    const calculateTimeSince = (dateString) => {
        const givenDate = new Date(dateString)
        const currentDate = new Date()
        const diffInMs = currentDate - givenDate
        const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24))
        const diffInHours = Math.floor(
            (diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const diffInMinutes = Math.floor(
            (diffInMs % (1000 * 60 * 60)) / (1000 * 60)
        )
        const diffInSeconds = Math.floor((diffInMs % (1000 * 60)) / 1000)
        return {
            days: diffInDays,
            hours: diffInHours,
            minutes: diffInMinutes,
            seconds: diffInSeconds,
        }
    }
    // breaking_news
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: '40%',
            render: (_, record) => (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <p style={{ margin: '0px' }}>
                            {record?.created_type === 'reporter' && (
                                <img
                                    style={{
                                        width: '1rem',
                                        marginRight: '9px',
                                    }}
                                    alt="ViewIcon"
                                    src={Uploadedicon}
                                />
                            )}
                            {record?.breaking_news === true && (
                                <span className="flashing-icon">
                                    <img
                                        src={breaking}
                                        style={{ width: '8%' }}
                                    />
                                </span>
                            )}
                            {record?.title}
                        </p>
                    </div>
                </div>
            ),
        },
        {
            title: 'Submitted Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            render: (_, record) => (
                <div>
                    <div>
                        {dayjs(record?.uploaded_date).format('DD MMM YYYY')}
                    </div>
                    {dayjs(record?.uploaded_date).format('hh:mm:ss A')}
                    <div></div>
                </div>
            ),
            defaultSortOrder: 'ascend',
            sorter: (a, b) =>
                dayjs(a.uploaded_date).unix() - dayjs(b.uploaded_date).unix(),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'District',
            dataIndex: 'district_name',
            key: 'district_name',
            render: (_, record) => record?.district_name?.[0],
        },
        {
            title: 'Time Since',
            dataIndex: 'time_since',
            key: 'time_since',
            sorter: (a, b) => {
                const timeA = calculateTimeSince(b.assigned_at)
                const timeB = calculateTimeSince(a.assigned_at)
                return (
                    timeB.days * 1440 +
                    timeB.hours * 60 +
                    timeB.minutes -
                    (timeA.days * 1440 + timeA.hours * 60 + timeA.minutes)
                )
            },
            defaultSortOrder: 'descend',
            render: (_, record) => {
                const timesince = calculateTimeSince(record?.assigned_at)
                let day = ' day, '
                if (timesince?.days > 1) {
                    day = ' days, '
                }
                return (
                    <div
                        style={{
                            fontWeight: '500',
                            color:
                                timesince?.days > 0 ||
                                timesince?.hours > 0 ||
                                timesince?.minutes > 15
                                    ? 'red'
                                    : 'black',
                        }}
                    >
                        {timesince?.days > 0 ? timesince?.days + `${day} ` : ''}
                        {timesince?.hours > 0 ? `${timesince?.hours} hr ` : ''}
                        {timesince?.minutes > 0
                            ? `${timesince?.minutes} min`
                            : ''}
                    </div>
                )
            },
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            render: (_, record) => (
                <Button type="primary" onClick={() => onReviewClicked(record)}>
                    Review
                </Button>
            ),
        },
    ]

    const handleConfirm = async () => {
        notification.open({
            message: 'Availability Update',
            description:
                'Your tasks for today have been reassigned. Please take a break."',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        setIsAvailabel('')
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true)
        setShowPopover(false)
    }

    const handleCancel = async () => {
        notification.open({
            message: 'Availability Update',
            description: 'Short break time! Take a moment to recharge.',
            icon: <BellOutlined style={{ color: '#1890ff' }} />,
            style: {
                borderRadius: '8px',
                backgroundColor: '#f0f9ff',
            },
        })
        // message.info("Short break time! Take a moment to recharge.");
        setIsAvailabel('')
        localStorage.setItem('is_Available', '')
        await isAvailableApi(accessToken, false, true)
        setShowPopover(false)
    }
    const handleSwitchChange = async (checked) => {
        if (!checked) {
            setShowPopover(true)
        } else {
            setIsAvailabel(true)
            localStorage.setItem('is_Available', true)
            await isAvailableApi(accessToken, true, false)
        }
    }
    return (
        <div className="main_dev">
            <div className="dash_board_header">
                <h4>Review Content</h4>
               
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.1rem',
                        fontWeight: '500',
                        margin: 'auto',
                        
                    }}
                >
                    <label
                        style={{
                            marginRight: '.5rem',
                            color: 'orange',
                            fontSize: '1.1rem',
                        }}
                    >
                        Pending :
                    </label>
                    <span
                        style={{
                            color: 'red',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                          {unassignedCount?.[0]?.count}
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1.1rem',
                        fontWeight: '500',
                        margin: 'auto',
                    
                    }}
                >
                    <label
                        style={{
                            marginRight: '.5rem',
                            color: 'green',
                            fontSize: '1.1rem',
                        }}
                    >
                        In Review:
                    </label>
                    <span
                        style={{
                            color: 'red',
                            fontSize: '1.3rem',
                            fontWeight: 'bold',
                        }}
                    >
                        {todayData?.length + yesterData?.length + oldData?.length}
                      
                    </span>
                </div>
                <div className="active_btn">
                    <Button
                        style={{ marginRight: '3rem', opacity: '.7' }}
                        type="primary"
                        ghost
                        onClick={async () => {
                            setDataLoading(true)
                            const newData = await getUgContentFunc(
                                dayjs().startOf('day').format('YYYY-MM-DD'),
                                dayjs()
                                    .subtract(1, 'day')
                                    .startOf('day')
                                    .format('YYYY-MM-DD')
                            )
                            setTodayData(newData?.today)
                            setYesterData(newData?.yesterday)
                            setOldData(newData?.old)
                            setDataLoading(false)
                            const res = await getEmployeeDetails(
                                localStorage.getItem('EMPLOYEE_ID'),
                                accessToken
                            )
                            localStorage.setItem(
                                'is_Available',
                                res?.data?.[0]?.is_availdable === true
                                    ? true
                                    : ''
                            )
                            setIsAvailabel(
                                res?.data?.[0]?.is_availdable === true
                                    ? true
                                    : ''
                            )
                        }}
                    >
                        <RedoOutlined />
                        Refresh
                    </Button>
                    <label style={{ marginRight: '1rem' }}>
                        Availability :
                    </label>
                    <Popover
                        content={
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    marginRight: '2rem',
                                }}
                            >
                                <Button
                                    className="dayOff"
                                    style={{
                                        width: 150,
                                        marginBottom: '.5rem',
                                        background: 'blue',
                                        color: 'white',
                                    }}
                                    onClick={handleCancel}
                                >
                                    Short Break
                                </Button>
                                <Button
                                    className="dayOff"
                                    style={{
                                        width: 150,
                                        background: 'red',
                                        color: 'white',
                                    }}
                                    onClick={handleConfirm}
                                >
                                    Day Off
                                </Button>
                            </div>
                        }
                        title="You are about to set"
                        trigger="click"
                        visible={showPopover}
                        onVisibleChange={(visible) =>
                            !visible && setShowPopover(false)
                        }
                    >
                        <Switch
                            value={isAvailable}
                            onChange={handleSwitchChange}
                            checkedChildren="Active"
                            unCheckedChildren="Inactive"
                            className="active_status"
                        />
                    </Popover>
                </div>
            </div>
            <div className="outer_dev">
                <Table
                    className="no-padding-table"
                    dataSource={[...oldData, ...yesterData, ...todayData]}
                    columns={columns}
                    pagination={{ pageSize: 20 }}
                    bordered
                    loading={dataLoading}
                />
            </div>
        </div>
    )
}
export default NewDashBoard
