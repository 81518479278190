import { Button, message, Select, Input } from 'antd'
import { Option } from 'antd/es/mentions'
import React, { useEffect, useState } from 'react'
import { typeOptions } from '../ManageContent/ManageContent.constant'
import { useSelector } from 'react-redux'
import './Reporter.scss'
import { getReporterTableData } from '../../AadhanApi'
import ExcelSheetUI from './ExcelSheetUI'

const EditStringers = () => {
    const { Search } = Input
    const locations = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allMandals = useSelector((state) => state.app.mandals)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [districtData, setDistrictData] = useState([])
    const [typeOption, setTypeOption] = useState('paid')
    const [state, setState] = useState(undefined)
    const [district, setDistrict] = useState(undefined)
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [dataLoading, setDataLoading] = useState(false)
    const [data, setData] = useState([])
    const [messageApi, contextHolder] = message.useMessage()
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }
    const handleStateChange = (event) => {
        setDistrict(undefined)
        const districts = allDistricts?.[event]
        setDistrictData(districts)
        setState(event)
    }

    const getReporterTable = async () => {
        setDataLoading(true)

        try {
            const getReporterTableDataResponse = await getReporterTableData(
                typeOption,
                mobile,
                name,
                accessToken,
                state,
                district,
                1
            )
            if (getReporterTableDataResponse?.data) {
                setData(getReporterTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayError('You are unauthorised, Please login again')
            } else {
                displayError('Failed to get Stringers data')
            }
        }
    }
    useEffect(() => {
        getReporterTable()
    }, [state, district, typeOption])
    return (
        <div className="stringers_Container">
            <h4>Manage App Stringers</h4>
            <hr></hr>
            <div style={{ display: 'flex' }}>
                <Select
                    allowClear
                    placeholder="Select State"
                    value={state}
                    showSearch
                    onChange={(e) => handleStateChange(e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 150, marginRight: '2rem' }}
                >
                    {locations.map((option, index) => {
                        return (
                            <Option
                                key={option.state_id}
                                value={option.state_id}
                            >
                                {option?.name}
                            </Option>
                        )
                    })}
                </Select>
                <Select
                    value={district === undefined ? undefined : district}
                    allowClear
                    placeholder="Select District"
                    onChange={(e) => setDistrict(e)}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option?.children
                            ?.toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    style={{ width: 170, marginRight: '1rem' }}
                >
                    {districtData?.map((option, index) => {
                        return (
                            <Option
                                key={option?.district_id}
                                value={option?.district_id}
                            >
                                {option?.name}
                            </Option>
                        )
                    })}
                </Select>
                <Search
                    className="custom-search"
                    placeholder="search by name"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => setName(e.target.value)}
                    onSearch={() => getReporterTable()}
                    value={name}
                    style={{ width: 250 }}
                />

                <Search
                    placeholder="search by mobile number"
                    enterButton="Search"
                    size="middle"
                    allowClear
                    onChange={(e) => {
                        const value = e.target.value.slice(0, 10) // Ensure only 10 digits

                        setMobile(value)
                    }}
                    onSearch={() => getReporterTable()}
                    value={mobile}
                    style={{ width: 250 }}
                    type="number"
                />
            </div>
            <ExcelSheetUI
                allMandals={allMandals}
                dataLoading={dataLoading}
                data={data}
                accessToken={accessToken}
                languageData={languageData}
                locations={locations}
                allDistricts={allDistricts}
                allConstituencies={allConstituencies}
                allMandals={allMandals}
            />
        </div>
    )
}

export default EditStringers
