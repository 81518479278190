import { useEffect, useState } from 'react'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import './AddAd.scss'
import AddAdForm from './AddAdForm/AddAdForm'
import { useLocation, useNavigate } from 'react-router'
import {getInHouseAdsDataByAdId,getAdvertisers} from '../../AadhanApi'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'

const AddAd = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [imagePath, setImagePath] = useState('')
    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const mode = searchparam.get('mode')
    const content_id = searchparam.get('contentId')
    const [fileList, setFileList] = useState([])
    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }
    const descriptionChange = (newDesc) => {
        const inputLines = newDesc.split('\n')?.length
        if (inputLines <= 9) {
            setDescription(newDesc)
        }
    }
    const token = localStorage.getItem('ACCESS_TOKEN')
    const accessToken = useSelector((state) => state.app.accessToken)
    const [videoPath, setVideoPath] = useState('')
    const [youtubeLink, setYoutubeLink] = useState('')
    const [otherLink, setOtherLink] = useState('')
    const [weburl, setWebUrl] = useState('')
    const [selectedType, setSelectedType] = useState('shortnews')
    const [advertiserData, setAdvertiserData] = useState([])

    const allStates = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allMandals = useSelector((state) => state.app.mandals)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const navigate = useNavigate()
    const [initialFormData, setInitialFormData] = useState({
        language: "English",
        title: "",
        description: "",
        ad_type: "shortnews",
        image_url: "",
        htmlUrl: "",
        shortVideoLink: "",
        advertiser_name: [],
        cta_link: "",
        cta_name: "",
        // expiry_date : "",
        // published_date: "",
        state_name: [],
        // district_name: [],
        // mandal_name: [],
        // constituency_name: [],
        // category: undefined,
        proofread: false,
        // start_time_of_day:"",
        // end_time_of_day:"",
        num_times_per_day: [],
        position: [],
        locationSpecific: "no",
        is_proof_read: false,
    })

    const [resObjCopy, setResObjCopy] = useState({});
    const languageData = useSelector((state) => state.app.languageData)
    const categories = useSelector((state) => state.app.categories)
    
    const updateFormData = async (content_id, token) => {
        try {
            const response = await getInHouseAdsDataByAdId(content_id, token);
            // response?.data?.data is what?
            const resObj = response?.data?.data;
            const shortVideoLink = resObj => resObj?.stream_video_url ? "video" : 
                resObj?.yt_video_url ? "youtubeLink" : "otherLink";
            
            setResObjCopy(resObj);

            setFileList(resObj?.image_urls ? resObj.image_urls : [])

            if (resObj) {
                setInitialFormData((prevData) => ({
                    ...prevData,
                    // ...response.data.data
                    language: languageData.find((l) => l.language_id === resObj.language_id).language_name,
                    title: resObj?.title,
                    description: resObj?.description,
                    ad_type: resObj?.ad_type,
                    image_url: resObj?.image_url,
                    htmlUrl: resObj?.web_url,
                    shortVideoLink: resObj?.shortVideoLink ? resObj?.shortVideoLink : "",
                    advertiser_name: resObj?.advertiser_name,
                    cta_link: resObj?.cta_link,
                    cta_name: resObj?.cta_name,
                    expiry_date : dayjs(resObj?.expiry_date),
                    published_date: dayjs(resObj?.published_date),
                    start_time_of_day: dayjs(resObj?.start_time_of_day),
                    end_time_of_day: dayjs(resObj?.end_time_of_day),
                    yt_video_url: resObj?.yt_video_url || "",
                    stream_video_url: resObj?.stream_video_url || "",
                    mp4_video_url: resObj?.mp4_video_url || "",
                    content_id: resObj?.content_id,
                    state_name: resObj?.state_name[0],
                    district_name: resObj?.district_name[0],
                    mandal_name: resObj?.mandal_name[0],
                    constituency_names: resObj?.constituency_names[0],
                    category: resObj?.category_id,
                    proofread: resObj?.proof_read_by_name ? true : false,
                    num_times_per_day: resObj?.num_times_per_day,
                    position: resObj?.position,
                    locationSpecific: resObj?.state_name?.[0] ? "yes" : "no",
                    is_proof_read: resObj?.is_proof_read,
                }));
            }
        } catch (err) {
            console.log(err);
        }
    };

    const getAdvertisersData = async () => {
        const res = await getAdvertisers(token)
        return res?.data?.data
    }

    useEffect(() => {
        if(content_id && token && mode === 'edit'){
            updateFormData(content_id, token)
        }

        if(token){
            getAdvertisersData().then((data) => {
                setAdvertiserData(data)
            })
        }

    }, [content_id])

    return (
        <div style={{padding:"0 1rem"}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            > <h3 >
            {mode === 'edit' ? 'Edit Ad' : 'Add Ad'}
        </h3>
                
                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{ color: 'LightCoral', fontSize: '1.8rem' }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>
           
            <div className="add-ad-container row">
                <div className="col-md">
                    <AddAdForm
                        setImagePath={setImagePath}
                        imagePath={imagePath}
                        descriptionChange={descriptionChange}
                        titleChange={titleChange}
                        mode={mode}
                        initialFormData={initialFormData}
                        fileList={fileList}
                        setFileList={setFileList}
                        accessToken={accessToken}
                        setVideoPath={setVideoPath}
                        setYoutubeLink={setYoutubeLink}
                        setOtherLink={setOtherLink}
                        setWebUrl={setWebUrl}
                        setSelectedType={setSelectedType}
                        selectedType={selectedType}
                        resObjCopy={resObjCopy}
                        setTitle={setTitle}
                        setDescription={setDescription}
                        title={title}
                        description={description}
                        advertiserData={advertiserData}
                        allStates={allStates}
                        allDistricts={allDistricts}
                        allMandals={allMandals}
                        allConstituencies={allConstituencies}
                        languageData={languageData}
                        categories={categories}
                    />
                </div>
                <div className="col-md">
                    <SmartphonePreview
                        image={imagePath}
                        title={title}
                        description={description}
                        cardImages={fileList}
                        videoPath={videoPath}
                        weburl={weburl}
                        selectedType={selectedType}
                    />
                </div>
            </div>
        </div>
    )
}
export default AddAd