import {
    Button,
    DatePicker,
    TimePicker,
    Form,
    Input,
    Select,
    Radio,
    message,
} from 'antd'
import { LoadingOutlined, CloudUploadOutlined } from '@ant-design/icons'
import Dragger from 'antd/es/upload/Dragger'
import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import './AddAdForm.scss'
import { typeOptions } from './AddAdConstant'
import { useEffect, useState, useRef } from 'react'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import dayjs from 'dayjs'
import {
    createInHouseAds,
    getImageUrl,
    getVideoUrl,
    updateInHouseAds,
    getSubCategories,
} from '../../../AadhanApi'
import { useNavigate } from 'react-router'
import { isPublish, isSubmitAndDraft } from '../../App.constants'
import { useSelector } from 'react-redux'
const DraggableUploadListItem = ({ originNode, file, fileList }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: file.uid,
    })
    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        cursor: 'move',
    }
    return (
        <div
            ref={setNodeRef}
            style={style}
            className={isDragging ? 'is-dragging' : ''}
            {...attributes}
            {...listeners}
        >
            <span className="card-number">
                {fileList?.findIndex((f) => f.uid === file.uid) + 1}
            </span>
            {file.status === 'error' && isDragging
                ? originNode.props.children
                : originNode}
        </div>
    )
}

const AddAdForm = ({
    titleChange,
    descriptionChange,
    setImagePath,
    imagePath,
    initialFormData,
    fileList,
    setFileList,
    accessToken,
    setVideoPath,
    setYoutubeLink,
    setOtherLink,
    setWebUrl,
    setSelectedType,
    selectedType,
    mode,
    resObjCopy,
    setDescription,
    setTitle,
    title,
    description,
    advertiserData,
    allStates,
    allDistricts,
    allMandals,
    allConstituencies,
    categories,
    languageData,
}) => {
    const [form] = Form.useForm()
    const [remainingWordsCount, setRemaingWordsCount] = useState(60)
    const [remainingCharCount, setRemaingCharCount] = useState(400)
    const navigate = useNavigate()
    const [isImageLoading, setIsImageLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [fileListError, setFileListError] = useState()
    const [imageError, setImageError] = useState(false)
    const [isVideoUploading, setIsVideoUploading] = useState(false)
    const inputVideoRef = useRef(null)
    const [messageApi, contextHolder] = message.useMessage()

    const token = localStorage.getItem('ACCESS_TOKEN')
    const poster_name = localStorage.getItem('EMPLOYEE_NAME')
    const posterId = localStorage.getItem('EMPLOYEE_ID')

    const userPermissions = useSelector((state) => state.app.userPermissions)
    const employeeId = useSelector((state) => state.app.employeeId)

    const isShortNewsRequired = selectedType === 'shortnews'
    const isHtmlRequired = selectedType === 'html'
    const isShortVideoRequired = selectedType === 'shortvideo'
    const isCardRequired = selectedType === 'card'

    const [districts, setDistricts] = useState([])
    const [mandals, setMandals] = useState([])
    const [constituencies, setConstituencies] = useState([])

    const [stateId, setStateId] = useState('')
    const [districtId, setDistrictId] = useState('')
    const [mandalId, setMandalId] = useState('')
    const [constituencyId, setConstituencyId] = useState('')

    const [selectedState, setSelectedState] = useState(
        resObjCopy?.state_name?.[0] || ''
    )
    const [selectedDistrict, setSelectedDistrict] = useState(
        resObjCopy?.district_name || ''
    )
    const [selectedMandal, setSelectedMandal] = useState(
        resObjCopy?.mandal_name?.[0] || ''
    )
    const [selectedConstituency, setSelectedConstituency] = useState(
        resObjCopy?.constituency_names || ''
    )

    const [subCategory, setSubCategory] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(
        initialFormData?.category_id
    )

    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [imagePathUrl, setImagePathUrl] = useState('')

    const locationsData = useSelector((state) => state.app.locations)
    const roleId = useSelector((state) => state.app.role_id)
    const [isPublished, setIsPublished] = useState(false)

    // const [selectedLanguage, setSelectedLanguage] = useState('english')

    useEffect(() => {
        setSelectedState(resObjCopy?.state_name?.[0] || '')
        setSelectedDistrict(resObjCopy?.district_name || '')
        setSelectedMandal(resObjCopy?.mandal_name?.[0] || '')
        setSelectedConstituency(resObjCopy?.constituency_names || '')
    }, [mode])

    let state_id = ''
    let district_id = ''

    const getDistricts = (state_name) => {
        state_id = allStates.find(
            (state) => state.name === state_name
        )?.state_id
        setStateId(state_id)
        setDistricts(allDistricts[state_id])

        // constinuencies will be fetched when we select state, getDistricts will be called when we select state
        setConstituencies(allConstituencies[state_id])

        // Use form.setFieldsValue to clear the dependent fields
        form.setFieldsValue({
            district_name: undefined,
            mandal_name: undefined,
            constituency_names: undefined,
        })
        setMandals([])
    }

    const getMandals = (district_name) => {
        district_id = districts.find(
            (district) => district.name === district_name
        )?.district_id
        setDistrictId(district_id)
        setMandals(allMandals[district_id])

        // Clear only the mandal field
        form.setFieldsValue({ mandal_name: undefined })
    }

    const createRequest = async (values) => {
        const adName = values?.advertiser_name
        const adId = advertiserData.find(
            (ad) => ad.advertiser_name === adName
        )?.advertiser_id
        const category_name = categories.filter((ct) => {
            return (
                ct.category_id === values.category &&
                ct.language_name ===
                    languageData.find((l) => l.language_id === languageOption)
                        ?.language_name
            )
        })[0]?.category_name

        const request = {
            code: resObjCopy?.code || '',
            content_id: resObjCopy?.content_id || '',
            description: values?.description,
            title: values?.title,
            image_url:
                values?.ad_type !== 'card'
                    ? imagePath || resObjCopy.image_url
                    : '',
            category_id: values.category,
            category_name: category_name,
            content_type: 'inhousead',
            ad_type: values?.ad_type,
            media_type: '', // check
            content_status: 'draft', // check
            language_id: languageOption,
            thumbnail_image_url: '',
            updated_by: 0, // check
            dynamic_url: resObjCopy?.dynamic_url || '',
            stream_video_url:
                values?.shortVideoLink === 'video' ? values?.video : '',
            mp4_video_url:
                values?.shortVideoLink === 'otherLink' ? values?.otherLink : '',
            yt_video_url:
                values?.shortVideoLink === 'youtubeLink'
                    ? values?.youtubeLink
                    : '',
            left_color: '',
            right_color: '',
            proof_read_by_name: poster_name, // check, if proof read is required.
            proof_read_by: posterId, // check, if proof read is required.
            uploaded_by: posterId,
            uploaded_by_name: poster_name,
            advertiser_id: adId,
            advertiser_name: adName,
            state: stateId,
            state_name: values.state_name ? [values.state_name] : [],
            district: districtId || resObjCopy.district,
            district_name: values.district_name ? [values.district_name] : [],
            mandal: mandalId || resObjCopy.mandal,
            mandal_name: values.mandal_name ? [values.mandal_name] : [],
            constituency_ids: constituencyId ? [constituencyId] : [],
            constituency_names: values.constituency_names
                ? [values.constituency_names]
                : [],
            num_times_per_day: values.num_times_per_day,
            cta_name: values.cta_name,
            cta_link: values.cta_link,
            image_urls: fileList,
            web_url: values.ad_type === 'html' ? values?.htmlUrl : '',
            position: values.position,
            published_date: dayjs(values.published_date),
            // proof_read_date:values.is_proof_read ? dayjs() : "", added below
            expiry_date: dayjs(values.expiry_date),
            start_time_of_day: dayjs(values.start_time_of_day),
            end_time_of_day: dayjs(values.end_time_of_day),
            locationSpecific: values.locationSpecific,
            is_proof_read: true,
        }

        let newrequest = {}
        if (resObjCopy) {
            newrequest = { ...resObjCopy, ...request }
        }
        return newrequest
    }

    const handleSave = async (type) => {
        setIsLoading(true)
        const values = form.getFieldsValue()
        const hellorequest = await createRequest(values)
        const request = { ...resObjCopy, ...hellorequest }
        request.content_status = type === 'draft' ? 'draft' : 'submit'

        try {
            const validation = await form.validateFields([
                'title',
                'category',
                'description',
                'ad_type',
                'advertiser_name',
                'cta_name',
                'cta_link',
                'language',
                'state_name',
                'published_date',
                'expiry_date',
                'start_time_of_day',
                'end_time_of_day',
                'num_times_per_day',
                'position',
                'proofread',
                'image_url',
            ])

            if (
                request.ad_type !== 'card' &&
                request?.image_url?.length === 0 &&
                request?.image_urls?.length === undefined
            ) {
                setIsLoading(false)
                return
            }
        } catch (e) {
            setIsLoading(false)
            return
        }

        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            try {
                const inHouseAdsResponse = await updateInHouseAds(
                    request,
                    initialFormData.content_id,
                    token
                )
                setIsLoading(false)
                if (inHouseAdsResponse?.status === 200) {
                    navigate('/home/ads')
                }
            } catch (err) {
                console.log(err)
            }
        } else if (mode === 'add') {
            try {
                const validation = await form.validateFields(['image_url'])
            } catch (err) {
                console.log(err)
            }

            request.uploaded_by = employeeId
            request.updated_by = 0
            try {
                const response = await createInHouseAds(request, token) // check

                if (response?.status === 200) {
                    navigate('/home/ads')
                }
                setIsLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const handlePublish = async () => {
        setIsLoading(true)
        const values = form.getFieldsValue()
        const hellorequest = await createRequest(values)
        const request = { ...resObjCopy, ...hellorequest }

        try {
            const validation = await form.validateFields([
                'title',
                'category',
                'description',
                'ad_type',
                'advertiser_name',
                'cta_name',
                'cta_link',
                'language',
                'state_name',
                'published_date',
                'expiry_date',
                'start_time_of_day',
                'end_time_of_day',
                'num_times_per_day',
                'position',
                'proofread',
                'image_url',
            ])

            if (
                request.ad_type !== 'card' &&
                request?.image_url?.length === 0 &&
                request?.image_urls?.length === undefined
            ) {
                setIsLoading(false)
                return
            }
        } catch (e) {
            setIsLoading(false)
            return
        }

        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.content_status = 'approved'
            try {
                const inHouseAdsResponse = await updateInHouseAds(
                    request,
                    initialFormData.content_id,
                    token
                )
                setIsLoading(false)

                if (inHouseAdsResponse?.status === 200) {
                    navigate('/home/ads')
                }
            } catch (err) {
                console.log(err)
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.content_status = 'approved'

            try {
                const validation = await form.validateFields(['image_url'])
            } catch (err) {
                console.log(err)
            }

            try {
                const response = await createInHouseAds(request, token) // check

                if (response?.status === 200) {
                    navigate('/home/ads')
                }
                setIsLoading(false)
            } catch (err) {
                console.log(err)
            }
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const getRemainingWords = (value, type, max) => {
        if (type === 'words') {
            const remaingWords = max - value?.split(' ')?.length
            return remaingWords
        } else {
            const remainingChar = max - value.length
            return remainingChar
        }
    }

    const getImagePath = async (path) => {
        setIsImageLoading(true)
        const cat = categories
            ?.map((ele) => {
                if (
                    ele.language_name === languageOption===1?'English':languageOption===2?'Telugu':languageOption===3?'Hindi':'Tamil' &&
                    ele.category_id === form.getFieldValue('category')
                ) {
                    return ele.category_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]
        const token = localStorage.getItem('ACCESS_TOKEN')
        const pathUrl = await getImageUrl(path, 'inhousead', token,languageOption===1?'English':languageOption===2?'Telugu':languageOption===3?'Hindi':'Tamil',title,'',cat)
        setIsImageLoading(false)

        if (pathUrl) {
            const imagePath =
                pathUrl.data.filter((p) => p.includes('public'))?.[0] ||
                pathUrl.data?.[0]
            form.setFieldsValue({ image_url: imagePath })
            setImagePath(imagePath)
        }
    }

    useEffect(() => {
        setLanguageOption(
            languageData.filter(
                (l) => l.language_name === initialFormData.language
            )[0]?.language_id || 1
        )

        form.setFieldsValue(initialFormData)
        form.setFieldsValue({ image_url: initialFormData.image_url })
        setIsPublished(resObjCopy.content_status === 'approved' ? true : false)

        if (resObjCopy && resObjCopy.state !== undefined) {
            setDistricts(allDistricts[resObjCopy.state] || [])
            setConstituencies(allConstituencies[resObjCopy.state] || [])
        }

        if (resObjCopy && resObjCopy.district !== undefined) {
            setMandals(allMandals[resObjCopy.district] || [])
        }

        setStateId(resObjCopy.state)
        setDistrictId(resObjCopy.district)
        setMandalId(resObjCopy.mandal)

        setConstituencyId(resObjCopy?.constituency_ids?.[0])
        setSelectedType(initialFormData.ad_type)
        // setting the short video link and radio button

        let shortVideoLink
        if (initialFormData.yt_video_url) {
            shortVideoLink = 'youtubeLink'
            form.setFieldsValue({ youtubeLink: initialFormData.yt_video_url })
        } else if (initialFormData.mp4_video_url) {
            shortVideoLink = 'otherLink'
            form.setFieldsValue({ otherLink: initialFormData.mp4_video_url })
        } else if (initialFormData.stream_video_url) {
            shortVideoLink = 'video'
            form.setFieldsValue({ video: initialFormData.stream_video_url })
        }

        if (
            selectedType === 'shortvideo' ||
            (selectedType === 'shortnews' &&
                (initialFormData.yt_video_url ||
                    initialFormData.mp4_video_url ||
                    initialFormData.stream_video_url))
        ) {
            form.setFieldsValue({ videoLink: shortVideoLink })
        }

        if (selectedType === 'shortvideo') {
            form.setFieldsValue({ shortVideoLink: shortVideoLink })
        } else if (
            selectedType === 'shortnews' &&
            (initialFormData.yt_video_url ||
                initialFormData.mp4_video_url ||
                initialFormData.stream_video_url)
        ) {
            form.setFieldsValue({ shortVideoLink: shortVideoLink })
            setVideoPath(
                initialFormData.yt_video_url ||
                    initialFormData.mp4_video_url ||
                    initialFormData.stream_video_url
            )
        }

        // end of setting short video link and radio button.

        if (initialFormData.description) {
            setDescription(initialFormData.description)
        }

        // set descritption into the form using setFieldsValue
        form.setFieldsValue({ description: initialFormData.description })

        if (initialFormData.ad_type === 'html') {
            setWebUrl(initialFormData.htmlUrl)
        }
        setImagePath(initialFormData.image_url)

        handleTitleChange(resObjCopy.title)
        if (
            initialFormData?.ad_type === 'shortnews' &&
            initialFormData?.image_url
        ) {
            form.setFieldsValue({ image_url: initialFormData.image_url })
            setImagePath(initialFormData.image_url)
            handleTitleChange(resObjCopy.title)
            // form.setFieldsValue({ title: resObjCopy.title });
            setDescription(initialFormData.description)
        }

        // set dropdown fields as undefined.
        form.setFieldValue(
            'published_date',
            isNaN(new Date(initialFormData.published_date).getTime())
                ? undefined
                : initialFormData.published_date
        )
        form.setFieldValue(
            'expiry_date',
            isNaN(new Date(initialFormData.expiry_date).getTime())
                ? undefined
                : initialFormData.expiry_date
        )
        form.setFieldValue(
            'start_time_of_day',
            isNaN(new Date(initialFormData.start_time_of_day).getTime())
                ? undefined
                : initialFormData.start_time_of_day
        )
        form.setFieldValue(
            'end_time_of_day',
            isNaN(new Date(initialFormData.end_time_of_day).getTime())
                ? undefined
                : initialFormData.end_time_of_day
        )

        setImagePathUrl(initialFormData.image_url)
        setImagePath(initialFormData.image_url)
    }, [initialFormData, form, mode]) // Trigger when initialFormData changes

    const sensor = useSensor(PointerSensor, {
        activationConstraint: {
            distance: 10,
        },
    })

    const onDragEnd = ({ active, over }) => {
        if (active.id !== over?.id) {
            setFileList((prev) => {
                const activeIndex = prev.findIndex((i) => i.uid === active.id)
                const overIndex = prev.findIndex((i) => i.uid === over?.id)
                return arrayMove(prev, activeIndex, overIndex)
            })
        }
    }

    const onChange = ({ fileList: newFileList }) => {
        const reader = new FileReader()
        if (newFileList.length) {
            reader.onload = (event) => {
                const img = new Image()
                img.src = event.target.result

                img.onload = () => {
                    const width = img.width
                    const height = img.height
                    if (width && height) {
                        setImageError(false)
                        // Remove the xhr key from each object in newFileList
                        const modifiedFileList = newFileList.map((file) => {
                            const { xhr, ...rest } = file
                            return rest
                        })
                        setFileList(modifiedFileList)
                    } else {
                        setImageError(true)
                    }
                }
            }
            reader.readAsDataURL(
                newFileList[newFileList.length - 1].originFileObj
            )
        } else {
            setFileList([])
        }
    }

    const draggerProps = {
        name: 'file',
        multiple: true,
        onRemove: (file) => {
            const index = fileList?.indexOf(file)
            const newFileList = fileList?.slice()
            if (index > -1) {
                newFileList?.splice(index, 1)
            }
            setFileList(newFileList)
        },
        customRequest: ({ file, onSuccess }) => {
            setIsLoading(true)
            checkImageDimensions(file)
                .then(({ width, height }) => {
                    if (width && height) {
                        setImageError(false)
                        getImageUrl(file, 'card', accessToken)
                            .then((pathUriArr) => {
                                setIsLoading(false)
                                if (pathUriArr?.data) {
                                    const path =
                                        pathUriArr?.data?.filter((path) =>
                                            path.includes('card')
                                        )[0] || pathUriArr?.data?.[0]
                                    setFileList([...fileList, path])
                                    onSuccess(path)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    } else {
                        setImageError(true)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    }

    const checkImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.onload = (event) => {
                const img = new Image()
                img.src = event.target.result

                img.onload = () => {
                    const width = img.width
                    const height = img.height
                    resolve({ width, height })
                }

                img.onerror = (error) => {
                    reject(error)
                }
            }

            reader.readAsDataURL(file)
        })
    }

    const getVideoPath = async (path) => {
        setIsLoading(true)
        if (path && path?.size / (1024 * 1024) <= 50) {
            setIsVideoUploading(true)
            try {
                const pathUri = await getVideoUrl(path, accessToken)
                if (pathUri?.data) {
                    setIsLoading(false)
                    setVideoPath(pathUri?.data)
                    setIsVideoUploading(false)
                    form.setFieldValue('video', pathUri?.data)
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to upload Video, Please try again',
                    duration: 5,
                })
                setIsVideoUploading(false)
                setVideoPath('')
            }
        } else {
            setIsLoading(false)
            alert('Video Size should be less than or Equal to 50Mb')
            inputVideoRef.current.value = null
        }
    }

    const changeDescriptionValue = (descriptionValue) => {
        // Clean up the description value
        let cleanedValue = descriptionValue
            .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            // .replace(/\s*([,.!?;:])\s*(?=\S)/g, '$1 ') // Ensure one space after punctuation only if there is more content
            .replace(/\s*([,.!?;:])$/, '$1') // Remove trailing space after punctuation at the end

        if (cleanedValue?.split('\n')?.length <= 9) {
            form.setFieldValue('description', cleanedValue)
            const remainingWords = getRemainingWords(cleanedValue, 'words', 60)
            const remainingChars = getRemainingWords(cleanedValue, 'char', 400)
            if (remainingWords > -1 && remainingChars > -1) {
                form.setFieldValue('description', cleanedValue)
                setDescription(cleanedValue)
                setRemaingCharCount(remainingChars)
                setRemaingWordsCount(remainingWords)
            } else if (cleanedValue === '') {
                setRemaingCharCount(400)
                setRemaingWordsCount(59)
            } else {
                if (remainingWords < 0) {
                    const newDescription = cleanedValue
                        .split(' ')
                        .slice(0, remainingWords)
                        .join(' ')
                    changeDescriptionValue(newDescription)
                } else if (remainingChars < 0) {
                    const newDescription = cleanedValue
                        .split('')
                        .slice(0, remainingChars)
                        .join('')
                    changeDescriptionValue(newDescription)
                }
            }
        }
    }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
                .replace(/\s+/g, ' ')
                // .replace(/\s*([,.!?;:])\s*(?=\S)/g, '$1 ')
                .replace(/\s*([,.!?;:])$/, '$1')

            cleanedValue = cleanedValue.trimStart()
            if (/[.!?]$/.test(cleanedValue)) {
                cleanedValue = cleanedValue.trimEnd()
            }
            setTitle(cleanedValue)
            form.setFieldValue('title', cleanedValue)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
        }
    }

    async function handleCategory(e) {
        setSelectedCategory(null)
        if (e !== null && e !== undefined) {
            await getSubCategories(accessToken, languageOption, e).then(
                (resp) => setSubCategory(resp.data.data)
            )
            setSelectedCategory(e)
        }
        setSelectedCategory(e)
    }

    useEffect(() => {
        if (initialFormData.category) {
            handleCategory(initialFormData?.category)
        }
    }, [locationsData])

    const handleLanguageChange = (id) => {
        setLanguageOption(id)
        form.setFieldValue('category', undefined)
        form.setFieldValue('subcategory', undefined)
        setSubCategory([])
    }

    function capitalizeFirstLetter(word) {
        return word.charAt(0).toUpperCase() + word.slice(1)
    }

    return (
        <div id="form" style={{ width: '100%' }}>
            {contextHolder}
            <Form
                form={form}
                name="basic"
                scrollToFirstError
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                // initialValues={{
                //     image_url: initialFormData?.image_url,
                // }}
                // initialValues={initialFormData}
                // onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                onValuesChange={(changedValues, allValues) => {
                    console.log('Changed:', changedValues)
                    console.log('All Values:', allValues)
                }}
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select
                        placeholder="--Select Category--"
                        onChange={(e) => handleCategory(e)}
                    >
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                    languageData?.filter(
                                        (l) => l.language_id === languageOption
                                    )?.[0]?.language_name
                            )
                            ?.map((option) => {
                                return (
                                    <Select.Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>
                {selectedCategory &&
                    roleId !== 5 &&
                    roleId !== 3 &&
                    subCategory?.length !== 0 && (
                        <Form.Item name="subcategory">
                            <Radio.Group
                                style={{
                                    marginLeft: '35%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'start',
                                    justifyContent: 'space-between',
                                }}
                            >
                                {subCategory?.map((e) => {
                                    return (
                                        <Radio
                                            style={{ margin: '.25rem' }}
                                            value={e.sub_category_name}
                                            key={e.sub_category_id}
                                        >
                                            {e.sub_category_name}
                                        </Radio>
                                    )
                                })}
                            </Radio.Group>
                        </Form.Item>
                    )}

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message:
                                'Value is required and should be less than 75 characters',
                        },
                    ]}
                >
                    <Input.TextArea
                        maxLength={75}
                        onChange={(e) => {
                            titleChange(e.target.value)
                            handleTitleChange(e.target.value)
                        }}
                        placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                        value={title}
                    />
                    <span className="description-subtext">
                        <span
                            style={{
                                color: title?.length > 75 ? 'red' : null,
                            }}
                        >
                            {' '}
                            {75 - (title?.length ?? 0)} characters
                        </span>
                    </span>
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        { required: true, message: 'please input description' },
                    ]}
                >
                    <div>
                        <Input.TextArea
                            style={{ minHeight: '10rem' }}
                            rows={2}
                            value={description}
                            onChange={(e) => {
                                descriptionChange(e.target.value)
                                changeDescriptionValue(e.target.value)
                            }}
                            placeholder="Upto 60 words"
                        />
                        <span className="description-subtext">
                            <span
                                style={{
                                    color:
                                        remainingWordsCount === 0
                                            ? 'red'
                                            : null,
                                }}
                            >
                                {' '}
                                {remainingWordsCount} words
                            </span>
                            |
                            <span
                                style={{
                                    color:
                                        remainingCharCount === 0 ? 'red' : null,
                                }}
                            >
                                {remainingCharCount} characters
                            </span>
                            |<span>max 9 line only</span>
                        </span>
                    </div>
                </Form.Item>

                <Form.Item
                    label="Ad Type"
                    name="ad_type"
                    rules={[{ required: true, message: 'please select type' }]}
                >
                    <Select onChange={(value, event) => setSelectedType(value)}>
                        {typeOptions.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                                {option.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {isCardRequired ? (
                    <Form.Item
                        rules={[
                            { required: true, message: 'Please upload a Card' },
                        ]}
                        wrapperCol={{ span: 24 }}
                    >
                        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                            <SortableContext
                                items={fileList?.map((i) => i?.uid)}
                                strategy={verticalListSortingStrategy}
                            >
                                <Dragger
                                    listType="picture"
                                    fileList={fileList}
                                    onChange={onChange}
                                    {...draggerProps}
                                    itemRender={(
                                        originNode,
                                        file,
                                        fileList
                                    ) => (
                                        <DraggableUploadListItem
                                            originNode={originNode}
                                            file={file}
                                            fileList={fileList}
                                        />
                                    )}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <CloudUploadOutlined />
                                    </p>
                                    <Button>Select Your Card</Button>
                                    <div
                                        style={{
                                            color: '#199be9',
                                            marginTop: '5px',
                                        }}
                                    >
                                        Image size 1080*2340
                                    </div>
                                </Dragger>
                            </SortableContext>
                        </DndContext>
                        {fileListError && (
                            <p className="error-card"> Please Upload a Card</p>
                        )}
                        {imageError && (
                            <p className="error-card">
                                Card Image size should be 1080*2340
                            </p>
                        )}
                    </Form.Item>
                ) : (
                    <Form.Item
                        label="Image"
                        name="image_url"
                        rules={[
                            {
                                required: true,
                                message: 'Please select an Image',
                            },
                        ]}
                    >
                        <ImageUploader getImagePath={getImagePath} />
                        <div> {isImageLoading && <LoadingOutlined />}</div>
                        {mode === 'edit' && (
                            <a href={imagePathUrl} target="_blank">
                                Uploaded Image
                            </a>
                        )}
                    </Form.Item>
                )}

                {isHtmlRequired && (
                    <Form.Item
                        label="HTML URL"
                        name="htmlUrl"
                        rules={[
                            {
                                required: isHtmlRequired,
                                message: 'Please input HTML URL',
                            },
                            {
                                type: 'url',
                                message: 'Please enter valid link',
                            },
                        ]}
                    >
                        <Input
                            onChange={(e) => {
                                setWebUrl(e.target.value)
                            }}
                        />
                    </Form.Item>
                )}

                {(isShortNewsRequired || isShortVideoRequired) && (
                    <div>
                        <Form.Item
                            label="Short Video"
                            name="shortVideoLink"
                            rules={[
                                {
                                    required: isShortVideoRequired,
                                    message: 'Please upload the video',
                                },
                            ]}
                        >
                            <Radio.Group>
                                <Radio value="youtubeLink">
                                    {' '}
                                    Youtube Link{' '}
                                </Radio>
                                <Radio value="otherLink"> Other Link </Radio>
                                <Radio value="video"> Video </Radio>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.shortVideoLink !==
                                currentValues.shortVideoLink
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('shortVideoLink') === 'video' ? (
                                    <Form.Item
                                        name="video"
                                        label="Video"
                                        rules={[
                                            {
                                                required: isShortVideoRequired,
                                                message:
                                                    'Please upload a video',
                                            },
                                        ]}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: '5px',
                                            }}
                                        >
                                            <input
                                                accept="video/mp4"
                                                ref={inputVideoRef}
                                                onChange={(e) => {
                                                    getVideoPath(
                                                        e.target.files?.[0]
                                                    )
                                                }}
                                                type="file"
                                            />
                                            <div>
                                                {isVideoUploading && (
                                                    <LoadingOutlined />
                                                )}
                                            </div>
                                        </div>
                                        <div>Max size 50Mb and 1080*2340</div>
                                    </Form.Item>
                                ) : getFieldValue('shortVideoLink') ===
                                  'youtubeLink' ? (
                                    <Form.Item
                                        label="Youtube Link"
                                        name="youtubeLink"
                                        rules={[
                                            {
                                                type: 'url',
                                                message:
                                                    'Please enter valid link',
                                            },
                                            {
                                                message:
                                                    'Please enter the link',
                                                required: isShortVideoRequired,
                                            },
                                        ]}
                                    >
                                        <Input
                                            placeholder="Youtube Link"
                                            onChange={(e) => {
                                                setYoutubeLink(e?.target?.value)
                                                setVideoPath(e?.target?.value)
                                            }}
                                        />
                                    </Form.Item>
                                ) : (
                                    getFieldValue('shortVideoLink') ===
                                        'otherLink' && (
                                        <Form.Item
                                            label="Other Link"
                                            name="otherLink"
                                            rules={[
                                                {
                                                    type: 'url',
                                                    message:
                                                        'Please enter valid link',
                                                    required:
                                                        isShortVideoRequired,
                                                },
                                            ]}
                                        >
                                            <Input
                                                placeholder="Other Link"
                                                onChange={(e) => {
                                                    setOtherLink(
                                                        e?.target?.value
                                                    )
                                                    setVideoPath(
                                                        e?.target?.value
                                                    )
                                                }}
                                            />
                                        </Form.Item>
                                    )
                                )
                            }
                        </Form.Item>
                    </div>
                )}

                <Form.Item
                    label="Advertiser"
                    name="advertiser_name"
                    rules={[{ required: true, message: 'Please select' }]}
                >
                    <Select placeholder="Select Advetiser" allowClear>
                        {advertiserData.map((ad) => {
                            return (
                                <Select.Option
                                    value={ad.advertiser_name}
                                    key={ad.advertiser_id}
                                >
                                    {ad.advertiser_name}
                                </Select.Option>
                            )
                        })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="CTA Link"
                    name="cta_link"
                    rules={[
                        { required: true, message: 'please input CTA Link' },
                        { type: 'url', message: 'Please enter valid link' },
                    ]}
                >
                    <Input placeholder="CTA Link" />
                </Form.Item>

                <Form.Item
                    label="CTA Name"
                    name="cta_name"
                    rules={[
                        { required: true, message: 'please input CTA Name' },
                    ]}
                >
                    <Input placeholder="CTA Name" />
                </Form.Item>

                <Form.Item label="Location Specific" name="locationSpecific">
                    <Radio.Group>
                        <Radio value="yes"> Yes </Radio>
                        <Radio value="no"> No </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.locationSpecific !==
                        currentValues?.locationSpecific
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item
                                label="state"
                                name="state_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please select state',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Select State"
                                    onChange={(value) => {
                                        getDistricts(value)
                                        setSelectedState(value)
                                    }}
                                    value={selectedState}
                                >
                                    {allStates.map((state) => (
                                        <Select.Option
                                            key={state.state_id}
                                            value={state.name}
                                        >
                                            {state.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('state_name', [])
                        )
                    }
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state_name !== currentValues.state_name
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('state_name') &&
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item
                                label="District"
                                name="district_name"
                                rules={[
                                    {
                                        required: false,
                                        message: 'please select district',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        getMandals(value)
                                        setSelectedDistrict(value)
                                    }}
                                    value={selectedDistrict}
                                    placeholder="Select District"
                                >
                                    {districts.map((district) => (
                                        <Select.Option
                                            key={district.district_id}
                                            value={district.name}
                                        >
                                            {district.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('district_name', [])
                        )
                    }
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state_name !== currentValues.state_name
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('state_name') &&
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item
                                label="Constituencies"
                                name="constituency_names"
                                rules={[
                                    {
                                        required: false,
                                        message: 'please select constituency',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setSelectedConstituency(value)
                                        setConstituencyId(
                                            constituencies.find(
                                                (constituency) =>
                                                    constituency.name === value
                                            )?.assembly_constituency_id
                                        )
                                    }}
                                    value={selectedConstituency}
                                    placeholder="Select Constituency"
                                >
                                    {constituencies.map((constituency) => (
                                        <Select.Option
                                            key={
                                                constituency.assembly_constituency_id
                                            }
                                            value={constituency.name}
                                        >
                                            {constituency.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('constituency_names', [])
                        )
                    }
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state_name !== currentValues.state_name
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('district_name') &&
                        getFieldValue('locationSpecific') === 'yes' &&
                        getFieldValue('district_name').length !== 0 ? (
                            <Form.Item
                                label="Mandal"
                                name="mandal_name"
                                rules={[
                                    {
                                        required: false,
                                        message: 'please select mandal',
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(value) => {
                                        setSelectedMandal(value)
                                        setMandalId(
                                            mandals.find(
                                                (mandal) =>
                                                    mandal.name === value
                                            )?.mandal_id
                                        )
                                    }}
                                    value={selectedMandal}
                                    placeholder="Select Mandal"
                                >
                                    {mandals.map((mandal) => (
                                        <Select.Option
                                            key={mandal.mandal_id}
                                            value={mandal.name}
                                        >
                                            {mandal.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('mandal_name', [])
                        )
                    }
                </Form.Item>

                <Form.Item
                    label="Number of times"
                    name="num_times_per_day"
                    rules={[{ required: true, message: 'Please input' }]}
                >
                    <Select
                        style={{
                            marginLeft: '5px',
                        }}
                        placeholder="Select Number of times per day"
                    >
                        {[1, 2, 3, 4, 5].map((value) => (
                            <Select.Option key={value} value={value}>
                                {value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Position"
                    name="position"
                    rules={[{ required: true, message: 'Please input' }]}
                >
                    <Select placeholder="Select Position">
                        {[1, 2, 3, 4, 5].map((value) => (
                            <Select.Option key={value} value={value}>
                                {value}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    label="Expiry"
                    name="expiry_date"
                    rules={[{ required: true, message: 'Please Select Date' }]}
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY HH:mm"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>

                <Form.Item
                    label="Publish"
                    name="published_date"
                    rules={[{ required: true, message: 'Please Select Date' }]}
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY HH:mm"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>

                <Form.Item
                    label="Start Time of Day"
                    name="start_time_of_day"
                    rules={[{ required: true, message: 'Please Select TIme' }]}
                >
                    <TimePicker format="HH:mm" />
                </Form.Item>

                <Form.Item
                    label="End Time of Day"
                    name="end_time_of_day"
                    rules={[{ required: true, message: 'Please Select Time' }]}
                >
                    <TimePicker format="HH:mm" />
                </Form.Item>

                {/* <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.publish !== currentValues.publish
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('publish') === 'schedule' ? (
                            <Form.Item
                                name="date"
                                label="Date"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please enter Date',
                                    },
                                ]}
                            >
                                <DatePicker
                                    format="YYYY-MM-DD HH:mm:ss"
                                    showTime={true}
                                />
                            </Form.Item>
                        ) : null
                    }
                </Form.Item> */}

                {/* {languageOption !== 2 && (
                    <Form.Item
                        label="Proof read"
                        name="is_proof_read"
                        rules={[
                            {
                                required: true,
                                message: 'Please select an option',
                            },
                        ]}
                        // className="custom-form-item"
                    >
                        <Radio.Group>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                        </Radio.Group>
                    </Form.Item>
                )} */}

                <Form.Item wrapperCol={{ span: 24 }}>
                    {isSubmitAndDraft(userPermissions, 'Content', 'Ads') && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            disabled={isLoading || isPublished}
                            onClick={() => handleSave('draft')}
                            // htmlType='submit'
                        >
                            Save
                        </Button>
                    )}
                    {isSubmitAndDraft(userPermissions, 'Content', 'Ads') && (
                        <Button
                            className="bg-color width-150 submit-buttons"
                            type="primary"
                            disabled={isLoading || isPublished}
                            onClick={() => handleSave('submit')}
                            // htmlType='submit'
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'Ads') && (
                        <Button
                            className="bg-color width-150 marginTop-10"
                            type="primary"
                            disabled={isLoading}
                            onClick={() => handlePublish()}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}

export default AddAdForm
