import { Button, Col, Form, Input, Radio, Row, Select } from 'antd'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import {
    createEmployee,
    getEmployeeDetails,
    getEmployeeShifts,
    getRoleList,
    updateEmployee,
} from '../../AadhanApi'
import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import './Employee.scss'
import ReactSelect from 'react-select'
import { Option } from 'antd/es/mentions'

const EditEmployee = () => {
    const [form] = Form.useForm()
    const [allRoles, setAllRoles] = useState([])
    const [selectedEmployee, setSelectedEmployee] = useState()
    const navigate = useNavigate()

    const getAllRoleList = async () => {
        const response = await getRoleList(accessToken)
        setAllRoles(response?.data)
    }

    useEffect(() => {
        getAllRoleList()
    }, [])

    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('employeeId')
    const mode = searchparam.get('mode')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = [
        {
            "language_id": 1,
            "language_name": "English",
          
        },
        {
            "language_id": 2,
            "language_name": "Telugu",
            
        },
        {
            "language_id": 3,
            "language_name": "Hindi",
           
        },
        {
            "language_id": 4,
            "language_name": "Tamil",
           
        }
    ]
    const allEmployees = useSelector((state) => state.app.employees)
    const employeeId = useSelector((state) => state.app.employeeId)
    const allStates = useSelector((state) => state.app.locations).map((e) => ({
        value: e.state_id,
        label: e.name,
    }))
    const allDistricts = useSelector((state) => state.app.districts)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [state, setState] = useState('')
    const [selectedConstituencies, setSelectedConstituncies] = useState([])
    const [selectedDistricts, setSelectedDistricts] = useState([])
    const [shifts,setShifts] = useState([])
    async function getShiftData(){
        const data = await getEmployeeShifts(accessToken)
        setShifts(data?.data?.data)
    }
    const onFinish = async (values) => {
        const obj = {
            first_name: values.firstName,
            last_name: values.lastName,
            email_id: values.email,
            password: mode === 'add' ? values.password : undefined,
            mobile_number: values.mobile,
            role_id: values.role,
            languages: values.languages,
            reports_to: values.reportsTo,
            image: '',
            created_by: employeeId,
            created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            typeof_employee: 'internal',
            last_login_time: '2023-07-19T14:34:40.573Z',
            updated_by: employeeId,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            status: values.status,
            district: values?.district?.map(({ label }) => label).join(','),
            district_id: values?.district?.map(({ value }) => value).join(','),
            state: values?.state?.map(({ label }) => label).join(','),
            state_id: values?.state?.map(({ value }) => value).join(','),
            constituencies: values?.constituency?.map(({ label }) => label),
            constituency_ids: values?.constituency?.map(({ value }) => value),
            is_availdable: values?.is_available,
            shift_id:
                values?.shift_name !== '' &&
                values?.shift_name !== undefined &&
                values?.shift_name !== null
                    ? values?.shift_name
                    : 0,
            shift_name: shifts.filter((e)=>e.shift_id===values?.shift_name)?.[0]?.shift_name,
        }
        if (mode === 'add') {
            const employeeResponse = await createEmployee(obj, accessToken)
            if (employeeResponse.data === 'Employee inserted successfully') {
                navigate('/home/employees')
                form.resetFields()
            }
        } else {
            const employeeResponse = await updateEmployee(
                obj,
                selectedEmployee.employee_id,
                accessToken
            )
            if (employeeResponse.data === 'employee updated successfully') {
                navigate('/home/employees')
                form.resetFields()
            }
        }
    }

    const updateFormData = async (contentId, accessToken) => {
        const response = await getEmployeeDetails(contentId, accessToken)
        handleStateChange(response?.data[0]?.state_id
            ?.split(',')
            ?.map((value, index) => ({
                value: value,
                label: response?.data[0]?.state?.split(',')[index],
            })),)
        setSelectedEmployee(response?.data[0])
        if (response?.data[0]) {
            form.setFieldsValue({
                firstName: response.data[0].first_name,
                lastName: response.data[0].last_name,
                email: response.data[0].email_id,
                mobile: response.data[0].mobile_number,
                role: response.data[0].role_id,
                languages: response.data[0].languages,
                password: response.data[0].password,
                reportsTo: response.data[0].reports_to,
                status: response?.data[0].status,
                district:response?.data[0].district_id!=='' ? response?.data[0]?.district_id
                    ?.split(',')
                    ?.map((value, index) => ({
                        value: value,
                        label: response?.data[0]?.district?.split(',')[index],
                    })) : [],
                
                state:response?.data[0].state_id!=='' ? response?.data[0]?.state_id
                    ?.split(',')
                    ?.map((value, index) => ({
                        value: value,
                        label: response?.data[0]?.state?.split(',')[index],
                    })) : [],

                constituency:response?.data[0].constituency_ids?.length!==0 ? response?.data[0]?.constituency_ids?.map(
                    (value, index) => ({
                        value: value,
                        label: response?.data[0]?.constituencies?.[index],
                    })
                ):[],

                is_available: response?.data[0]?.is_availdable,
                // shift_id: response?.data[0]?.shift_id,
                shift_name: response?.data[0]?.shift_id,
            })
        }
    }

    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
        getShiftData()
    }, [contentId])
    
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    }
    const customStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '32px',
            height: '32px',
        }),
        valueContainer: (provided) => ({
            ...provided,
            height: '32px',
            padding: '0 6px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0px',
            fontSize: '12px',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            height: '32px',
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '12px',
        }),
    }
    function handleStateChange(e) {
        setState(e) // Assuming `setState` updates the state with selected options

        let dist = []
        e?.forEach((stateOption) => {
            if (allDistricts[stateOption.value]) {
                // Check if districts exist for selected state
                dist.push(
                    ...allDistricts[stateOption.value]?.map((district) => ({
                        label: district.name,
                        value: district.district_id,
                    }))
                )
            }
        })
        setSelectedDistricts(dist)
        let consti = []
        e?.forEach((stateOption) => {
            if (allDistricts[stateOption.value]) {
                // Check if districts exist for selected state
                consti.push(
                    ...allConstituencies[stateOption.value]?.map((cons) => ({
                        label: cons.name,
                        value: cons.assembly_constituency_id,
                    }))
                )
            }
        })
        setSelectedConstituncies(consti)
    }

    return (
        <div className="padding-20">
            <h3 className="heading">Edit Employee</h3>
            <div className="padding-20">
                <Form
                    id="form"
                    form={form}
                    name="employeeForm"
                    scrollToFirstError
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{
                        status: 'active',
                        firstName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        role: '',
                        languages: '',
                        password: '',
                        reportsTo: '',
                        is_available:true
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Last Name"
                                name="lastName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter Last name',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        type: 'email',
                                        message: 'Please enter valid email',
                                    },
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Mobile"
                                name="mobile"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter mobile',
                                    },
                                    {
                                        pattern: /^[\d]{10}$/,
                                        message:
                                            'Mobile Number should be 10 digits',
                                    },
                                ]}
                                validateTrigger="onBlur"
                            >
                                <Input type="string" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label="Role"
                                name="role"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select role',
                                    },
                                ]}
                            >
                                <Select>
                                    {allRoles?.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.role_id}
                                        >
                                            {option.role_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="Language(s)"
                                name="languages"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select language',
                                    },
                                ]}
                            >
                                <Select mode="multiple">
                                    {languageData.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.language_id}
                                        >
                                            {option.language_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        {mode === 'add' && (
                            <Col span={12}>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter password',
                                        },
                                    ]}
                                >
                                    <Input.Password />
                                </Form.Item>
                            </Col>
                        )}

                        <Col span={12}>
                            <Form.Item
                                label="Reports To"
                                name="reportsTo"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Select>
                                    {allEmployees?.map((employee, index) => (
                                        <Select.Option
                                            key={index}
                                            value={employee.employee_id}
                                        >
                                            {employee.employee_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Is Available" name="is_available">
                                <Radio.Group>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Shift Name" name="shift_name">
                            <Select placeholder='select employee shift'>
                                {shifts?.map((e)=><Option value={e.shift_id} key={e.shift_id} >{e.shift_name}</Option>)}
                               </Select>
                            </Form.Item>
                        </Col>
                        {/* <Col span={12}>
                            <Form.Item label="Shift Id" name="shift_id">
                                <Input type="number"></Input>
                            </Form.Item>
                        </Col> */}
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="Status" name="status">
                                <Radio.Group>
                                    <Radio value="active"> Active </Radio>
                                    <Radio value="inactive"> Inactive </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item label="State" name="state">
                                <ReactSelect
                                    onChange={(e) => handleStateChange(e)}
                                    options={allStates}
                                    placeholder="Select state"
                                    isClearable
                                    isMulti
                                    styles={customStyles}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item label="District" name="district">
                                <ReactSelect
                                    options={selectedDistricts}
                                    placeholder="Select District"
                                    isClearable
                                    isMulti
                                    styles={customStyles}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={20}>
                            <Form.Item
                                label="Assembly Constituency"
                                name="constituency"
                            >
                                <ReactSelect
                                    options={selectedConstituencies}
                                    placeholder="Select constituency"
                                    isClearable
                                    isMulti
                                    styles={{
                                        ...customStyles,
                                        container: (provided) => ({
                                            ...provided,
                                            width: 333,
                                        }),
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default EditEmployee
