import { useEffect, useState } from 'react'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import AddShortNewsForm from './AddShortNewsForm/AddShortNewsForm'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getShortNewsByContentId } from '../../AadhanApi'
import { Button, message } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'

const AddShortNews = () => {
    const [messageApi, contextHolder] = message.useMessage()
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [imagePath, setImagePath] = useState('')
    const [videoPath, setVideoPath] = useState()
    const location = useLocation()
    const [hashTagId, setHashTagId] = useState()
    const [sourceId, setSourceId] = useState()
    const [code, setCode] = useState('')
    const [contentStatus, setContentStatus] = useState('')
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const mode = searchparam.get('mode')
    const languageData = useSelector((state) => state.app.languageData)
    const categories = useSelector((state) => state.app.categories)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [pro,setPro] = useState('');
    const [anti,setAnti] = useState('');
    const [otherLink, setOtherLink] = useState('')
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const navigate = useNavigate()

    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        breakingNews: false,
        disturbingVisuals: 'no',
        publish: dayjs(),
        locationSpecific: 'no',
        proofRead: '',
        expiryHours: 'Never',
        gender: 'all',
        media: 'youtubeLink',
    })

    const getMedia = (response) => {
        if (response?.stream_video_url !== '') {
            return 'video'
        } else if (response?.yt_video_url !== '') {
            return 'youtubeLink'
        } else {
            return 'otherLink'
        }
    }

    const updateFormData = async (contentId, accessToken) => {
        try {
            const response = await getShortNewsByContentId(
                contentId,
                accessToken
            )
            if (response?.data) {
                setLanguageOption(response?.data?.[0]?.language_id,)
                setCode(response?.data[0].code)
                setImagePath(response?.data?.[0]?.image_url)
                if (response?.data?.[0]?.stream_video_url !== '') {
                    setVideoPath(response?.data?.[0]?.stream_video_url)
                } else if (response?.data?.[0]?.yt_video_url !== '') {
                    setVideoPath(response?.data?.[0]?.yt_video_url)
                }
                if(response?.data?.[0]?.sub_category_id !== '' && response?.data?.[0]?.sub_category_id!==undefined && response?.data?.[0]?.sub_category_id!==null){
                    setPro(response?.data?.[0]?.sub_category_id)
                }
                if(response?.data?.[0]?.anti_sub_category_id !== '' && response?.data?.[0]?.anti_sub_category_id!==undefined && response?.data?.[0]?.anti_sub_category_id!==null){
                    setAnti(response?.data?.[0]?.anti_sub_category_id)
                }
                
                setTitle(response?.data?.[0]?.title)
                setDescription(response?.data?.[0]?.description)
                setHashTagId(response?.data?.[0]?.hashtag_id)
                setSourceId(response?.data?.[0]?.source_id)
                setContentStatus(response?.data?.[0]?.content_status)
                setInitialFormData({
                    ...initialFormData,
                    streamVideoPath: response?.data?.[0]?.stream_video_url,
                    title: response?.data?.[0]?.title,
                    otherLink:
                        response?.data?.[0]?.mp4_video_url === ''
                            ? ''
                            : response?.data?.[0]?.mp4_video_url,
                    language: response?.data?.[0]?.language_id,
                    breakingNews: response?.data?.[0]?.breaking_news,
                    relevancy: response?.data?.[0]?.relevancy,
                    category: response?.data?.[0]?.category_id,
                    disturbingVisuals: response?.data?.[0]?.disturbing_visuals,
                    media: getMedia(response?.data?.[0]),
                    publish: dayjs(
                        dayjs(response?.data?.[0]?.published_date).format(
                            'YYYY-MM-DD HH:mm:ss'
                        )
                    ),
                    gender: response?.data?.[0]?.gender,
                    locationSpecific:
                        response?.data?.[0]?.state !== '' ? 'yes' : 'no',
                    expiryHours:
                        response?.data?.[0]?.expiry_hours == 0
                            ? 'Never'
                            : response?.data?.[0]?.expiry_hours,
                    shortDescription: response?.data?.[0]?.description,
                    district_id: response?.data?.[0]?.district?.split(','),
                    districts: response?.data?.[0]?.district_name,
                    constituency_ids: response?.data?.[0]?.constituency_ids,
                    assemblyconstituencies:
                        response?.data?.[0]?.constituency_names,
                    mandal_id: response?.data?.[0]?.mandal?.split(','), 
                    mandal: response?.data?.[0]?.mandal_name,
                    subcategory: response?.data?.[0]?.sub_category_name,
                    subcategory_id: response?.data?.[0]?.sub_category_id,
                    r2_video_url:response?.data?.[0]?.r2_video_url,
                    youtubeLink:
                        response?.data?.[0]?.yt_video_url === ''
                            ? ''
                            : response?.data?.[0]?.yt_video_url,
                    source: response?.data?.[0]?.source_id,
                    sourceLink: response?.data?.[0]?.source_url,
                    proofRead:
                        response?.data?.[0]?.content_status !== 'draft' &&
                        response?.data?.[0]?.content_status !== 'submit'
                            ? response?.data?.[0]?.is_proof_read
                            : '',
                    image: response?.data?.[0].image_url,
                    state_id: response?.data?.[0]?.state?.split(','),
                    state: response?.data?.[0]?.state_name,
                    keywords: response?.data?.[0]?.keywords,
                    uploadedBy: response?.data?.[0]?.uploaded_by,
                    uploadedByName: response?.data?.[0]?.uploaded_by_name,
                    uploadedDate: response?.data?.[0]?.uploaded_date,
                    is_celebration: response?.data?.[0]?.is_celebration,
                })
            }
        } catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get Short news Data from content Id',
                duration: 5,
            })
        }
    }

    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }

    const descriptionChange = (newDesc) => {
        const inputLines = newDesc.split('\n')?.length
        if (inputLines <= 9) {
            setDescription(newDesc)
        }
    }

    return (
        <div style={{ padding: '0 1rem' }}>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h3>{mode === 'edit' ? 'Edit Short News' : 'Add Short'}</h3>
                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{ color: 'LightCoral', fontSize: '1.8rem' }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>

            <div className="add-ad-container row">
                <div className="col-md">
                    <AddShortNewsForm
                        setImagePath={setImagePath}
                        descriptionChange={descriptionChange}
                        titleChange={titleChange}
                        initialFormData={initialFormData}
                        languageData={languageData}
                        categories={categories}
                        accessToken={accessToken}
                        mode={mode}
                        contentId={contentId}
                        setVideoPath={setVideoPath}
                        videoPath={videoPath}
                        setHashTagId={setHashTagId}
                        hashTagId={hashTagId}
                        sourceId={sourceId}
                        setSourceId={setSourceId}
                        code={code}
                        contentStatus={contentStatus}
                        setOtherLink={setOtherLink}
                        languageOption={languageOption}
                        setLanguageOption={setLanguageOption}
                        pro={pro}
                        anti={anti}
                    />
                </div>
                <div className="col-md">
                    <SmartphonePreview
                        image={imagePath}
                        title={title}
                        videoPath={videoPath}
                        description={description}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddShortNews
