import { Tag } from 'antd'

export const ActiveOptions = [
    {
        value: 'active',
        label: 'Active',
    },
    {
        value: 'inactive',
        label: 'Inactive',
    },
    {
        value: 'all',
        label: 'All',
    },
]

export const platformOptions = [
    {
        label: 'Android',
        value: 'android',
    },
    {
        label: 'IOS',
        value: 'ios',
    },
]

export const ApprovalOptions = [
    {
        value: 'approved',
        label: 'Approved',
    },
    {
        value: 'draft',
        label: 'Draft',
    },
    {
        value: 'submit',
        label: 'Submitted',
    },
    {
        value: 'all',
        label: '--All--',
    },
]

export const statusOptions = [
    {
        value: 'submit',
        label: 'Submit',
    },
    {
        value: 'approved',
        label: 'Approved',
    },
    {
        value: 'rejected',
        label: 'Rejected',
    },
]

export const ContentDateOptions = [
    {
        value: 'today',
        label: 'Today',
    },
    {
        value: 'yesterday',
        label: 'Yesterday',
    },
    {
        value: 'currentWeek',
        label: 'Current Week',
    },
    {
        value: 'lastMonth',
        label: 'Last Month',
    },
]

export const AddTypeOptions = [
    {
        value: 'all',
        label: 'All',
    },
    {
        label: 'Short News',
        value: 'shortnews',
    },
    {
        label: 'HTML',
        value: 'html',
    },
    {
        label: 'Short Video',
        value: 'shortvideo',
    },
    {
        label: 'Card',
        value: 'card',
    },
]

export const typeOptions = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'paid',
        label: 'Paid',
    },
    {
        value: 'unpaid',
        label: 'Unpaid',
    },
]

export const ugctypeOptions = [
    {
        value: true,
        label: 'Reporter',
    },
    {
        value: false,
        label: 'Stringer',
    },
]

export const getProofReadSymbol = (tag) => {
    return <Tag key={tag}>{tag.toUpperCase()}</Tag>
}

export const modalRoutes = [
    '/home/languages',
    '/home/hashtags',
    '/home/categories',
    '/home/subcategories',
    '/home/sources',
    '/home/features',
]

export const daysOptions = [
    {
        label: 'Monday',
        value: 1,
    },
    {
        label: 'Tuesday',
        value: 2,
    },
    {
        label: 'Wednesday',
        value: 3,
    },
    {
        label: 'Thursday',
        value: 4,
    },
    {
        label: 'Friday',
        value: 5,
    },
    {
        label: 'Saturday',
        value: 6,
    },
    {
        label: 'Sunday',
        value: 7,
    },
]

export const months = [
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' },
]
