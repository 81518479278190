import getApiResponse from "../openAi";
import TransliterateComponent from "./TransliterateComponent";
export default async function generateKeyWord(title, description,lang) {
  const message = {
    model: 'gpt-4o-mini',
    messages: [
      {
        role: 'system',
        content:
          'You are a helpful assistant that extracts 6 main searchable non-repeating keywords in the original and english. Focus on high-level concepts like location, age, diseases, specific actions, and key entities relevant for search. Avoid overly generic words like "hair" or "treatment". Return the results in JSON format with two keys: "original" and "english" for the original and english language keywords. Each keyword should have at least 3 visual characters.',
      },
      {
        role: 'user',
        content: `Extract keywords in JSON format from the following title and content: 
                    Title: "${title}"
                    Content: "${description}"
                    Provide the results in the following format: 
                    {
                        "original": ["keyword1", "keyword2", ...],
                         "english": ["keyword1", "keyword2", ...]
                    }`,
      },
    ],
    max_tokens: 200,
    n: 1,
    stop: null,
    temperature: 0.1,
  };
  if(title===undefined || description === undefined || lang===undefined){
    return ''
  }
  const response = await getApiResponse(message);
  if (response) {
    const cleanedInput = response?.replace(/```json|```/g, '').trim();
    // Match the JSON object in the response
    const jsonMatch = cleanedInput?.match(/\{(?:[^{}]*|"(?:\\.|[^"\\])*"|\{(?:[^{}]*|"(?:\\.|[^"\\])*")*\})*\}/);
    let trans=[]
    let filteredOriginal=[]
    let english=[]
    if (jsonMatch) {
      const jsonString = jsonMatch?.[0];
      try {
        const jsonObject = JSON.parse(jsonString);
        // Filter out keywords with less than 3 visual characters in both original and English arrays
        filteredOriginal = jsonObject?.original?.filter((word) => word?.replace(/[^\u0B80-\u0BFF\u0900-\u097F\u0C00-\u0C7F]/g, '')?.length >= 3);
        english = jsonObject?.english;
        // Rebuild the filtered JSON object with valid keywords
        // Replace diacritic characters with their base Latin counterparts
        const language = lang === 1 ? 'english' : lang===2 ? 'telugu' : lang === 3 ? 'devanagari' : 'tamil'    
        trans = filteredOriginal?.map((e)=>TransliterateComponent(e,language,language==='devanagari'?'hk':'itrans')?.toLocaleLowerCase()); 
        let filteredJsonObject = [
         ...filteredOriginal,
         ...english,
         ...trans
        ];
        
        return filteredJsonObject?.join(',');

      } catch (error) {
        console.error("transliteration error:", error);
        return [...filteredOriginal, ...english].join(',');
      }
    } else {
      console.log("No JSON object found.");
      return "";
    }
  }
}
