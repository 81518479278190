import { Button, DatePicker, Select } from 'antd'
import { Option } from 'antd/es/mentions'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import './video.scss'
import { getVideos } from '../../AadhanApi'
import dayjs from 'dayjs'
import { DownloadOutlined, Loading3QuartersOutlined } from '@ant-design/icons'
import Search from 'antd/es/input/Search'
import VideoPlayerWithThumbnail from './VideoPlayerWithThumbnail'
const VideoLibrary = () => {
    const token = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [language, setLanguage] = useState(2)
    const [date, setDate] = useState(dayjs(new Date()))
    const [video, setVideo] = useState([])
    const [searchedVideo, setSearchedVideo] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const [search, setSearch] = useState('')
    const getVideo = async () => {
        setDataLoading(true)
        const res = await getVideos(
            language,
            date.format('YYYY-MM-DD'),
            token
        )
        setVideo(res?.data?.data)
        setSearchedVideo(res?.data?.data)
        setSearch('')
        setDataLoading(false)
    }

    useEffect(() => {
        getVideo()
    }, [date, language])

    const handleDownload = (url) => {
        const a = document.createElement('a')
        a.href = url
        a.download = 'video.mp4'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }
    const handleSearch = (e) => {
        setDataLoading(true)
        const filtered = video.filter(
            (video) =>
                video?.title.toLowerCase().includes(e) ||
                video?.keywords.toLowerCase().includes(e)
        )
        setSearchedVideo(filtered)
        setDataLoading(false)
    }
    return (
        <div style={{ padding: '0 1rem 1rem 1rem' }}>
            <div className="filters">
                <Select
                    className="filter"
                    value={language}
                    onChange={(e) => setLanguage(e)}
                    style={{ width: 200 }}
                    placeholder="Select language"
                >
                    {languageData?.map((option) => {
                        return (
                            <Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Option>
                        )
                    })}   
                </Select>
                <DatePicker
                    className="filter"
                    format="YYYY/MM/DD"
                    value={date}
                    allowClear={false}
                    onChange={(e) => setDate(e)}
                    disabledDate={(currentDate) => currentDate > new Date()}
                />
                <Search
                    style={{ width: '270px' }}
                    placeholder="Search by title or keywords..."
                    allowClear
                    enterButton="Search"
                    size="medium"
                    onSearch={handleSearch}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                ></Search>
                <Button
                    onClick={getVideo}
                    type="primary"
                    style={{ marginLeft: 'auto' }}
                    loading={dataLoading}
                    icon={dataLoading ? '' : <Loading3QuartersOutlined />}
                    ghost
                >
                    Refresh
                </Button>
            </div>
            <hr></hr>
            {dataLoading && (
                <div
                    style={{
                        height: '60vh',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            )}
            <div className="video_container">
                {!dataLoading &&
                    searchedVideo.length !== 0 &&
                    searchedVideo?.map((e, index) => {
                        return (
                            <div className="video_box" key={index}>
                                <div className="video">
                                    <VideoPlayerWithThumbnail  videoUrl={e?.r2_video_url}  thumbnailUrl={e.image_url}   />
                                </div>
                                <div className="video_title_container">
                                    <div className="video_title">
                                        {e?.title}
                                    </div>
                                    <Button
                                        type="primary"
                                        className="download_btn"
                                        onClick={(event) => {
                                            event.preventDefault()
                                            event.stopPropagation() // Prevents interaction with the video player itself
                                            handleDownload(e?.r2_video_url) // Call download with video URL
                                        }}
                                        icon={<DownloadOutlined />}
                                    ></Button>
                                </div>
                            </div>
                        )
                    })}
            </div>
            {searchedVideo.length === 0 && !dataLoading && (
                <div className="no_data">No Videos</div>
            )}
        </div>
    )
}

export default VideoLibrary
