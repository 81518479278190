import React, { useEffect, useState } from 'react'
import './Reporter.scss'
import { getReporterTableData } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { Button, Input, message, Popover, Select } from 'antd'
import { useLocation, useNavigate } from 'react-router'
import { EditOutlined } from '@ant-design/icons'
import CommonTableWithBorder from '../CommonTable/CommonTableWithBorder'
import ExcelSheetUI from './ExcelSheetUI'
import { Option } from 'antd/es/mentions'
import './Reporter.scss'
import Search from 'antd/es/input/Search'
const EditTVStringers = () => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const location = useLocation()
    const [dataLoading, setDataLoading] = useState(false)
    const [mobileNumber, setMobileNumber] = useState('')
    const locations = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allMandals = useSelector((state) => state.app.mandals)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const [districtData, setDistrictData] = useState([])
    const [typeOption, setTypeOption] = useState('paid')
    const [state, setState] = useState(undefined)
    const [district, setDistrict] = useState(undefined)
    const [mobile, setMobile] = useState('')
    const [name, setName] = useState('')
    const [visible, setVisible] = useState(false)
    const getStringerDetail = async () => {
        const data = await getReporterTableData(
            'all',
            mobileNumber,
            '',
            accessToken,
            '',
            '',
            undefined
        )
        if (data.data.length === 0) {
            return 'Stringer not found'
        } else if (
            data.data[0].stringer_type === 2 ||
            !data.data[0].stringer_type
        ) {
            return data?.data?.[0]?.user_id
        } else {
            return 'User is already assigned to the app stringers'
        }
    }
    const handleStateChange = (event) => {
        setDistrict(undefined)
        const districts = allDistricts?.[event]
        setDistrictData(districts)
        setState(event)
    }
    async function getData() {
        setDataLoading(true)
        const data = await getReporterTableData(
            typeOption,
            mobile,
            name,
            accessToken,
            state,
            district,
            2
        )
        setData(data?.data)
        setDataLoading(false)
    }
    useEffect(() => {
        getData()
    }, [location.pathname, state, district])

    const handleMobileInput = (e) => {
        const value = `${e.target.value}`.slice(0, 10)
        setMobileNumber(value)
    }

    const handleNext = async () => {
        if (mobileNumber.length === 10) {
            const res = await getStringerDetail()
            if (res === 'Stringer not found') {
                message.error(res)
            } else if (
                res === 'User is already assigned to the app stringers'
            ) {
                message.warning(res)
            } else {
                message.success('Stringer found')
                navigate(`edit?mode=edit&reporterId=${res}`)
            }
        } else {
            message.error('Mobile number must be 10 digits')
        }
    }

    const handleCancel = () => {
        setVisible(false)
        setMobileNumber('')
    }
    const reportersTableColumns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            align: 'center',
        },
        {
            title: 'Constituency',
            dataIndex: 'constituency',
            key: 'constituency',
            align: 'center',
            render: (_, record) => (
                <div>
                    {record?.reporter_information?.constituencies?.join(' ')}
                </div>
            ),
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            render: (_, record) => (
                <div>{record?.reporter_information?.state}</div>
            ),
        },
        {
            title: 'Mobile number',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            navigate(
                                `edit?mode=edit&reporterId=${record?.user_id}`
                            )
                        }}
                    />
                </div>
            ),
        },
    ]

    const popoverContent = (
        <div>
            <Input
                placeholder="Enter mobile number"
                value={mobileNumber}
                className="ant-input-number"
                onChange={handleMobileInput}
                onPressEnter={handleNext}
                type="number"
            />
            <div
                style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'flex-start',
                }}
            >
                <Button onClick={handleCancel} style={{ marginRight: '10px' }}>
                    Cancel
                </Button>
                <Button type="primary" onClick={handleNext}>
                    Next
                </Button>
            </div>
        </div>
    )

    return (
        <div>
            <div className="title_block">
                <h5 className="title">Manage TV Stringers</h5>
                <Popover
                    content={popoverContent}
                    title="Add/Edit TV Stringers"
                    trigger="click"
                    visible={visible}
                    onVisibleChange={(visible) => setVisible(visible)}
                >
                    <Button type="primary">Add/Edit TV Stringers</Button>
                </Popover>
            </div>
            <hr></hr>
            <div className="edit_tv">
                <div style={{ display: 'flex' }}>
                    <Select
                        allowClear
                        placeholder="Select State"
                        value={state}
                        showSearch
                        onChange={(e) => handleStateChange(e)}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 150, marginRight: '2rem' }}
                    >
                        {locations.map((option, index) => {
                            return (
                                <Option
                                    key={option.state_id}
                                    value={option.state_id}
                                >
                                    {option?.name}
                                </Option>
                            )
                        })}
                    </Select>
                    <Select
                        value={district === undefined ? undefined : district}
                        allowClear
                        placeholder="Select District"
                        onChange={(e) => setDistrict(e)}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option?.children
                                ?.toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: 170, marginRight: '1rem' }}
                    >
                        {districtData?.map((option, index) => {
                            return (
                                <Option
                                    key={option?.district_id}
                                    value={option?.district_id}
                                >
                                    {option?.name}
                                </Option>
                            )
                        })}
                    </Select>
                    <Search
                        className="custom-search"
                        placeholder="search by name"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onChange={(e) => setName(e.target.value)}
                        onSearch={() => getData()}
                        value={name}
                        style={{ width: 250 }}
                    />

                    <Search
                        placeholder="search by mobile number"
                        enterButton="Search"
                        size="middle"
                        allowClear
                        onChange={(e) => {
                            const value = e.target.value.slice(0, 10) // Ensure only 10 digits

                            setMobile(value)
                        }}
                        onSearch={() => getData()}
                        value={mobile}
                        style={{ width: 250 }}
                        type="number"
                    />
                </div>
                <ExcelSheetUI
                    data={data}
                    dataLoading={dataLoading}
                    allConstituencies={allConstituencies}
                    locations={locations}
                    accessToken={accessToken}
                    allDistricts={allDistricts}
                    allMandals={allMandals}
                />
            </div>
        </div>
    )
}

export default EditTVStringers
