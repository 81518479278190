import React, { useEffect, useState } from 'react'
import {
    Button,
    Form,
    Input,
    message,
    notification,
    Popover,
    Select,
} from 'antd'
import './dashboard.scss'
import { BellOutlined } from '@ant-design/icons'
import { reassignContent } from '../../AadhanApi'
import { useSelector } from 'react-redux'
const ReassignModal = ({ user, employees, getEmployeeData, content }) => {
    const [custom, setCustom] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const handleOk = async () => {
        const res = await reassignContent(accessToken, user?.user_id, true)

        setIsModalOpen(false)
        if (res?.data?.success === true) {
            notification.open({
                message: 'Notification',
                description: (
                    <div>
                        <span
                            style={{
                                color: 'green',
                                fontSize: '1rem',
                            }}
                        >
                            {user?.name}
                        </span>{' '}
                        {`'s all content has been successfully reassigned.`}
                    </div>
                ),
                icon: <BellOutlined style={{ color: '#1890ff' }} />,
                style: {
                    borderRadius: '8px',
                    backgroundColor: '#f0f9ff',
                },
            })
            getEmployeeData()
        } else {
            message.error('Failed To Reassign Contents')
        }
    }
    const handleCancel = async () => {
        const res = await reassignContent(accessToken, user?.user_id, false)
        setIsModalOpen(false)
        if (res?.data?.success === true) {
            notification.open({
                message: 'Notification',
                description: (
                    <div>
                        <span
                            style={{
                                color: 'green',
                                fontSize: '1rem',
                            }}
                        >
                            {user?.name}
                        </span>{' '}
                        {`'s deadline contents has been successfully reassigned.`}
                    </div>
                ),
                icon: <BellOutlined style={{ color: '#1890ff' }} />,
                style: {
                    borderRadius: '8px',
                    backgroundColor: '#f0f9ff',
                },
            })
            getEmployeeData()
        } else {
            message.error('Failed To Reassign Contents')
        }
    }
    useEffect(()=>{
        setCustom(false)
    },[isModalOpen])
    const onFinish = (value) => {
        console.log(value)
        // setIsModalOpen(false)
        // setCustom(false)
    }
    return (
        <>
            <Popover
                content={
                    <div>
                        {!custom ? (
                            <div style={{ display: 'flex' }}>
                                <Button
                                    style={{ marginRight: '1rem' }}
                                    type="primary"
                                    onClick={handleOk}
                                >
                                    Yes
                                </Button>
                                <Button
                                    style={{ marginRight: '1rem' }}
                                    type="primary"
                                    ghost
                                    onClick={handleCancel}
                                >
                                    No
                                </Button>
                                {/* <Button
                                    type="primary"
                                    onClick={() => setCustom(true)}
                                    style={{ backgroundColor: 'orange' }}
                                >
                                    Custom
                                </Button> */}
                            </div>
                        ) : (
                            <div>
                                <Form
                                    labelWrap
                                    labelCol={{ span: 10 }}
                                    wrapperCol={{ span: 13 }}
                                    onFinish={onFinish}
                                    
                                >
                                    <Form.Item
                                        label="Total Pending"
                                        name="total"
                                    >
                                        <Input disabled style={{color:"blue"}} defaultValue={content} />
                                    </Form.Item>
                                    <Form.Item
                                        required={true}
                                        style={{marginTop:"-5%"}}
                                        label="Reassign Count"
                                        name="reassignCount"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    '',
                                            },
                                            {
                                                type: 'number',
                                                min: 1,
                                                max: content,
                                                message: `Count > 1 & <= ${content}`,
                                                transform: (value) =>
                                                    Number(value),
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Enter Reassign Count" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Employeee"
                                        name="employee"
                                        rules={[
                                            {
                                                required: 'true',
                                                message:
                                                    'Please Select Employee',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select Employee">
                                            {employees?.map((e) => {
                                                if (
                                                    user?.user_id !==
                                                    e.employee_id
                                                ) {
                                                    return (
                                                        <Select.Option
                                                            value={
                                                                e?.employee_id
                                                            }
                                                            key={e?.employee_id}
                                                        >
                                                            {e?.employee_name}
                                                        </Select.Option>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label=" " >
                                        <Button htmlType="submit" type="primary">Reassign</Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        )}
                    </div>
                }
                title={
                    <div>
                        {!custom ? (
                            <>
                                <div>
                                    {
                                        'Click Yes to deactivate the user and reassign all, or'
                                    }
                                </div>
                                <div>
                                    {
                                        'No to reassign only contents with crossed deadlines.'
                                    }
                                </div>
                            </>
                        ) : (
                            <>Custom Reassign</>
                        )}
                    </div>
                }
                trigger="click"
                visible={isModalOpen}
                onVisibleChange={(visible) => !visible && setIsModalOpen(false)}
            >
                <Button
                    disabled={content <= 0}
                    type="primary"
                    onClick={() => setIsModalOpen(true)}
                >
                    Reassign
                </Button>
            </Popover>
            {/* <Button disabled={ content===0 ? true : false } type="primary" onClick={showModal}>
        Reassign
      </Button>
      <Modal okText='Assign' footer={null} className='reassign-modal' title={<span style={{color:"gray",fontSize:"1.2rem",textAlign:"center"}}>{"Reassign Content"}</span>} width={700} open={isModalOpen} onCancel={handleCancel}>
        <TransferList date={date} getEmployeeData={getEmployeeData} user={user} setIsModalOpen={setIsModalOpen} language_id={language_id} isModalOpen={isModalOpen} showModal={showModal} employee={employee}/>
      </Modal> */}
        </>
    )
}
export default ReassignModal
