import React, { useState, useEffect, useMemo } from 'react'
import {
    Button,
    Select,
    Modal,
    Form,
    Input,
    TimePicker,
    DatePicker,
    Popconfirm,
    message,
    Upload,
    Switch,
} from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'
import './ManageCategory.scss'
import CommonTable from '../CommonTable/CommonTable'
import {
    getVisualsCategory,
    createVisualsCategories,
    getVisualsByCategory,
    updateVisualsByCategory,
    deleteVisualsByCategory,
    getCoverPageImageUrl,
} from '../../AadhanApi'
import {
    EditOutlined,
    DeleteOutlined,
    CloudUploadOutlined,
} from '@ant-design/icons'
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'

const Category = () => {
    const [language, setLanguage] = useState(1)
    const languageData = useSelector((state) => state.app.languageData)
    const [data, setData] = useState([])
    const [form] = Form.useForm()
    const [categoryName, setCategoryName] = useState()
    const [categoryId, setCategoryId] = useState()
    const [fileList, setFileList] = useState([])
    const [modalTitle, setModalTitle] = useState('Add Category')
    const [editingRecord, setEditingRecord] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [selectedFrequency, setSelectedFrequency] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [mode, setMode] = useState('')
    const [filteredData, setFilteredData] = useState([])
    const [formValues, setFormValues] = useState({})
    const [currentLanguage, setCurrentLanguage] = useState(null)
    const [localName, setLocalName] = useState('')
    const [selectedType, setSelectedType] = useState('All')
    const { Dragger } = Upload
    const [isRecurring, setIsRecurring] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const navigate = useNavigate()
    const getCategoryData = async (accessToken) => {
        try {
            const response = await getVisualsCategory(accessToken, language)
            console.log('Category data recieved:', response?.data?.data)
            return response?.data?.data || []
        } catch (err) {
            console.error('Error fetching category data:', err)
            message.error('Failed to fetch category data')
            throw err
        }
    }

    const onFinish = async (formValues) => {
        if (
            !isCategoryNameUnique(
                formValues.category_name,
                formValues.language_id
            )
        ) {
            message.error('Category name already exists for this language')
            return
        }
        const payloadData = {
            language_id: formValues.language_id,
            category_name: formValues.category_name,
            local_name: formValues.local_name,
            is_daily: selectedFrequency === 'daily' && isRecurring === 'yes',
            is_weekly: selectedFrequency === 'weekly' && isRecurring === 'yes',
            is_monthly:
                selectedFrequency === 'monthly' && isRecurring === 'yes',
            is_date_specific:
                selectedFrequency === 'date_specific' && isRecurring === 'yes',
            day_of_week: 0,
            day_of_month: 0,
            month_of_year: 0,
            start_time: null,
            end_time: null,
            cover_page:
                fileList.length > 0
                    ? fileList[0]?.response?.data ||
                      fileList[0].response?.url ||
                      fileList[0].url
                    : editingRecord?.cover_page?.data,
        }
        console.log(payloadData)

        if (selectedFrequency === 'daily' && isRecurring === 'yes') {
            payloadData.start_time = formValues.start_time_of_day.format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            payloadData.end_time = formValues.end_time_of_day.format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
        } else if (selectedFrequency === 'weekly' && isRecurring === 'yes') {
            payloadData.day_of_week = parseInt(formValues.sday)
            payloadData.start_time = formValues.start_time_of_day.format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            payloadData.end_time = formValues.end_time_of_day.format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
        } else if (selectedFrequency === 'monthly' && isRecurring === 'yes') {
            payloadData.day_of_month = parseInt(formValues.date)
            payloadData.start_time = formValues.start_time_of_day.format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            payloadData.end_time = formValues.end_time_of_day.format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
        } else if (
            selectedFrequency === 'date_specific' &&
            isRecurring === 'yes'
        ) {
            const dateFormat = 'YYYY-MM-DD'
            const timeFormat = 'HH:mm:ss.SSSZ'
            payloadData.start_time = `${formValues.specificdate.format(
                dateFormat
            )}T${formValues.start_time_of_day.format(timeFormat)}`
            payloadData.end_time = `${formValues.specificdate.format(
                dateFormat
            )}T${formValues.end_time_of_day.format(timeFormat)}`
            payloadData.day_of_month = form.getFieldValue('specificdate').date()
            payloadData.month_of_year =
                form.getFieldValue('specificdate').month() + 1
        }

        try {
            if (mode === 'edit') {
                await updateVisualsByCategory(
                    categoryId,
                    payloadData,
                    accessToken
                )
                message.success('Category updated successfully')
            } else {
                await createVisualsCategories(payloadData, accessToken)
                message.success('Category created successfully')
            }
            setIsModalOpen(false)
            const updateData = await getCategoryData(accessToken)
            setData(updateData)
            setFilteredData(
                updateData.filter((item) => item.language_id === language)
            )
            form.resetFields()
            setFileList([])
        } catch (err) {
            console.error('Error:', err)
            message.error(
                `Failed to ${mode === 'edit' ? 'update' : 'create'} category`
            )
        }
    }
    const handleChange = ({ fileList: newFileList }) => {
        const updatedFileList = newFileList.slice(-1)
        setFileList(updatedFileList)
        if (
            updatedFileList.length > 0 &&
            updatedFileList[0].status === 'done'
        ) {
            const coverPageUrl =
                `${updatedFileList[0].url}?${new Date().getTime()}` ||
                `${updatedFileList[0].response.url}?${new Date().getTime()}` ||
                `${updatedFileList[0].thumb.url}?${new Date().getTime()}`

            setFormValues((prevValues) => ({
                ...prevValues,
                cover_page: coverPageUrl,
            }))

            form.setFieldsValue({
                cover_page: coverPageUrl,
            })
        } else {
            setFormValues((prevValues) => ({ ...prevValues, cover_page: null }))
            form.setFieldsValue({ cover_page: undefined })
        }
    }

    const isCategoryNameUnique = (categoryName, languageId) => {
        return !data.some(
            (item) =>
                item.category_name.toLowerCase() ===
                    categoryName.toLowerCase() &&
                item.language_id === languageId &&
                (mode === 'add' ||
                    (mode === 'edit' && item.category_id !== categoryId))
        )
    }
    const filterData = (lang, type, dataToFilter = data) => {
        let filtered = dataToFilter.filter((item) => item.language_id === lang)
        if (type !== 'All') {
            filtered = filtered.filter((item) => {
                if (type === 'Daily') return item.is_daily
                if (type === 'Weekly') return item.is_weekly
                if (type === 'Monthly') return item.is_monthly
                if (type === 'Date Specific') return item.is_date_specific
                return false
            })
        }
        setFilteredData(filtered)
    }

    useEffect(() => {
        if (languageData && languageData.length > 0) {
            const englishLanguage = languageData.find(
                (lang) => lang.language_name.toLowerCase() === 'english'
            )
            const initialLanguage = englishLanguage
                ? englishLanguage.language_id
                : languageData[0].language_id
            setLanguage(initialLanguage)
        }
    }, [languageData])

    useEffect(() => {
        if (isModalOpen && language) {
            form.setFieldsValue({ language_id: language })
        }
    }, [language, isModalOpen, form])

    useEffect(() => {
        const fetchData = async () => {
            if (accessToken && language) {
                const fetchedData = await getCategoryData(accessToken)
                console.log('Fetched data:', fetchData)
                setData(fetchedData)
                filterData(language, selectedType, fetchedData)
            }
        }
        fetchData()
    }, [accessToken, language, selectedType])

    useEffect(() => {
        if (editingRecord) {
            form.setFieldsValue({
                cover_page: editingRecord.cover_page,
                local_name: editingRecord.local_name,
            })
            setLocalName(editingRecord.local_name)
            if (editingRecord.cover_page) {
                setFileList([
                    {
                        uid: '-1',
                        name: 'cover_page.png',
                        status: 'done',
                        url: `${
                            editingRecord.cover_page
                        }?${new Date().getTime()}`,
                    },
                ])
            } else {
                setFileList([])
            }
        }
    }, [editingRecord, isModalOpen])

    const draggerProps = {
        name: 'file',
        multiple: false,
        accept: 'image/jpeg,image/png',
        onRemove: () => {
            setFileList([])
            form.setFieldsValue({ cover_page: undefined })
        },
        customRequest: ({ file, onSuccess }) => {
            setIsLoading(true)

            getCoverPageImageUrl(file, categoryName, accessToken, language)
                .then((pathUriArr) => {
                    setIsLoading(false)
                    if (pathUriArr) {
                        const path = pathUriArr
                        setFileList([{ ...file, url: path }])
                        onSuccess(path)
                    }
                })
                .catch((error) => {
                    console.log(error)
                    setIsLoading(false)
                })
        },
    }

    const showModal = async (record = null) => {
        if (record.length !== 0) {
            setModalTitle('Edit Category')
            setEditingRecord(record)
            setMode('edit')
            if (
                !record?.is_daily &&
                !record?.is_date_specific &&
                !record?.is_monthly &&
                !record?.is_weekly
            ) {
                setIsRecurring('no')
            } else {
                setIsRecurring('yes')
            }
            setCategoryId(record.category_id)
            const fullDetails = await getVisualsByCategory(
                record.category_id,
                accessToken
            )
            let frequencyType = 'daily'
            if (record.is_weekly) frequencyType = 'weekly'
            if (record.is_monthly) frequencyType = 'monthly'
            if (record.is_date_specific) frequencyType = 'date_specific'

            setSelectedFrequency(frequencyType)
            if (fullDetails.cover_page) {
                setFileList([
                    {
                        uid: '-1',
                        name: 'cover_page.png',
                        status: 'done',
                        url: `${
                            fullDetails?.cover_page
                        }?${new Date().getTime()}`,
                    },
                ])
            } else {
                setFileList([])
            }
            setCategoryName(record.category_name)
            const formValues = {
                language_id: record.language_id,
                category_name: record.category_name,
                type: frequencyType,
                start_time_of_day: record.start_time
                    ? moment(record.start_time)
                    : null,
                end_time_of_day: record.end_time
                    ? moment(record.end_time)
                    : null,
                month_of_year: record.month_of_year,
                is_daily: selectedFrequency === 'daily',
                is_weekly: selectedFrequency === 'weekly',
                is_monthly: selectedFrequency === 'monthly',
                is_date_specific: selectedFrequency === 'date_specific',
                cover_page: fullDetails.cover_page,
            }

            if (frequencyType === 'weekly') {
                formValues.sday = record.day_of_week
            } else if (frequencyType === 'monthly') {
                formValues.date = record.day_of_month
            } else if (frequencyType === 'date_specific') {
                formValues.specificdate = record.start_time
                    ? dayjs(record.start_time)
                    : null
            }

            form.setFieldsValue(formValues)
        } else {
            setModalTitle('Add Category')
            setEditingRecord(null)
            setMode('add')
            setIsRecurring('no')
            setFileList([])
            form.resetFields()
            form.setFieldValue({ language_id: language })
        }
        setIsModalOpen(true)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
        form.resetFields()
        setFileList([])
        setSelectedFrequency(null)
        setEditingRecord(null)
        setMode('add')
    }

    const deleteRow = async (record) => {
        try {
            await deleteVisualsByCategory(record.category_id, accessToken)

            setData((prevData) => {
                const updatedData = prevData.filter(
                    (item) => item.category_id !== record.category_id
                )
                return updatedData
            })

            setFilteredData((prevFilteredData) => {
                const updatedFilteredData = prevFilteredData.filter(
                    (item) => item.category_id !== record.category_id
                )
                return updatedFilteredData
            })

            message.success('Category deleted successfully')
        } catch (err) {
            console.error('Error deleting category:', err)
            message.error('Failed to delete category')
        }
    }

    const handleLanguageChange = (value) => {
        setLanguage(value)
        setCurrentLanguage(value)
        // const filtered = data.filter((item) => item.language_id === value)
        // setFilteredData(filtered)
        filterData(value, selectedType)
        if (isModalOpen) {
            form.setFieldValue({ language_id: value })
        }
    }

    const handleTypeChange = (value) => {
        setSelectedType(value)
        filterData(language, value)
    }

    const handleFrequencyChange = (value) => {
        setSelectedFrequency(value)
    }

    const frequencyOptions = useMemo(
        () => [
            { label: 'Daily', value: 'daily', key: 1 },
            { label: 'Weekly', value: 'weekly', key: 2 },
            { label: 'Monthly', value: 'monthly', key: 3 },
            { label: 'Date Specific', value: 'date_specific', key: 4 },
        ],
        []
    )

    const weekdayOptions = useMemo(
        () => [
            { label: 'Monday', value: 1, key: 1 },
            { label: 'Tuesday', value: 2, key: 2 },
            { label: 'Wednesday', value: 3, key: 3 },
            { label: 'Thursday', value: 4, key: 4 },
            { label: 'Friday', value: 5, key: 5 },
            { label: 'Saturday', value: 6, key: 6 },
            { label: 'Sunday', value: 7, key: 7 },
        ],
        []
    )

    const dateOptions = useMemo(
        () =>
            Array.from({ length: 31 }, (_, i) => ({
                label: (i + 1).toString(),
                value: (i + 1).toString(),
                key: i + 1,
            })),
        []
    )

    const columns = useMemo(
        () => [
            {
                title: 'Category Name',
                dataIndex: 'category_name',
                key: 'category_name',
                width: '21%',
                render: (text, record) => (
                    <div>
                        <div>{text}</div>
                        <div
                            style={{
                                color: 'gray',
                                fontSize: '0.8rem',
                                marginTop: '2px',
                            }}
                        >
                            {record.local_name}
                        </div>
                    </div>
                ),
            },

            {
                title: 'Cover Page',
                dataIndex: 'cover_page',
                key: 'cover_page',
                width: '21%',
                render: (_, record) => (
                    <img
                        style={{ width: '4rem' }}
                        src={`${record?.cover_page}?${new Date().getTime()}`}
                        alt="Cover Image"
                    />
                ),
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: '21%',
                render: (_, record) => {
                    let type = 'No Recurring'
                    let timeInfo = ''

                    if (record.is_daily) {
                        type = 'Daily'
                        timeInfo = `${moment(record.start_time).format(
                            'h:mm A'
                        )} - ${moment(record.end_time).format('h:mm A')}`
                    } else if (record.is_weekly) {
                        type = 'Weekly'
                        timeInfo = `${
                            weekdayOptions.find(
                                (day) => day.value === record.day_of_week
                            )?.label
                        }, ${moment(record.start_time).format(
                            'h:mm A'
                        )} - ${moment(record.end_time).format('h:mm A')}`
                    } else if (record.is_monthly) {
                        type = 'Monthly'
                        timeInfo = `Day ${record.day_of_month}, ${moment(
                            record.start_time
                        ).format('h:mm A')} - ${moment(record.end_time).format(
                            'h:mm A'
                        )}`
                    } else if (record.is_date_specific) {
                        type = 'Date Specific'
                        timeInfo = `${moment(record.start_time).format(
                            'DD/MM/YYYY, h:mm A'
                        )} - ${moment(record.end_time).format('h:mm A')}`
                    }

                    return (
                        <div>
                            <div>{type}</div>
                            <div style={{ fontSize: '0.8em', color: '#888' }}>
                                {timeInfo}
                            </div>
                        </div>
                    )
                },
            },
            {
                title: 'No of Visuals',
                dataIndex: 'no_of_items',
                key: 'no_of_items',
                width: '19%',
                render: (_, record) => (
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: 'blue',
                            cursor: 'pointer',
                        }}
                        onClick={() =>
                            navigate(
                                `/home/visuals/${record?.category_id}/${record?.language_id}`
                            )
                        }
                    >
                        {record?.no_of_items}
                    </span>
                    // <a style={{textDecoration:"underline",color:"blue",cursor:"pointer"}} href={`/home/visuals/${record?.category_id}/${record?.language_id}`} >{record?.no_of_items}</a>
                ),
            },
            {
                title: 'Actions',
                key: 'actions',
                align: 'center',
                width: '19%',
                render: (_, record) => (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: '10px',
                            fontSize: '1.2rem',
                        }}
                    >
                        <EditOutlined
                            onClick={() => {
                                setMode('edit')
                                showModal(record)
                                console.log(record)
                            }}
                            aria-label="Edit category"
                        />
                        <Popconfirm
                            title="Delete Category"
                            description="Are you sure you want to delete this Category?"
                            onConfirm={() => deleteRow(record)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteOutlined
                                style={{ paddingLeft: '5px' }}
                                aria-label="Delete Category"
                            />
                        </Popconfirm>
                    </div>
                ),
            },
        ],
        []
    )
    const validateEndTime = (_, value) => {
        const startTime = form.getFieldValue('start_time_of_day')
        if (!startTime || !value) {
            return Promise.resolve()
        }
        if (value.isAfter(startTime)) {
            return Promise.resolve()
        }
        return Promise.reject(
            new Error('End time should be greater than start time')
        )
    }
    return (
        <div className="category-container">
            <h2>Manage Category</h2>
            <div className="category-dropdowns">
                <div className="category-controls">
                    <Select
                        value={language}
                        onChange={handleLanguageChange}
                        placeholder="Select Language"
                        style={{ width: 200 }}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option.language_id}
                                value={option.language_id}
                            >
                                {option.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        placeholder="Select Type"
                        style={{ width: 200 }}
                        value={selectedType}
                        onChange={handleTypeChange}
                    >
                        <Select.Option value="All">All</Select.Option>
                        <Select.Option value="Daily">Daily</Select.Option>
                        <Select.Option value="Weekly">Weekly</Select.Option>
                        <Select.Option value="Monthly">Monthly</Select.Option>
                        <Select.Option value="Date Specific">
                            Date Specific
                        </Select.Option>
                    </Select>
                </div>
                <Button
                    type="primary"
                    onClick={() => {
                        showModal([])
                        setMode('add')
                    }}
                    className="add-category-button"
                >
                    Add Category
                </Button>
            </div>
            <CommonTable columns={columns} data={filteredData} />
            <Modal
                width={660}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                centered
                title={
                    <h2 className="modal-title">
                        {mode === 'add' ? 'Add Category' : 'Edit Category'}
                    </h2>
                }
            >
                <hr />
                <Form form={form} onFinish={onFinish}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '2rem',
                        }}
                    >
                        <div
                            style={{
                                width: '48%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Form.Item
                                label={
                                    <span style={{ fontWeight: 550 }}>
                                        Language
                                    </span>
                                }
                                name="language_id"
                                wrapperCol={{ span: 15 }}
                                style={{ width: '100%' }}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select language',
                                    },
                                ]}
                                className="form-item"
                                labelCol={{ span: 24 }}
                                initialValue={currentLanguage}
                            >
                                <Select
                                    onChange={handleLanguageChange}
                                    className="form-item"
                                >
                                    {languageData?.map((option) => (
                                        <Select.Option
                                            key={option?.language_id}
                                            value={option?.language_id}
                                        >
                                            {option?.language_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label={
                                    <span style={{ fontWeight: 550 }}>
                                        Category Name
                                    </span>
                                }
                                name="category_name"
                                wrapperCol={{ span: 15 }}
                                rules={[
                                    {
                                        required: true,
                                        whitespace: true,
                                        message:
                                            ' Please enter the Category Name',
                                    },

                                    {
                                        validator: (_, value) => {
                                            if (!value) return Promise.resolve()
                                            if (
                                                isCategoryNameUnique(
                                                    value,
                                                    form.getFieldValue(
                                                        'language_id'
                                                    )
                                                )
                                            ) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Category name already exists for this language'
                                                )
                                            )
                                        },
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9 ]+$/,
                                        message:
                                            'Category name should not contain special characters',
                                    },
                                ]}
                                className="form-item"
                                labelCol={{ span: 24 }}
                            >
                                <Input
                                    placeholder="Enter Category Name"
                                    value={categoryName}
                                    onChange={(e) =>
                                        setCategoryName(e.target.value)
                                    }
                                    className="form-item"
                                />
                            </Form.Item>
                            <Form.Item
                                name="cover_page"
                                label="Cover Page"
                                wrapperCol={{ span: 24 }}
                                style={{ width: '100%' }}
                                rules={[
                                    // {
                                    //     required: true,
                                    //     message: 'Please upload a cover page',
                                    // },
                                    {
                                        validator: (_, value) => {
                                            if (fileList.length > 0) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(
                                                new Error(
                                                    'Please upload a Cover Page'
                                                )
                                            )
                                        },
                                    },
                                ]}
                            >
                                <Dragger
                                    listType="picture"
                                    fileList={fileList}
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={handleChange}
                                    maxCount={1}
                                    {...draggerProps}
                                    className="custom-dragger"
                                    style={{ width: '100%' }}
                                >
                                    {
                                        <>
                                            <p className="ant-upload-drag-icon">
                                                <CloudUploadOutlined />
                                            </p>
                                            <Button>Cover page</Button>
                                            <div
                                                style={{
                                                    color: '#199be9',
                                                    marginTop: '5px',
                                                    marginRight: '5px',
                                                }}
                                            >
                                                JPEG, JPG and PNG format only
                                            </div>
                                        </>
                                    }
                                </Dragger>
                            </Form.Item>
                        </div>

                        <div
                            style={{
                                width: '48%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Form.Item
                                label={
                                    <span style={{ fontWeight: 550 }}>
                                        Local Name
                                    </span>
                                }
                                name="local_name"
                                wrapperCol={{ span: 15 }}
                                rules={[
                                    {
                                        required: false,
                                        message: ' Please enter the Local Name',
                                    },
                                ]}
                                className="form-item"
                                labelCol={{ span: 24 }}
                            >
                                <Input
                                    placeholder="Enter Local Name"
                                    className="form-item"
                                />
                            </Form.Item>
                            <Form.Item label="Is Recurring" key="is_recurring">
                                <Switch
                                    onChange={() =>
                                        setIsRecurring((pre) =>
                                            pre === 'yes' ? 'no' : 'yes'
                                        )
                                    }
                                    checkedChildren="Yes"
                                    unCheckedChildren="No"
                                    value={isRecurring === 'yes' ? true : false}
                                    className="active_status"
                                />
                            </Form.Item>
                            {isRecurring === 'yes' && (
                                <>
                                    <div>
                                        <Form.Item
                                            label={
                                                <span
                                                    style={{ fontWeight: 550 }}
                                                >
                                                    Recurring Type
                                                </span>
                                            }
                                            name="type"
                                            wrapperCol={{ span: 15 }}
                                            rules={[
                                                {
                                                    message:
                                                        'Enter Recurring Type',
                                                },
                                            ]}
                                            className="form-item"
                                            labelCol={{ span: 24 }}
                                        >
                                            <Select
                                                onChange={handleFrequencyChange}
                                                value={selectedFrequency}
                                                placeholder="Select Frequency"
                                                style={{ width: 200 }}
                                                className="form-item"
                                            >
                                                {frequencyOptions.map(
                                                    (option) => (
                                                        <Select.Option
                                                            key={option.key}
                                                            value={option.value}
                                                        >
                                                            {option.label}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    {selectedFrequency === 'daily' && (
                                        <>
                                            <Form.Item
                                                label="Start Time of Day"
                                                name="start_time_of_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker format="HH:mm" />
                                            </Form.Item>
                                            <Form.Item
                                                label="End Time of Day"
                                                name="end_time_of_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                    {
                                                        validator:
                                                            validateEndTime,
                                                    },
                                                ]}
                                            >
                                                <TimePicker format="HH:mm" />
                                            </Form.Item>
                                        </>
                                    )}

                                    {selectedFrequency === 'weekly' && (
                                        <>
                                            <Form.Item
                                                label="Select Day"
                                                name="sday"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select Day"
                                                    style={{ width: 200 }}
                                                    className="form-item"
                                                >
                                                    {weekdayOptions.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={option.key}
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="Start Time of Day"
                                                name="start_time_of_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker format="HH:mm" />
                                            </Form.Item>
                                            <Form.Item
                                                label="End Time of Day"
                                                name="end_time_of_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker format="HH:mm" />
                                            </Form.Item>
                                        </>
                                    )}

                                    {selectedFrequency === 'monthly' && (
                                        <>
                                            <Form.Item
                                                label="Select a Date"
                                                name="date"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please select a date',
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Select Day"
                                                    style={{ width: 200 }}
                                                    className="form-item"
                                                >
                                                    {dateOptions.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={option.key}
                                                                value={
                                                                    option.value
                                                                }
                                                            >
                                                                {option.label}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                label="Start Time of Day"
                                                name="start_time_of_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker format="HH:mm" />
                                            </Form.Item>
                                            <Form.Item
                                                label="End Time of Day"
                                                name="end_time_of_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Time',
                                                    },
                                                ]}
                                            >
                                                <TimePicker format="HH:mm" />
                                            </Form.Item>
                                        </>
                                    )}

                                    {selectedFrequency === 'date_specific' && (
                                        <>
                                            <Form.Item
                                                label="Select Specific date"
                                                name="specificdate"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'Please Select Date',
                                                    },
                                                ]}
                                            >
                                                <DatePicker
                                                    format="DD/MM/YYYY"
                                                    disabledDate={(
                                                        currentDate
                                                    ) =>
                                                        currentDate &&
                                                        currentDate <
                                                            moment().startOf(
                                                                'day'
                                                            )
                                                    }
                                                />
                                            </Form.Item>
                                            {isRecurring && (
                                                <>
                                                    <Form.Item
                                                        label="Start Time of Day"
                                                        name="start_time_of_day"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please Select Time',
                                                            },
                                                        ]}
                                                    >
                                                        <TimePicker format="HH:mm" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        label="End Time of Day"
                                                        name="end_time_of_day"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please Select Time',
                                                            },
                                                        ]}
                                                    >
                                                        <TimePicker format="HH:mm" />
                                                    </Form.Item>
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                            <Button
                                type="primary"
                                className="save-button"
                                htmlType="submit"
                            >
                                {mode === 'add' ? 'Save' : 'Update'}
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </div>
    )
}

export default Category
