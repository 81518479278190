import React, { useRef, useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { monthsData } from './LanguageSpecificMonths.js'
import Aquarius from '../../assets/horoscopeCardsTemplates/Aquarius.jpg'
import Aries from '../../assets/horoscopeCardsTemplates/Aries.jpg'
import Cancer from '../../assets/horoscopeCardsTemplates/Cancer.jpg'
import Capricorn from '../../assets/horoscopeCardsTemplates/Capricorn.jpg'
import Gemini from '../../assets/horoscopeCardsTemplates/Gemini.jpg'
import Leo from '../../assets/horoscopeCardsTemplates/Leo.jpg'
import Libra from '../../assets/horoscopeCardsTemplates/Libra.jpg'
import Pisces from '../../assets/horoscopeCardsTemplates/Pisces.jpg'
import Sagittarius from '../../assets/horoscopeCardsTemplates/Sagittarius.jpg'
import Scorpio from '../../assets/horoscopeCardsTemplates/Scorpio.jpg'
import Taurus from '../../assets/horoscopeCardsTemplates/Taurus.jpg'
import Virgo from '../../assets/horoscopeCardsTemplates/Virgo.jpg'

const HoroscopeCanvas = React.forwardRef(
    (
        {
            modalText,
            month,
            day,
            currentHoroscope,
            onImageExport,
            setDrawCanvas,
            language,
        },
        ref
    ) => {
        const horoscopeImages = {
            Aquarius,
            Aries,
            Cancer,
            Capricorn,
            Gemini,
            Leo,
            Libra,
            Pisces,
            Sagittarius,
            Scorpio,
            Taurus,
            Virgo,
        }

        const getLanguageKey = (languageId) => {
            const languageMap = {
                1: 'english',
                2: 'telugu',
                3: 'hindi',
                4: 'tamil',
            }
            return languageMap[languageId] || 'english'
        }

        const translateMonth = (englishMonth, selectedLanguage) => {
            const languageKey = getLanguageKey(selectedLanguage)
            const monthIndex = monthsData.english.indexOf(englishMonth)
            return monthsData[languageKey][monthIndex]
        }

        const imageRef = useRef(null)
        const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
        const [fontLoaded, setFontLoaded] = useState(false)
        const [displayDimensions] = useState({
            width: 196.8,
            height: 426.666666667,
        })
        const [exportDimensions] = useState({
            // width: 393.778280543,
            // height: 854.298642534,
            width: 315.022624434,
            height: 683.438914027,
        })
        const calculateScaleFactor = useCallback(
            (isExport) => {
                const baseWidth = 295.2
                return isExport
                    ? exportDimensions.width / baseWidth
                    : displayDimensions.width / baseWidth
            },
            [displayDimensions, exportDimensions]
        )

        useEffect(() => {
            const loadFont = async () => {
                const fontPromise = new FontFace(
                    'Mallanna',
                    'url(https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwzEzRwVvJ7.woff2)'
                ).load()
                await fontPromise
                document.fonts.add(fontPromise)
            }
            loadFont()
        }, [])

        useEffect(() => {
            const font = new FontFace(
                'Mallanna',
                'url(https://fonts.gstatic.com/s/mallanna/v13/hv-Vlzx-KEQb84YaDGwzEzRwVvJ7.woff2)'
            )

            font.load()
                .then(() => {
                    document.fonts.add(font)
                    setFontLoaded(true)
                })
                .catch((error) => {
                    console.error('Failed to load Mallanna font:', error)
                    setFontLoaded(true)
                })
        }, [])

        const drawImageWithAspectRatio = useCallback(
            (context, image, targetWidth, targetHeight) => {
                const imageAspectRatio = image.width / image.height
                let drawWidth = targetWidth
                let drawHeight = targetHeight
                let offsetX = 0
                let offsetY = 0

                if (imageAspectRatio > 1) {
                    drawHeight = targetWidth / imageAspectRatio
                    offsetY = (targetHeight - drawHeight) / 2
                } else {
                    drawWidth = targetHeight * imageAspectRatio
                    offsetX = (targetWidth - drawWidth) / 2
                }

                context.drawImage(
                    image,
                    offsetX,
                    offsetY,
                    drawWidth,
                    drawHeight
                )
                context.imageSmoothingEnabled = true
                context.imageSmoothingQuality = 'high'
            },
            []
        )

        const drawTextWithSpacing = useCallback(
            (context, text, x, y, maxWidth, lineHeight, letterSpacing) => {
                if (!text || typeof text !== 'string') return

                const words = text.split(' ')
                let line = ''
                let lines = []

                for (const word of words) {
                    const testLine = line + (line ? ' ' : '') + word
                    const metrics = context.measureText(testLine)
                    const testWidth =
                        metrics.width + letterSpacing * (testLine.length - 1)

                    if (testWidth > maxWidth && line) {
                        lines.push(line)
                        line = word
                    } else {
                        line = testLine
                    }
                }

                lines.push(line)

                const maxLines = 12
                if (lines.length > maxLines) {
                    lines = lines.slice(0, maxLines - 1)
                    lines.push('... (text truncated)')
                }

                lines.forEach((lineText, index) => {
                    const additionalSpacing =
                        letterSpacing * (lineText.length - 1)
                    context.fillText(
                        lineText,
                        x,
                        y + index * lineHeight,
                        maxWidth + additionalSpacing
                    )
                })
            },
            []
        )

        const drawHoroscopeText = useCallback(
            (
                context,
                text,
                position,
                width,
                height,
                scaleFactor = 1,
                isExport = false
            ) => {
                if (!text) return
                const baseFontSize = isExport ? 19.2 : 16
                const fontSize = baseFontSize * scaleFactor * 1.015
                const lineHeight = fontSize * 1.26
                const letterSpacing = (isExport ? 1.3 : 0.5) * scaleFactor

                const isTelugu = /[\u0C00-\u0C7F]/.test(text)
                const fontFamily = isTelugu ? 'Mallanna' : 'sans-serif'

                context.font = `normal ${fontSize}px ${fontFamily}`
                context.fillStyle = 'white'
                context.textAlign = 'center'

                const maxWidth = width * 0.91
                const x = width * position.x
                const y = height * position.y

                drawTextWithSpacing(
                    context,
                    text,
                    x,
                    y,
                    maxWidth,
                    lineHeight,
                    letterSpacing
                )
            },
            [drawTextWithSpacing]
        )

        const drawDateText = useCallback(
            (
                context,
                month,
                day,
                width,
                height,
                scaleFactor = 1,
                isExport = false
            ) => {
                const baseFontSize = isExport ? 13.35 : 11.2
                const fontSize = baseFontSize * scaleFactor * 1.3
                const lineHeight = fontSize * 1.32
                const letterSpacing = (isExport ? 1 : 0.4) * scaleFactor

                context.font = `${fontSize}px Sree Krushnadevaraya`
                context.fillStyle = 'rgba(247,171,75,255)'
                context.textAlign = 'center'

                const monthX = width * 0.47
                const dayX = width * 0.575
                const dateY = height * 0.931

                const monthNames = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December',
                ]
                const monthName = month
                const formattedDay = String(day).padStart(2, '0')

                const translatedMonth = translateMonth(month, language)

                drawTextWithSpacing(
                    context,
                    translatedMonth,
                    monthX,
                    dateY,
                    width,
                    lineHeight,
                    letterSpacing
                )
                drawTextWithSpacing(
                    context,
                    formattedDay,
                    dayX,
                    dateY,
                    width,
                    lineHeight,
                    letterSpacing
                )
            },
            [drawTextWithSpacing, language]
        )

        const drawCanvas = useCallback(
            (
                width,
                height,
                scaleFactor = 1,
                applyDpr = true,
                isExport = false
            ) => {
                if (!ref.current || !imageRef.current || !fontLoaded) return

                const canvas = ref.current
                const context = canvas.getContext('2d')
                const dpr = applyDpr ? window.devicePixelRatio || 1 : 1

                const scaledWidth = width * scaleFactor
                const scaledHeight = height * scaleFactor

                canvas.width = scaledWidth * dpr
                canvas.height = scaledHeight * dpr
                context.scale(dpr, dpr)

                canvas.style.width = `${scaledWidth}px`
                canvas.style.height = `${scaledHeight}px`

                context.resetTransform()
                context.scale(dpr, dpr)

                context.clearRect(0, 0, scaledWidth, scaledHeight)
                drawImageWithAspectRatio(
                    context,
                    imageRef.current,
                    scaledWidth,
                    scaledHeight
                )
                const textScaleFactor = calculateScaleFactor(isExport)

                drawHoroscopeText(
                    context,
                    modalText,
                    position,
                    scaledWidth,
                    scaledHeight,
                    textScaleFactor,
                    isExport
                )
                drawDateText(
                    context,
                    month,
                    day,
                    scaledWidth,
                    scaledHeight,
                    textScaleFactor,
                    isExport
                )
            },
            [
                modalText,
                month,
                day,
                position,
                ref,
                calculateScaleFactor,
                drawImageWithAspectRatio,
                drawHoroscopeText,
                drawDateText,
                fontLoaded,
            ]
        )

        useEffect(() => {
            setDrawCanvas(
                () => (width, height, scaleFactor, applyDpr, isExport) =>
                    drawCanvas(
                        isExport
                            ? exportDimensions.width
                            : displayDimensions.width,
                        isExport
                            ? exportDimensions.height
                            : displayDimensions.height,
                        scaleFactor,
                        applyDpr,
                        isExport
                    )
            )
        }, [drawCanvas, setDrawCanvas, exportDimensions, displayDimensions])

        useEffect(() => {
            const image = new Image()
            if (horoscopeImages.hasOwnProperty(currentHoroscope)) {
                image.src = horoscopeImages[currentHoroscope]
            } else {
                console.error(
                    `No image found for horoscope: ${currentHoroscope}`
                )
            }
            image.onload = () => {
                imageRef.current = image
                if (fontLoaded && imageRef.current) {
                    drawCanvas(
                        displayDimensions.width,
                        displayDimensions.height,
                        1,
                        true,
                        false
                    )
                }
                // drawCanvas(295.2, 640, 1, true)
                // drawCanvas(196.8, 426.666666667, 1, true)
            }
            image.onerror = (error) => {
                console.error('Error loading image:', error)
            }
        }, [
            drawCanvas,
            currentHoroscope,
            displayDimensions,
            fontLoaded,
            imageRef,
        ])

        const handleMouseDown = useCallback((e) => {
            const canvas = ref.current
            const rect = canvas.getBoundingClientRect()

            const handleMouseMove = (moveEvent) => {
                setPosition({
                    x: (moveEvent.clientX - rect.left) / rect.width,
                    y: (moveEvent.clientY - rect.top) / rect.height,
                })
            }

            const handleMouseUp = () => {
                document.removeEventListener('mousemove', handleMouseMove)
                document.removeEventListener('mouseup', handleMouseUp)
            }

            document.addEventListener('mousemove', handleMouseMove)
            document.addEventListener('mouseup', handleMouseUp)
        }, [])

        return (
            <div className="horoscope-canvas-container">
                <canvas
                    className="canvas"
                    ref={ref}
                    onMouseDown={handleMouseDown}
                    aria-label="Horoscope Canvas"
                ></canvas>
            </div>
        )
    }
)
HoroscopeCanvas.propTypes = {
    modalText: PropTypes.string.isRequired,
    month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    day: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    currentHoroscope: PropTypes.string.isRequired,
    onImageExport: PropTypes.func.isRequired,
    setDrawCanvas: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
}

export default HoroscopeCanvas
